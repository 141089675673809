// source: wallet/web/wallet.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var wallet_base_pb = require('../../wallet/base_pb.js');
goog.object.extend(proto, wallet_base_pb);
var wallet_transaction_pb = require('../../wallet/transaction_pb.js');
goog.object.extend(proto, wallet_transaction_pb);
var base_base_pb = require('../../base/base_pb.js');
goog.object.extend(proto, base_base_pb);
goog.exportSymbol('proto.wallet.web.BankAccount', null, global);
goog.exportSymbol('proto.wallet.web.BankTransferData', null, global);
goog.exportSymbol('proto.wallet.web.CalculateTopUpAmountReq', null, global);
goog.exportSymbol('proto.wallet.web.CalculateTopUpAmountRes', null, global);
goog.exportSymbol('proto.wallet.web.CalculateTopUpAmountV2Res', null, global);
goog.exportSymbol('proto.wallet.web.CheckPromotionCodeReq', null, global);
goog.exportSymbol('proto.wallet.web.CheckPromotionCodeRes', null, global);
goog.exportSymbol('proto.wallet.web.GetWalletInfoReq', null, global);
goog.exportSymbol('proto.wallet.web.GetWalletInfoRes', null, global);
goog.exportSymbol('proto.wallet.web.MyWalletReq', null, global);
goog.exportSymbol('proto.wallet.web.MyWalletRes', null, global);
goog.exportSymbol('proto.wallet.web.OrderFlightMetadata', null, global);
goog.exportSymbol('proto.wallet.web.PaymentAmount', null, global);
goog.exportSymbol('proto.wallet.web.Payoo', null, global);
goog.exportSymbol('proto.wallet.web.PromoApplyRes', null, global);
goog.exportSymbol('proto.wallet.web.PromoErrorRes', null, global);
goog.exportSymbol('proto.wallet.web.TopUpPaymentAction', null, global);
goog.exportSymbol('proto.wallet.web.TopUpPaymentActionData', null, global);
goog.exportSymbol('proto.wallet.web.TopUpPaymentInfo', null, global);
goog.exportSymbol('proto.wallet.web.TopUpPaymentMethod', null, global);
goog.exportSymbol('proto.wallet.web.TopUpPaymentSDK', null, global);
goog.exportSymbol('proto.wallet.web.TopUpReq', null, global);
goog.exportSymbol('proto.wallet.web.TopUpRes', null, global);
goog.exportSymbol('proto.wallet.web.Wallet', null, global);
goog.exportSymbol('proto.wallet.web.WalletBasic', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wallet.web.OrderFlightMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wallet.web.OrderFlightMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wallet.web.OrderFlightMetadata.displayName = 'proto.wallet.web.OrderFlightMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wallet.web.Wallet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wallet.web.Wallet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wallet.web.Wallet.displayName = 'proto.wallet.web.Wallet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wallet.web.WalletBasic = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wallet.web.WalletBasic, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wallet.web.WalletBasic.displayName = 'proto.wallet.web.WalletBasic';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wallet.web.TopUpPaymentInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wallet.web.TopUpPaymentInfo.repeatedFields_, null);
};
goog.inherits(proto.wallet.web.TopUpPaymentInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wallet.web.TopUpPaymentInfo.displayName = 'proto.wallet.web.TopUpPaymentInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wallet.web.BankAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wallet.web.BankAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wallet.web.BankAccount.displayName = 'proto.wallet.web.BankAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wallet.web.TopUpPaymentMethod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wallet.web.TopUpPaymentMethod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wallet.web.TopUpPaymentMethod.displayName = 'proto.wallet.web.TopUpPaymentMethod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wallet.web.Payoo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wallet.web.Payoo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wallet.web.Payoo.displayName = 'proto.wallet.web.Payoo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wallet.web.TopUpPaymentSDK = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wallet.web.TopUpPaymentSDK, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wallet.web.TopUpPaymentSDK.displayName = 'proto.wallet.web.TopUpPaymentSDK';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wallet.web.TopUpPaymentActionData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wallet.web.TopUpPaymentActionData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wallet.web.TopUpPaymentActionData.displayName = 'proto.wallet.web.TopUpPaymentActionData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wallet.web.BankTransferData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wallet.web.BankTransferData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wallet.web.BankTransferData.displayName = 'proto.wallet.web.BankTransferData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wallet.web.GetWalletInfoReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wallet.web.GetWalletInfoReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wallet.web.GetWalletInfoReq.displayName = 'proto.wallet.web.GetWalletInfoReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wallet.web.CheckPromotionCodeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wallet.web.CheckPromotionCodeReq.repeatedFields_, null);
};
goog.inherits(proto.wallet.web.CheckPromotionCodeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wallet.web.CheckPromotionCodeReq.displayName = 'proto.wallet.web.CheckPromotionCodeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wallet.web.TopUpReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wallet.web.TopUpReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wallet.web.TopUpReq.displayName = 'proto.wallet.web.TopUpReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wallet.web.CalculateTopUpAmountReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wallet.web.CalculateTopUpAmountReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wallet.web.CalculateTopUpAmountReq.displayName = 'proto.wallet.web.CalculateTopUpAmountReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wallet.web.GetWalletInfoRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wallet.web.GetWalletInfoRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wallet.web.GetWalletInfoRes.displayName = 'proto.wallet.web.GetWalletInfoRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wallet.web.PromoErrorRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wallet.web.PromoErrorRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wallet.web.PromoErrorRes.displayName = 'proto.wallet.web.PromoErrorRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wallet.web.PromoApplyRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wallet.web.PromoApplyRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wallet.web.PromoApplyRes.displayName = 'proto.wallet.web.PromoApplyRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wallet.web.TopUpRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wallet.web.TopUpRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wallet.web.TopUpRes.displayName = 'proto.wallet.web.TopUpRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wallet.web.CheckPromotionCodeRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wallet.web.CheckPromotionCodeRes.repeatedFields_, null);
};
goog.inherits(proto.wallet.web.CheckPromotionCodeRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wallet.web.CheckPromotionCodeRes.displayName = 'proto.wallet.web.CheckPromotionCodeRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wallet.web.CalculateTopUpAmountRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wallet.web.CalculateTopUpAmountRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wallet.web.CalculateTopUpAmountRes.displayName = 'proto.wallet.web.CalculateTopUpAmountRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wallet.web.PaymentAmount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wallet.web.PaymentAmount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wallet.web.PaymentAmount.displayName = 'proto.wallet.web.PaymentAmount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wallet.web.CalculateTopUpAmountV2Res = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wallet.web.CalculateTopUpAmountV2Res, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wallet.web.CalculateTopUpAmountV2Res.displayName = 'proto.wallet.web.CalculateTopUpAmountV2Res';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wallet.web.MyWalletReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wallet.web.MyWalletReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wallet.web.MyWalletReq.displayName = 'proto.wallet.web.MyWalletReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wallet.web.MyWalletRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wallet.web.MyWalletRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wallet.web.MyWalletRes.displayName = 'proto.wallet.web.MyWalletRes';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wallet.web.OrderFlightMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.wallet.web.OrderFlightMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wallet.web.OrderFlightMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.OrderFlightMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    flightType: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tripType: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wallet.web.OrderFlightMetadata}
 */
proto.wallet.web.OrderFlightMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wallet.web.OrderFlightMetadata;
  return proto.wallet.web.OrderFlightMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wallet.web.OrderFlightMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wallet.web.OrderFlightMetadata}
 */
proto.wallet.web.OrderFlightMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTripType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wallet.web.OrderFlightMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wallet.web.OrderFlightMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wallet.web.OrderFlightMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.OrderFlightMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlightType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTripType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string flight_type = 1;
 * @return {string}
 */
proto.wallet.web.OrderFlightMetadata.prototype.getFlightType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.OrderFlightMetadata} returns this
 */
proto.wallet.web.OrderFlightMetadata.prototype.setFlightType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string trip_type = 2;
 * @return {string}
 */
proto.wallet.web.OrderFlightMetadata.prototype.getTripType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.OrderFlightMetadata} returns this
 */
proto.wallet.web.OrderFlightMetadata.prototype.setTripType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wallet.web.Wallet.prototype.toObject = function(opt_includeInstance) {
  return proto.wallet.web.Wallet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wallet.web.Wallet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.Wallet.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    partnershipId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    balance: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wallet.web.Wallet}
 */
proto.wallet.web.Wallet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wallet.web.Wallet;
  return proto.wallet.web.Wallet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wallet.web.Wallet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wallet.web.Wallet}
 */
proto.wallet.web.Wallet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnershipId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBalance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wallet.web.Wallet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wallet.web.Wallet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wallet.web.Wallet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.Wallet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPartnershipId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBalance();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wallet.web.Wallet.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.Wallet} returns this
 */
proto.wallet.web.Wallet.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.wallet.web.Wallet.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.Wallet} returns this
 */
proto.wallet.web.Wallet.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string partnership_id = 3;
 * @return {string}
 */
proto.wallet.web.Wallet.prototype.getPartnershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.Wallet} returns this
 */
proto.wallet.web.Wallet.prototype.setPartnershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double balance = 4;
 * @return {number}
 */
proto.wallet.web.Wallet.prototype.getBalance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wallet.web.Wallet} returns this
 */
proto.wallet.web.Wallet.prototype.setBalance = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wallet.web.WalletBasic.prototype.toObject = function(opt_includeInstance) {
  return proto.wallet.web.WalletBasic.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wallet.web.WalletBasic} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.WalletBasic.toObject = function(includeInstance, msg) {
  var f, obj = {
    balance: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wallet.web.WalletBasic}
 */
proto.wallet.web.WalletBasic.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wallet.web.WalletBasic;
  return proto.wallet.web.WalletBasic.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wallet.web.WalletBasic} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wallet.web.WalletBasic}
 */
proto.wallet.web.WalletBasic.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBalance(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wallet.web.WalletBasic.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wallet.web.WalletBasic.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wallet.web.WalletBasic} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.WalletBasic.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBalance();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional double balance = 1;
 * @return {number}
 */
proto.wallet.web.WalletBasic.prototype.getBalance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wallet.web.WalletBasic} returns this
 */
proto.wallet.web.WalletBasic.prototype.setBalance = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional string currency = 2;
 * @return {string}
 */
proto.wallet.web.WalletBasic.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.WalletBasic} returns this
 */
proto.wallet.web.WalletBasic.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wallet.web.TopUpPaymentInfo.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wallet.web.TopUpPaymentInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.wallet.web.TopUpPaymentInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wallet.web.TopUpPaymentInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.TopUpPaymentInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transactionStatus: jspb.Message.getFieldWithDefault(msg, 2, 0),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    fee: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    totalPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    currencyCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    paymentMethod: (f = msg.getPaymentMethod()) && proto.wallet.web.TopUpPaymentMethod.toObject(includeInstance, f),
    bankAccountsList: jspb.Message.toObjectList(msg.getBankAccountsList(),
    proto.wallet.web.BankAccount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wallet.web.TopUpPaymentInfo}
 */
proto.wallet.web.TopUpPaymentInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wallet.web.TopUpPaymentInfo;
  return proto.wallet.web.TopUpPaymentInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wallet.web.TopUpPaymentInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wallet.web.TopUpPaymentInfo}
 */
proto.wallet.web.TopUpPaymentInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionId(value);
      break;
    case 2:
      var value = /** @type {!proto.wallet.TransactionStatus} */ (reader.readEnum());
      msg.setTransactionStatus(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFee(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalPrice(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    case 7:
      var value = new proto.wallet.web.TopUpPaymentMethod;
      reader.readMessage(value,proto.wallet.web.TopUpPaymentMethod.deserializeBinaryFromReader);
      msg.setPaymentMethod(value);
      break;
    case 8:
      var value = new proto.wallet.web.BankAccount;
      reader.readMessage(value,proto.wallet.web.BankAccount.deserializeBinaryFromReader);
      msg.addBankAccounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wallet.web.TopUpPaymentInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wallet.web.TopUpPaymentInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wallet.web.TopUpPaymentInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.TopUpPaymentInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransactionStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getFee();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getTotalPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPaymentMethod();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.wallet.web.TopUpPaymentMethod.serializeBinaryToWriter
    );
  }
  f = message.getBankAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.wallet.web.BankAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional string transaction_id = 1;
 * @return {string}
 */
proto.wallet.web.TopUpPaymentInfo.prototype.getTransactionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.TopUpPaymentInfo} returns this
 */
proto.wallet.web.TopUpPaymentInfo.prototype.setTransactionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wallet.TransactionStatus transaction_status = 2;
 * @return {!proto.wallet.TransactionStatus}
 */
proto.wallet.web.TopUpPaymentInfo.prototype.getTransactionStatus = function() {
  return /** @type {!proto.wallet.TransactionStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wallet.TransactionStatus} value
 * @return {!proto.wallet.web.TopUpPaymentInfo} returns this
 */
proto.wallet.web.TopUpPaymentInfo.prototype.setTransactionStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional double amount = 3;
 * @return {number}
 */
proto.wallet.web.TopUpPaymentInfo.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wallet.web.TopUpPaymentInfo} returns this
 */
proto.wallet.web.TopUpPaymentInfo.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double fee = 4;
 * @return {number}
 */
proto.wallet.web.TopUpPaymentInfo.prototype.getFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wallet.web.TopUpPaymentInfo} returns this
 */
proto.wallet.web.TopUpPaymentInfo.prototype.setFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double total_price = 5;
 * @return {number}
 */
proto.wallet.web.TopUpPaymentInfo.prototype.getTotalPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wallet.web.TopUpPaymentInfo} returns this
 */
proto.wallet.web.TopUpPaymentInfo.prototype.setTotalPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string currency_code = 6;
 * @return {string}
 */
proto.wallet.web.TopUpPaymentInfo.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.TopUpPaymentInfo} returns this
 */
proto.wallet.web.TopUpPaymentInfo.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional TopUpPaymentMethod payment_method = 7;
 * @return {?proto.wallet.web.TopUpPaymentMethod}
 */
proto.wallet.web.TopUpPaymentInfo.prototype.getPaymentMethod = function() {
  return /** @type{?proto.wallet.web.TopUpPaymentMethod} */ (
    jspb.Message.getWrapperField(this, proto.wallet.web.TopUpPaymentMethod, 7));
};


/**
 * @param {?proto.wallet.web.TopUpPaymentMethod|undefined} value
 * @return {!proto.wallet.web.TopUpPaymentInfo} returns this
*/
proto.wallet.web.TopUpPaymentInfo.prototype.setPaymentMethod = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wallet.web.TopUpPaymentInfo} returns this
 */
proto.wallet.web.TopUpPaymentInfo.prototype.clearPaymentMethod = function() {
  return this.setPaymentMethod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wallet.web.TopUpPaymentInfo.prototype.hasPaymentMethod = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated BankAccount bank_accounts = 8;
 * @return {!Array<!proto.wallet.web.BankAccount>}
 */
proto.wallet.web.TopUpPaymentInfo.prototype.getBankAccountsList = function() {
  return /** @type{!Array<!proto.wallet.web.BankAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wallet.web.BankAccount, 8));
};


/**
 * @param {!Array<!proto.wallet.web.BankAccount>} value
 * @return {!proto.wallet.web.TopUpPaymentInfo} returns this
*/
proto.wallet.web.TopUpPaymentInfo.prototype.setBankAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.wallet.web.BankAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wallet.web.BankAccount}
 */
proto.wallet.web.TopUpPaymentInfo.prototype.addBankAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.wallet.web.BankAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wallet.web.TopUpPaymentInfo} returns this
 */
proto.wallet.web.TopUpPaymentInfo.prototype.clearBankAccountsList = function() {
  return this.setBankAccountsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wallet.web.BankAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.wallet.web.BankAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wallet.web.BankAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.BankAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    bankName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bankCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bankBranch: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accountNumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    cardNumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    note: jspb.Message.getFieldWithDefault(msg, 7, ""),
    description: jspb.Message.getFieldWithDefault(msg, 8, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wallet.web.BankAccount}
 */
proto.wallet.web.BankAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wallet.web.BankAccount;
  return proto.wallet.web.BankAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wallet.web.BankAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wallet.web.BankAccount}
 */
proto.wallet.web.BankAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankBranch(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardNumber(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wallet.web.BankAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wallet.web.BankAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wallet.web.BankAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.BankAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBankName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBankCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBankBranch();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCardNumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string bank_name = 1;
 * @return {string}
 */
proto.wallet.web.BankAccount.prototype.getBankName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.BankAccount} returns this
 */
proto.wallet.web.BankAccount.prototype.setBankName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string bank_code = 2;
 * @return {string}
 */
proto.wallet.web.BankAccount.prototype.getBankCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.BankAccount} returns this
 */
proto.wallet.web.BankAccount.prototype.setBankCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string bank_branch = 3;
 * @return {string}
 */
proto.wallet.web.BankAccount.prototype.getBankBranch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.BankAccount} returns this
 */
proto.wallet.web.BankAccount.prototype.setBankBranch = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_name = 4;
 * @return {string}
 */
proto.wallet.web.BankAccount.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.BankAccount} returns this
 */
proto.wallet.web.BankAccount.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_number = 5;
 * @return {string}
 */
proto.wallet.web.BankAccount.prototype.getAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.BankAccount} returns this
 */
proto.wallet.web.BankAccount.prototype.setAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string card_number = 6;
 * @return {string}
 */
proto.wallet.web.BankAccount.prototype.getCardNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.BankAccount} returns this
 */
proto.wallet.web.BankAccount.prototype.setCardNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string note = 7;
 * @return {string}
 */
proto.wallet.web.BankAccount.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.BankAccount} returns this
 */
proto.wallet.web.BankAccount.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string description = 8;
 * @return {string}
 */
proto.wallet.web.BankAccount.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.BankAccount} returns this
 */
proto.wallet.web.BankAccount.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string icon = 9;
 * @return {string}
 */
proto.wallet.web.BankAccount.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.BankAccount} returns this
 */
proto.wallet.web.BankAccount.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wallet.web.TopUpPaymentMethod.prototype.toObject = function(opt_includeInstance) {
  return proto.wallet.web.TopUpPaymentMethod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wallet.web.TopUpPaymentMethod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.TopUpPaymentMethod.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    code: jspb.Message.getFieldWithDefault(msg, 3, ""),
    method: jspb.Message.getFieldWithDefault(msg, 4, 0),
    fee: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    additionalFee: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    displayFee: jspb.Message.getFieldWithDefault(msg, 7, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wallet.web.TopUpPaymentMethod}
 */
proto.wallet.web.TopUpPaymentMethod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wallet.web.TopUpPaymentMethod;
  return proto.wallet.web.TopUpPaymentMethod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wallet.web.TopUpPaymentMethod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wallet.web.TopUpPaymentMethod}
 */
proto.wallet.web.TopUpPaymentMethod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMethod(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFee(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAdditionalFee(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayFee(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wallet.web.TopUpPaymentMethod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wallet.web.TopUpPaymentMethod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wallet.web.TopUpPaymentMethod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.TopUpPaymentMethod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMethod();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getFee();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getAdditionalFee();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getDisplayFee();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wallet.web.TopUpPaymentMethod.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.TopUpPaymentMethod} returns this
 */
proto.wallet.web.TopUpPaymentMethod.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wallet.web.TopUpPaymentMethod.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.TopUpPaymentMethod} returns this
 */
proto.wallet.web.TopUpPaymentMethod.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.wallet.web.TopUpPaymentMethod.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.TopUpPaymentMethod} returns this
 */
proto.wallet.web.TopUpPaymentMethod.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 method = 4;
 * @return {number}
 */
proto.wallet.web.TopUpPaymentMethod.prototype.getMethod = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.wallet.web.TopUpPaymentMethod} returns this
 */
proto.wallet.web.TopUpPaymentMethod.prototype.setMethod = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double fee = 5;
 * @return {number}
 */
proto.wallet.web.TopUpPaymentMethod.prototype.getFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wallet.web.TopUpPaymentMethod} returns this
 */
proto.wallet.web.TopUpPaymentMethod.prototype.setFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double additional_fee = 6;
 * @return {number}
 */
proto.wallet.web.TopUpPaymentMethod.prototype.getAdditionalFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wallet.web.TopUpPaymentMethod} returns this
 */
proto.wallet.web.TopUpPaymentMethod.prototype.setAdditionalFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional string display_fee = 7;
 * @return {string}
 */
proto.wallet.web.TopUpPaymentMethod.prototype.getDisplayFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.TopUpPaymentMethod} returns this
 */
proto.wallet.web.TopUpPaymentMethod.prototype.setDisplayFee = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string icon = 8;
 * @return {string}
 */
proto.wallet.web.TopUpPaymentMethod.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.TopUpPaymentMethod} returns this
 */
proto.wallet.web.TopUpPaymentMethod.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wallet.web.Payoo.prototype.toObject = function(opt_includeInstance) {
  return proto.wallet.web.Payoo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wallet.web.Payoo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.Payoo.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderXml: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orderChecksum: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wallet.web.Payoo}
 */
proto.wallet.web.Payoo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wallet.web.Payoo;
  return proto.wallet.web.Payoo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wallet.web.Payoo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wallet.web.Payoo}
 */
proto.wallet.web.Payoo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderXml(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderChecksum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wallet.web.Payoo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wallet.web.Payoo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wallet.web.Payoo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.Payoo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderXml();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrderChecksum();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string order_xml = 1;
 * @return {string}
 */
proto.wallet.web.Payoo.prototype.getOrderXml = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.Payoo} returns this
 */
proto.wallet.web.Payoo.prototype.setOrderXml = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string order_checksum = 2;
 * @return {string}
 */
proto.wallet.web.Payoo.prototype.getOrderChecksum = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.Payoo} returns this
 */
proto.wallet.web.Payoo.prototype.setOrderChecksum = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wallet.web.TopUpPaymentSDK.prototype.toObject = function(opt_includeInstance) {
  return proto.wallet.web.TopUpPaymentSDK.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wallet.web.TopUpPaymentSDK} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.TopUpPaymentSDK.toObject = function(includeInstance, msg) {
  var f, obj = {
    payoo: (f = msg.getPayoo()) && proto.wallet.web.Payoo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wallet.web.TopUpPaymentSDK}
 */
proto.wallet.web.TopUpPaymentSDK.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wallet.web.TopUpPaymentSDK;
  return proto.wallet.web.TopUpPaymentSDK.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wallet.web.TopUpPaymentSDK} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wallet.web.TopUpPaymentSDK}
 */
proto.wallet.web.TopUpPaymentSDK.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wallet.web.Payoo;
      reader.readMessage(value,proto.wallet.web.Payoo.deserializeBinaryFromReader);
      msg.setPayoo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wallet.web.TopUpPaymentSDK.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wallet.web.TopUpPaymentSDK.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wallet.web.TopUpPaymentSDK} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.TopUpPaymentSDK.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPayoo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.wallet.web.Payoo.serializeBinaryToWriter
    );
  }
};


/**
 * optional Payoo payoo = 1;
 * @return {?proto.wallet.web.Payoo}
 */
proto.wallet.web.TopUpPaymentSDK.prototype.getPayoo = function() {
  return /** @type{?proto.wallet.web.Payoo} */ (
    jspb.Message.getWrapperField(this, proto.wallet.web.Payoo, 1));
};


/**
 * @param {?proto.wallet.web.Payoo|undefined} value
 * @return {!proto.wallet.web.TopUpPaymentSDK} returns this
*/
proto.wallet.web.TopUpPaymentSDK.prototype.setPayoo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wallet.web.TopUpPaymentSDK} returns this
 */
proto.wallet.web.TopUpPaymentSDK.prototype.clearPayoo = function() {
  return this.setPayoo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wallet.web.TopUpPaymentSDK.prototype.hasPayoo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wallet.web.TopUpPaymentActionData.prototype.toObject = function(opt_includeInstance) {
  return proto.wallet.web.TopUpPaymentActionData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wallet.web.TopUpPaymentActionData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.TopUpPaymentActionData.toObject = function(includeInstance, msg) {
  var f, obj = {
    webviewUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sdk: (f = msg.getSdk()) && proto.wallet.web.TopUpPaymentSDK.toObject(includeInstance, f),
    bankTransferData: (f = msg.getBankTransferData()) && proto.wallet.web.BankTransferData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wallet.web.TopUpPaymentActionData}
 */
proto.wallet.web.TopUpPaymentActionData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wallet.web.TopUpPaymentActionData;
  return proto.wallet.web.TopUpPaymentActionData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wallet.web.TopUpPaymentActionData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wallet.web.TopUpPaymentActionData}
 */
proto.wallet.web.TopUpPaymentActionData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebviewUrl(value);
      break;
    case 2:
      var value = new proto.wallet.web.TopUpPaymentSDK;
      reader.readMessage(value,proto.wallet.web.TopUpPaymentSDK.deserializeBinaryFromReader);
      msg.setSdk(value);
      break;
    case 3:
      var value = new proto.wallet.web.BankTransferData;
      reader.readMessage(value,proto.wallet.web.BankTransferData.deserializeBinaryFromReader);
      msg.setBankTransferData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wallet.web.TopUpPaymentActionData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wallet.web.TopUpPaymentActionData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wallet.web.TopUpPaymentActionData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.TopUpPaymentActionData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWebviewUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSdk();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wallet.web.TopUpPaymentSDK.serializeBinaryToWriter
    );
  }
  f = message.getBankTransferData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.wallet.web.BankTransferData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string webview_url = 1;
 * @return {string}
 */
proto.wallet.web.TopUpPaymentActionData.prototype.getWebviewUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.TopUpPaymentActionData} returns this
 */
proto.wallet.web.TopUpPaymentActionData.prototype.setWebviewUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TopUpPaymentSDK sdk = 2;
 * @return {?proto.wallet.web.TopUpPaymentSDK}
 */
proto.wallet.web.TopUpPaymentActionData.prototype.getSdk = function() {
  return /** @type{?proto.wallet.web.TopUpPaymentSDK} */ (
    jspb.Message.getWrapperField(this, proto.wallet.web.TopUpPaymentSDK, 2));
};


/**
 * @param {?proto.wallet.web.TopUpPaymentSDK|undefined} value
 * @return {!proto.wallet.web.TopUpPaymentActionData} returns this
*/
proto.wallet.web.TopUpPaymentActionData.prototype.setSdk = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wallet.web.TopUpPaymentActionData} returns this
 */
proto.wallet.web.TopUpPaymentActionData.prototype.clearSdk = function() {
  return this.setSdk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wallet.web.TopUpPaymentActionData.prototype.hasSdk = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional BankTransferData bank_transfer_data = 3;
 * @return {?proto.wallet.web.BankTransferData}
 */
proto.wallet.web.TopUpPaymentActionData.prototype.getBankTransferData = function() {
  return /** @type{?proto.wallet.web.BankTransferData} */ (
    jspb.Message.getWrapperField(this, proto.wallet.web.BankTransferData, 3));
};


/**
 * @param {?proto.wallet.web.BankTransferData|undefined} value
 * @return {!proto.wallet.web.TopUpPaymentActionData} returns this
*/
proto.wallet.web.TopUpPaymentActionData.prototype.setBankTransferData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wallet.web.TopUpPaymentActionData} returns this
 */
proto.wallet.web.TopUpPaymentActionData.prototype.clearBankTransferData = function() {
  return this.setBankTransferData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wallet.web.TopUpPaymentActionData.prototype.hasBankTransferData = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wallet.web.BankTransferData.prototype.toObject = function(opt_includeInstance) {
  return proto.wallet.web.BankTransferData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wallet.web.BankTransferData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.BankTransferData.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wallet.web.BankTransferData}
 */
proto.wallet.web.BankTransferData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wallet.web.BankTransferData;
  return proto.wallet.web.BankTransferData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wallet.web.BankTransferData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wallet.web.BankTransferData}
 */
proto.wallet.web.BankTransferData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wallet.web.BankTransferData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wallet.web.BankTransferData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wallet.web.BankTransferData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.BankTransferData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.wallet.web.BankTransferData.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.BankTransferData} returns this
 */
proto.wallet.web.BankTransferData.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wallet.web.GetWalletInfoReq.prototype.toObject = function(opt_includeInstance) {
  return proto.wallet.web.GetWalletInfoReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wallet.web.GetWalletInfoReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.GetWalletInfoReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wallet.web.GetWalletInfoReq}
 */
proto.wallet.web.GetWalletInfoReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wallet.web.GetWalletInfoReq;
  return proto.wallet.web.GetWalletInfoReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wallet.web.GetWalletInfoReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wallet.web.GetWalletInfoReq}
 */
proto.wallet.web.GetWalletInfoReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wallet.web.GetWalletInfoReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wallet.web.GetWalletInfoReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wallet.web.GetWalletInfoReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.GetWalletInfoReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wallet.web.CheckPromotionCodeReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wallet.web.CheckPromotionCodeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.wallet.web.CheckPromotionCodeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wallet.web.CheckPromotionCodeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.CheckPromotionCodeReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    promotionCodesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    orderPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    orderProductType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    orderFlightMetadata: (f = msg.getOrderFlightMetadata()) && proto.wallet.web.OrderFlightMetadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wallet.web.CheckPromotionCodeReq}
 */
proto.wallet.web.CheckPromotionCodeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wallet.web.CheckPromotionCodeReq;
  return proto.wallet.web.CheckPromotionCodeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wallet.web.CheckPromotionCodeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wallet.web.CheckPromotionCodeReq}
 */
proto.wallet.web.CheckPromotionCodeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addPromotionCodes(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOrderPrice(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderProductType(value);
      break;
    case 5:
      var value = new proto.wallet.web.OrderFlightMetadata;
      reader.readMessage(value,proto.wallet.web.OrderFlightMetadata.deserializeBinaryFromReader);
      msg.setOrderFlightMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wallet.web.CheckPromotionCodeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wallet.web.CheckPromotionCodeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wallet.web.CheckPromotionCodeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.CheckPromotionCodeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPromotionCodesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getOrderPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getOrderProductType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOrderFlightMetadata();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.wallet.web.OrderFlightMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string promotion_codes = 1;
 * @return {!Array<string>}
 */
proto.wallet.web.CheckPromotionCodeReq.prototype.getPromotionCodesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wallet.web.CheckPromotionCodeReq} returns this
 */
proto.wallet.web.CheckPromotionCodeReq.prototype.setPromotionCodesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wallet.web.CheckPromotionCodeReq} returns this
 */
proto.wallet.web.CheckPromotionCodeReq.prototype.addPromotionCodes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wallet.web.CheckPromotionCodeReq} returns this
 */
proto.wallet.web.CheckPromotionCodeReq.prototype.clearPromotionCodesList = function() {
  return this.setPromotionCodesList([]);
};


/**
 * optional double order_price = 2;
 * @return {number}
 */
proto.wallet.web.CheckPromotionCodeReq.prototype.getOrderPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wallet.web.CheckPromotionCodeReq} returns this
 */
proto.wallet.web.CheckPromotionCodeReq.prototype.setOrderPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string order_product_type = 4;
 * @return {string}
 */
proto.wallet.web.CheckPromotionCodeReq.prototype.getOrderProductType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.CheckPromotionCodeReq} returns this
 */
proto.wallet.web.CheckPromotionCodeReq.prototype.setOrderProductType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional OrderFlightMetadata order_flight_metadata = 5;
 * @return {?proto.wallet.web.OrderFlightMetadata}
 */
proto.wallet.web.CheckPromotionCodeReq.prototype.getOrderFlightMetadata = function() {
  return /** @type{?proto.wallet.web.OrderFlightMetadata} */ (
    jspb.Message.getWrapperField(this, proto.wallet.web.OrderFlightMetadata, 5));
};


/**
 * @param {?proto.wallet.web.OrderFlightMetadata|undefined} value
 * @return {!proto.wallet.web.CheckPromotionCodeReq} returns this
*/
proto.wallet.web.CheckPromotionCodeReq.prototype.setOrderFlightMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wallet.web.CheckPromotionCodeReq} returns this
 */
proto.wallet.web.CheckPromotionCodeReq.prototype.clearOrderFlightMetadata = function() {
  return this.setOrderFlightMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wallet.web.CheckPromotionCodeReq.prototype.hasOrderFlightMetadata = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wallet.web.TopUpReq.prototype.toObject = function(opt_includeInstance) {
  return proto.wallet.web.TopUpReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wallet.web.TopUpReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.TopUpReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    paymentMethodCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    currencyCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    purchaseOrderId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    redirectUrl: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wallet.web.TopUpReq}
 */
proto.wallet.web.TopUpReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wallet.web.TopUpReq;
  return proto.wallet.web.TopUpReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wallet.web.TopUpReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wallet.web.TopUpReq}
 */
proto.wallet.web.TopUpReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentMethodCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPurchaseOrderId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRedirectUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wallet.web.TopUpReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wallet.web.TopUpReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wallet.web.TopUpReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.TopUpReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getPaymentMethodCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPurchaseOrderId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRedirectUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional double amount = 1;
 * @return {number}
 */
proto.wallet.web.TopUpReq.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wallet.web.TopUpReq} returns this
 */
proto.wallet.web.TopUpReq.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional string payment_method_code = 2;
 * @return {string}
 */
proto.wallet.web.TopUpReq.prototype.getPaymentMethodCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.TopUpReq} returns this
 */
proto.wallet.web.TopUpReq.prototype.setPaymentMethodCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string currency_code = 3;
 * @return {string}
 */
proto.wallet.web.TopUpReq.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.TopUpReq} returns this
 */
proto.wallet.web.TopUpReq.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string purchase_order_id = 4;
 * @return {string}
 */
proto.wallet.web.TopUpReq.prototype.getPurchaseOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.TopUpReq} returns this
 */
proto.wallet.web.TopUpReq.prototype.setPurchaseOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string redirect_url = 5;
 * @return {string}
 */
proto.wallet.web.TopUpReq.prototype.getRedirectUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.TopUpReq} returns this
 */
proto.wallet.web.TopUpReq.prototype.setRedirectUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wallet.web.CalculateTopUpAmountReq.prototype.toObject = function(opt_includeInstance) {
  return proto.wallet.web.CalculateTopUpAmountReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wallet.web.CalculateTopUpAmountReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.CalculateTopUpAmountReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    paymentMethodCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    currencyCode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wallet.web.CalculateTopUpAmountReq}
 */
proto.wallet.web.CalculateTopUpAmountReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wallet.web.CalculateTopUpAmountReq;
  return proto.wallet.web.CalculateTopUpAmountReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wallet.web.CalculateTopUpAmountReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wallet.web.CalculateTopUpAmountReq}
 */
proto.wallet.web.CalculateTopUpAmountReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentMethodCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wallet.web.CalculateTopUpAmountReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wallet.web.CalculateTopUpAmountReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wallet.web.CalculateTopUpAmountReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.CalculateTopUpAmountReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getPaymentMethodCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional double amount = 1;
 * @return {number}
 */
proto.wallet.web.CalculateTopUpAmountReq.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wallet.web.CalculateTopUpAmountReq} returns this
 */
proto.wallet.web.CalculateTopUpAmountReq.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional string payment_method_code = 2;
 * @return {string}
 */
proto.wallet.web.CalculateTopUpAmountReq.prototype.getPaymentMethodCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.CalculateTopUpAmountReq} returns this
 */
proto.wallet.web.CalculateTopUpAmountReq.prototype.setPaymentMethodCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string currency_code = 3;
 * @return {string}
 */
proto.wallet.web.CalculateTopUpAmountReq.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.CalculateTopUpAmountReq} returns this
 */
proto.wallet.web.CalculateTopUpAmountReq.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wallet.web.GetWalletInfoRes.prototype.toObject = function(opt_includeInstance) {
  return proto.wallet.web.GetWalletInfoRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wallet.web.GetWalletInfoRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.GetWalletInfoRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    wallet: (f = msg.getWallet()) && proto.wallet.web.Wallet.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wallet.web.GetWalletInfoRes}
 */
proto.wallet.web.GetWalletInfoRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wallet.web.GetWalletInfoRes;
  return proto.wallet.web.GetWalletInfoRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wallet.web.GetWalletInfoRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wallet.web.GetWalletInfoRes}
 */
proto.wallet.web.GetWalletInfoRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wallet.web.Wallet;
      reader.readMessage(value,proto.wallet.web.Wallet.deserializeBinaryFromReader);
      msg.setWallet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wallet.web.GetWalletInfoRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wallet.web.GetWalletInfoRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wallet.web.GetWalletInfoRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.GetWalletInfoRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWallet();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.wallet.web.Wallet.serializeBinaryToWriter
    );
  }
};


/**
 * optional Wallet wallet = 1;
 * @return {?proto.wallet.web.Wallet}
 */
proto.wallet.web.GetWalletInfoRes.prototype.getWallet = function() {
  return /** @type{?proto.wallet.web.Wallet} */ (
    jspb.Message.getWrapperField(this, proto.wallet.web.Wallet, 1));
};


/**
 * @param {?proto.wallet.web.Wallet|undefined} value
 * @return {!proto.wallet.web.GetWalletInfoRes} returns this
*/
proto.wallet.web.GetWalletInfoRes.prototype.setWallet = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wallet.web.GetWalletInfoRes} returns this
 */
proto.wallet.web.GetWalletInfoRes.prototype.clearWallet = function() {
  return this.setWallet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wallet.web.GetWalletInfoRes.prototype.hasWallet = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wallet.web.PromoErrorRes.prototype.toObject = function(opt_includeInstance) {
  return proto.wallet.web.PromoErrorRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wallet.web.PromoErrorRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.PromoErrorRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wallet.web.PromoErrorRes}
 */
proto.wallet.web.PromoErrorRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wallet.web.PromoErrorRes;
  return proto.wallet.web.PromoErrorRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wallet.web.PromoErrorRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wallet.web.PromoErrorRes}
 */
proto.wallet.web.PromoErrorRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wallet.web.PromoErrorRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wallet.web.PromoErrorRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wallet.web.PromoErrorRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.PromoErrorRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.wallet.web.PromoErrorRes.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.PromoErrorRes} returns this
 */
proto.wallet.web.PromoErrorRes.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.wallet.web.PromoErrorRes.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.PromoErrorRes} returns this
 */
proto.wallet.web.PromoErrorRes.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wallet.web.PromoApplyRes.prototype.toObject = function(opt_includeInstance) {
  return proto.wallet.web.PromoApplyRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wallet.web.PromoApplyRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.PromoApplyRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wallet.web.PromoApplyRes}
 */
proto.wallet.web.PromoApplyRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wallet.web.PromoApplyRes;
  return proto.wallet.web.PromoApplyRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wallet.web.PromoApplyRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wallet.web.PromoApplyRes}
 */
proto.wallet.web.PromoApplyRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wallet.web.PromoApplyRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wallet.web.PromoApplyRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wallet.web.PromoApplyRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.PromoApplyRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.wallet.web.PromoApplyRes.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.PromoApplyRes} returns this
 */
proto.wallet.web.PromoApplyRes.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double amount = 2;
 * @return {number}
 */
proto.wallet.web.PromoApplyRes.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wallet.web.PromoApplyRes} returns this
 */
proto.wallet.web.PromoApplyRes.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wallet.web.TopUpRes.prototype.toObject = function(opt_includeInstance) {
  return proto.wallet.web.TopUpRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wallet.web.TopUpRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.TopUpRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    action: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actionData: (f = msg.getActionData()) && proto.wallet.web.TopUpPaymentActionData.toObject(includeInstance, f),
    payment: (f = msg.getPayment()) && proto.wallet.web.TopUpPaymentInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wallet.web.TopUpRes}
 */
proto.wallet.web.TopUpRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wallet.web.TopUpRes;
  return proto.wallet.web.TopUpRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wallet.web.TopUpRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wallet.web.TopUpRes}
 */
proto.wallet.web.TopUpRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {!proto.wallet.web.TopUpPaymentAction} */ (reader.readEnum());
      msg.setAction(value);
      break;
    case 3:
      var value = new proto.wallet.web.TopUpPaymentActionData;
      reader.readMessage(value,proto.wallet.web.TopUpPaymentActionData.deserializeBinaryFromReader);
      msg.setActionData(value);
      break;
    case 4:
      var value = new proto.wallet.web.TopUpPaymentInfo;
      reader.readMessage(value,proto.wallet.web.TopUpPaymentInfo.deserializeBinaryFromReader);
      msg.setPayment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wallet.web.TopUpRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wallet.web.TopUpRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wallet.web.TopUpRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.TopUpRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getActionData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.wallet.web.TopUpPaymentActionData.serializeBinaryToWriter
    );
  }
  f = message.getPayment();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.wallet.web.TopUpPaymentInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.wallet.web.TopUpRes.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wallet.web.TopUpRes} returns this
 */
proto.wallet.web.TopUpRes.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional TopUpPaymentAction action = 2;
 * @return {!proto.wallet.web.TopUpPaymentAction}
 */
proto.wallet.web.TopUpRes.prototype.getAction = function() {
  return /** @type {!proto.wallet.web.TopUpPaymentAction} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wallet.web.TopUpPaymentAction} value
 * @return {!proto.wallet.web.TopUpRes} returns this
 */
proto.wallet.web.TopUpRes.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional TopUpPaymentActionData action_data = 3;
 * @return {?proto.wallet.web.TopUpPaymentActionData}
 */
proto.wallet.web.TopUpRes.prototype.getActionData = function() {
  return /** @type{?proto.wallet.web.TopUpPaymentActionData} */ (
    jspb.Message.getWrapperField(this, proto.wallet.web.TopUpPaymentActionData, 3));
};


/**
 * @param {?proto.wallet.web.TopUpPaymentActionData|undefined} value
 * @return {!proto.wallet.web.TopUpRes} returns this
*/
proto.wallet.web.TopUpRes.prototype.setActionData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wallet.web.TopUpRes} returns this
 */
proto.wallet.web.TopUpRes.prototype.clearActionData = function() {
  return this.setActionData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wallet.web.TopUpRes.prototype.hasActionData = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TopUpPaymentInfo payment = 4;
 * @return {?proto.wallet.web.TopUpPaymentInfo}
 */
proto.wallet.web.TopUpRes.prototype.getPayment = function() {
  return /** @type{?proto.wallet.web.TopUpPaymentInfo} */ (
    jspb.Message.getWrapperField(this, proto.wallet.web.TopUpPaymentInfo, 4));
};


/**
 * @param {?proto.wallet.web.TopUpPaymentInfo|undefined} value
 * @return {!proto.wallet.web.TopUpRes} returns this
*/
proto.wallet.web.TopUpRes.prototype.setPayment = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wallet.web.TopUpRes} returns this
 */
proto.wallet.web.TopUpRes.prototype.clearPayment = function() {
  return this.setPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wallet.web.TopUpRes.prototype.hasPayment = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wallet.web.CheckPromotionCodeRes.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wallet.web.CheckPromotionCodeRes.prototype.toObject = function(opt_includeInstance) {
  return proto.wallet.web.CheckPromotionCodeRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wallet.web.CheckPromotionCodeRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.CheckPromotionCodeRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    promotionAppliedList: jspb.Message.toObjectList(msg.getPromotionAppliedList(),
    proto.wallet.web.PromoApplyRes.toObject, includeInstance),
    promotionErrorsList: jspb.Message.toObjectList(msg.getPromotionErrorsList(),
    proto.wallet.web.PromoErrorRes.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wallet.web.CheckPromotionCodeRes}
 */
proto.wallet.web.CheckPromotionCodeRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wallet.web.CheckPromotionCodeRes;
  return proto.wallet.web.CheckPromotionCodeRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wallet.web.CheckPromotionCodeRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wallet.web.CheckPromotionCodeRes}
 */
proto.wallet.web.CheckPromotionCodeRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = new proto.wallet.web.PromoApplyRes;
      reader.readMessage(value,proto.wallet.web.PromoApplyRes.deserializeBinaryFromReader);
      msg.addPromotionApplied(value);
      break;
    case 3:
      var value = new proto.wallet.web.PromoErrorRes;
      reader.readMessage(value,proto.wallet.web.PromoErrorRes.deserializeBinaryFromReader);
      msg.addPromotionErrors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wallet.web.CheckPromotionCodeRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wallet.web.CheckPromotionCodeRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wallet.web.CheckPromotionCodeRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.CheckPromotionCodeRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getPromotionAppliedList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wallet.web.PromoApplyRes.serializeBinaryToWriter
    );
  }
  f = message.getPromotionErrorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wallet.web.PromoErrorRes.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.wallet.web.CheckPromotionCodeRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wallet.web.CheckPromotionCodeRes} returns this
 */
proto.wallet.web.CheckPromotionCodeRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated PromoApplyRes promotion_applied = 2;
 * @return {!Array<!proto.wallet.web.PromoApplyRes>}
 */
proto.wallet.web.CheckPromotionCodeRes.prototype.getPromotionAppliedList = function() {
  return /** @type{!Array<!proto.wallet.web.PromoApplyRes>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wallet.web.PromoApplyRes, 2));
};


/**
 * @param {!Array<!proto.wallet.web.PromoApplyRes>} value
 * @return {!proto.wallet.web.CheckPromotionCodeRes} returns this
*/
proto.wallet.web.CheckPromotionCodeRes.prototype.setPromotionAppliedList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wallet.web.PromoApplyRes=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wallet.web.PromoApplyRes}
 */
proto.wallet.web.CheckPromotionCodeRes.prototype.addPromotionApplied = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wallet.web.PromoApplyRes, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wallet.web.CheckPromotionCodeRes} returns this
 */
proto.wallet.web.CheckPromotionCodeRes.prototype.clearPromotionAppliedList = function() {
  return this.setPromotionAppliedList([]);
};


/**
 * repeated PromoErrorRes promotion_errors = 3;
 * @return {!Array<!proto.wallet.web.PromoErrorRes>}
 */
proto.wallet.web.CheckPromotionCodeRes.prototype.getPromotionErrorsList = function() {
  return /** @type{!Array<!proto.wallet.web.PromoErrorRes>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wallet.web.PromoErrorRes, 3));
};


/**
 * @param {!Array<!proto.wallet.web.PromoErrorRes>} value
 * @return {!proto.wallet.web.CheckPromotionCodeRes} returns this
*/
proto.wallet.web.CheckPromotionCodeRes.prototype.setPromotionErrorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wallet.web.PromoErrorRes=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wallet.web.PromoErrorRes}
 */
proto.wallet.web.CheckPromotionCodeRes.prototype.addPromotionErrors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wallet.web.PromoErrorRes, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wallet.web.CheckPromotionCodeRes} returns this
 */
proto.wallet.web.CheckPromotionCodeRes.prototype.clearPromotionErrorsList = function() {
  return this.setPromotionErrorsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wallet.web.CalculateTopUpAmountRes.prototype.toObject = function(opt_includeInstance) {
  return proto.wallet.web.CalculateTopUpAmountRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wallet.web.CalculateTopUpAmountRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.CalculateTopUpAmountRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    fee: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    totalPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    currencyCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    paymentMethod: (f = msg.getPaymentMethod()) && proto.wallet.web.TopUpPaymentMethod.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wallet.web.CalculateTopUpAmountRes}
 */
proto.wallet.web.CalculateTopUpAmountRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wallet.web.CalculateTopUpAmountRes;
  return proto.wallet.web.CalculateTopUpAmountRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wallet.web.CalculateTopUpAmountRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wallet.web.CalculateTopUpAmountRes}
 */
proto.wallet.web.CalculateTopUpAmountRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFee(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalPrice(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    case 7:
      var value = new proto.wallet.web.TopUpPaymentMethod;
      reader.readMessage(value,proto.wallet.web.TopUpPaymentMethod.deserializeBinaryFromReader);
      msg.setPaymentMethod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wallet.web.CalculateTopUpAmountRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wallet.web.CalculateTopUpAmountRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wallet.web.CalculateTopUpAmountRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.CalculateTopUpAmountRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getFee();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getTotalPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPaymentMethod();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.wallet.web.TopUpPaymentMethod.serializeBinaryToWriter
    );
  }
};


/**
 * optional double amount = 3;
 * @return {number}
 */
proto.wallet.web.CalculateTopUpAmountRes.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wallet.web.CalculateTopUpAmountRes} returns this
 */
proto.wallet.web.CalculateTopUpAmountRes.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double fee = 4;
 * @return {number}
 */
proto.wallet.web.CalculateTopUpAmountRes.prototype.getFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wallet.web.CalculateTopUpAmountRes} returns this
 */
proto.wallet.web.CalculateTopUpAmountRes.prototype.setFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double total_price = 5;
 * @return {number}
 */
proto.wallet.web.CalculateTopUpAmountRes.prototype.getTotalPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wallet.web.CalculateTopUpAmountRes} returns this
 */
proto.wallet.web.CalculateTopUpAmountRes.prototype.setTotalPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string currency_code = 6;
 * @return {string}
 */
proto.wallet.web.CalculateTopUpAmountRes.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.CalculateTopUpAmountRes} returns this
 */
proto.wallet.web.CalculateTopUpAmountRes.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional TopUpPaymentMethod payment_method = 7;
 * @return {?proto.wallet.web.TopUpPaymentMethod}
 */
proto.wallet.web.CalculateTopUpAmountRes.prototype.getPaymentMethod = function() {
  return /** @type{?proto.wallet.web.TopUpPaymentMethod} */ (
    jspb.Message.getWrapperField(this, proto.wallet.web.TopUpPaymentMethod, 7));
};


/**
 * @param {?proto.wallet.web.TopUpPaymentMethod|undefined} value
 * @return {!proto.wallet.web.CalculateTopUpAmountRes} returns this
*/
proto.wallet.web.CalculateTopUpAmountRes.prototype.setPaymentMethod = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wallet.web.CalculateTopUpAmountRes} returns this
 */
proto.wallet.web.CalculateTopUpAmountRes.prototype.clearPaymentMethod = function() {
  return this.setPaymentMethod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wallet.web.CalculateTopUpAmountRes.prototype.hasPaymentMethod = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wallet.web.PaymentAmount.prototype.toObject = function(opt_includeInstance) {
  return proto.wallet.web.PaymentAmount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wallet.web.PaymentAmount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.PaymentAmount.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    fee: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    totalPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    currencyCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    paymentMethod: (f = msg.getPaymentMethod()) && proto.wallet.web.TopUpPaymentMethod.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wallet.web.PaymentAmount}
 */
proto.wallet.web.PaymentAmount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wallet.web.PaymentAmount;
  return proto.wallet.web.PaymentAmount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wallet.web.PaymentAmount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wallet.web.PaymentAmount}
 */
proto.wallet.web.PaymentAmount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFee(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalPrice(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    case 7:
      var value = new proto.wallet.web.TopUpPaymentMethod;
      reader.readMessage(value,proto.wallet.web.TopUpPaymentMethod.deserializeBinaryFromReader);
      msg.setPaymentMethod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wallet.web.PaymentAmount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wallet.web.PaymentAmount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wallet.web.PaymentAmount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.PaymentAmount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getFee();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getTotalPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPaymentMethod();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.wallet.web.TopUpPaymentMethod.serializeBinaryToWriter
    );
  }
};


/**
 * optional double amount = 3;
 * @return {number}
 */
proto.wallet.web.PaymentAmount.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wallet.web.PaymentAmount} returns this
 */
proto.wallet.web.PaymentAmount.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double fee = 4;
 * @return {number}
 */
proto.wallet.web.PaymentAmount.prototype.getFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wallet.web.PaymentAmount} returns this
 */
proto.wallet.web.PaymentAmount.prototype.setFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double total_price = 5;
 * @return {number}
 */
proto.wallet.web.PaymentAmount.prototype.getTotalPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wallet.web.PaymentAmount} returns this
 */
proto.wallet.web.PaymentAmount.prototype.setTotalPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string currency_code = 6;
 * @return {string}
 */
proto.wallet.web.PaymentAmount.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.web.PaymentAmount} returns this
 */
proto.wallet.web.PaymentAmount.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional TopUpPaymentMethod payment_method = 7;
 * @return {?proto.wallet.web.TopUpPaymentMethod}
 */
proto.wallet.web.PaymentAmount.prototype.getPaymentMethod = function() {
  return /** @type{?proto.wallet.web.TopUpPaymentMethod} */ (
    jspb.Message.getWrapperField(this, proto.wallet.web.TopUpPaymentMethod, 7));
};


/**
 * @param {?proto.wallet.web.TopUpPaymentMethod|undefined} value
 * @return {!proto.wallet.web.PaymentAmount} returns this
*/
proto.wallet.web.PaymentAmount.prototype.setPaymentMethod = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wallet.web.PaymentAmount} returns this
 */
proto.wallet.web.PaymentAmount.prototype.clearPaymentMethod = function() {
  return this.setPaymentMethod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wallet.web.PaymentAmount.prototype.hasPaymentMethod = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wallet.web.CalculateTopUpAmountV2Res.prototype.toObject = function(opt_includeInstance) {
  return proto.wallet.web.CalculateTopUpAmountV2Res.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wallet.web.CalculateTopUpAmountV2Res} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.CalculateTopUpAmountV2Res.toObject = function(includeInstance, msg) {
  var f, obj = {
    payment: (f = msg.getPayment()) && proto.wallet.web.PaymentAmount.toObject(includeInstance, f),
    display: (f = msg.getDisplay()) && proto.wallet.web.PaymentAmount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wallet.web.CalculateTopUpAmountV2Res}
 */
proto.wallet.web.CalculateTopUpAmountV2Res.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wallet.web.CalculateTopUpAmountV2Res;
  return proto.wallet.web.CalculateTopUpAmountV2Res.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wallet.web.CalculateTopUpAmountV2Res} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wallet.web.CalculateTopUpAmountV2Res}
 */
proto.wallet.web.CalculateTopUpAmountV2Res.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wallet.web.PaymentAmount;
      reader.readMessage(value,proto.wallet.web.PaymentAmount.deserializeBinaryFromReader);
      msg.setPayment(value);
      break;
    case 2:
      var value = new proto.wallet.web.PaymentAmount;
      reader.readMessage(value,proto.wallet.web.PaymentAmount.deserializeBinaryFromReader);
      msg.setDisplay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wallet.web.CalculateTopUpAmountV2Res.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wallet.web.CalculateTopUpAmountV2Res.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wallet.web.CalculateTopUpAmountV2Res} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.CalculateTopUpAmountV2Res.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPayment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.wallet.web.PaymentAmount.serializeBinaryToWriter
    );
  }
  f = message.getDisplay();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wallet.web.PaymentAmount.serializeBinaryToWriter
    );
  }
};


/**
 * optional PaymentAmount payment = 1;
 * @return {?proto.wallet.web.PaymentAmount}
 */
proto.wallet.web.CalculateTopUpAmountV2Res.prototype.getPayment = function() {
  return /** @type{?proto.wallet.web.PaymentAmount} */ (
    jspb.Message.getWrapperField(this, proto.wallet.web.PaymentAmount, 1));
};


/**
 * @param {?proto.wallet.web.PaymentAmount|undefined} value
 * @return {!proto.wallet.web.CalculateTopUpAmountV2Res} returns this
*/
proto.wallet.web.CalculateTopUpAmountV2Res.prototype.setPayment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wallet.web.CalculateTopUpAmountV2Res} returns this
 */
proto.wallet.web.CalculateTopUpAmountV2Res.prototype.clearPayment = function() {
  return this.setPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wallet.web.CalculateTopUpAmountV2Res.prototype.hasPayment = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PaymentAmount display = 2;
 * @return {?proto.wallet.web.PaymentAmount}
 */
proto.wallet.web.CalculateTopUpAmountV2Res.prototype.getDisplay = function() {
  return /** @type{?proto.wallet.web.PaymentAmount} */ (
    jspb.Message.getWrapperField(this, proto.wallet.web.PaymentAmount, 2));
};


/**
 * @param {?proto.wallet.web.PaymentAmount|undefined} value
 * @return {!proto.wallet.web.CalculateTopUpAmountV2Res} returns this
*/
proto.wallet.web.CalculateTopUpAmountV2Res.prototype.setDisplay = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wallet.web.CalculateTopUpAmountV2Res} returns this
 */
proto.wallet.web.CalculateTopUpAmountV2Res.prototype.clearDisplay = function() {
  return this.setDisplay(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wallet.web.CalculateTopUpAmountV2Res.prototype.hasDisplay = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wallet.web.MyWalletReq.prototype.toObject = function(opt_includeInstance) {
  return proto.wallet.web.MyWalletReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wallet.web.MyWalletReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.MyWalletReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wallet.web.MyWalletReq}
 */
proto.wallet.web.MyWalletReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wallet.web.MyWalletReq;
  return proto.wallet.web.MyWalletReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wallet.web.MyWalletReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wallet.web.MyWalletReq}
 */
proto.wallet.web.MyWalletReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wallet.web.MyWalletReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wallet.web.MyWalletReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wallet.web.MyWalletReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.MyWalletReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wallet.web.MyWalletRes.prototype.toObject = function(opt_includeInstance) {
  return proto.wallet.web.MyWalletRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wallet.web.MyWalletRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.MyWalletRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && proto.wallet.web.WalletBasic.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wallet.web.MyWalletRes}
 */
proto.wallet.web.MyWalletRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wallet.web.MyWalletRes;
  return proto.wallet.web.MyWalletRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wallet.web.MyWalletRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wallet.web.MyWalletRes}
 */
proto.wallet.web.MyWalletRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wallet.web.WalletBasic;
      reader.readMessage(value,proto.wallet.web.WalletBasic.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wallet.web.MyWalletRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wallet.web.MyWalletRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wallet.web.MyWalletRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.web.MyWalletRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.wallet.web.WalletBasic.serializeBinaryToWriter
    );
  }
};


/**
 * optional WalletBasic info = 1;
 * @return {?proto.wallet.web.WalletBasic}
 */
proto.wallet.web.MyWalletRes.prototype.getInfo = function() {
  return /** @type{?proto.wallet.web.WalletBasic} */ (
    jspb.Message.getWrapperField(this, proto.wallet.web.WalletBasic, 1));
};


/**
 * @param {?proto.wallet.web.WalletBasic|undefined} value
 * @return {!proto.wallet.web.MyWalletRes} returns this
*/
proto.wallet.web.MyWalletRes.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wallet.web.MyWalletRes} returns this
 */
proto.wallet.web.MyWalletRes.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wallet.web.MyWalletRes.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * @enum {number}
 */
proto.wallet.web.TopUpPaymentAction = {
  TOP_UP_PAYMENT_ACTION_WEB_VIEW: 0,
  TOP_UP_PAYMENT_ACTION_BROWSER: 1,
  TOP_UP_PAYMENT_ACTION_IN_APP_SDK: 2,
  TOP_UP_PAYMENT_ACTION_BANK_TRANSFER: 3
};

goog.object.extend(exports, proto.wallet.web);
