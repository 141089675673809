// source: wallet/transaction.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var wallet_base_pb = require('../wallet/base_pb.js');
goog.object.extend(proto, wallet_base_pb);
var base_enum_pb = require('../base/enum_pb.js');
goog.object.extend(proto, base_enum_pb);
var validate_validate_pb = require('../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.wallet.Filter', null, global);
goog.exportSymbol('proto.wallet.ServiceMetadata', null, global);
goog.exportSymbol('proto.wallet.TopUpPaymentActionData', null, global);
goog.exportSymbol('proto.wallet.TopUpPaymentInfo', null, global);
goog.exportSymbol('proto.wallet.TopUpPaymentMethod', null, global);
goog.exportSymbol('proto.wallet.Transaction', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wallet.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wallet.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wallet.Filter.displayName = 'proto.wallet.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wallet.Transaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wallet.Transaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wallet.Transaction.displayName = 'proto.wallet.Transaction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wallet.ServiceMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wallet.ServiceMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wallet.ServiceMetadata.displayName = 'proto.wallet.ServiceMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wallet.TopUpPaymentActionData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wallet.TopUpPaymentActionData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wallet.TopUpPaymentActionData.displayName = 'proto.wallet.TopUpPaymentActionData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wallet.TopUpPaymentInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wallet.TopUpPaymentInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wallet.TopUpPaymentInfo.displayName = 'proto.wallet.TopUpPaymentInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wallet.TopUpPaymentMethod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wallet.TopUpPaymentMethod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wallet.TopUpPaymentMethod.displayName = 'proto.wallet.TopUpPaymentMethod';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wallet.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.wallet.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wallet.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    referenceCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fromDate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    toDate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    bookerId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    partnerShopId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    transactionType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    transactionSource: jspb.Message.getFieldWithDefault(msg, 7, ""),
    productType: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wallet.Filter}
 */
proto.wallet.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wallet.Filter;
  return proto.wallet.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wallet.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wallet.Filter}
 */
proto.wallet.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferenceCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFromDate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setToDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookerId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnerShopId(value);
      break;
    case 6:
      var value = /** @type {!proto.base.TransactionType} */ (reader.readEnum());
      msg.setTransactionType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionSource(value);
      break;
    case 8:
      var value = /** @type {!proto.base.ProductType} */ (reader.readEnum());
      msg.setProductType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wallet.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wallet.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wallet.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTransactionType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getProductType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * optional string reference_code = 1;
 * @return {string}
 */
proto.wallet.Filter.prototype.getReferenceCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.Filter} returns this
 */
proto.wallet.Filter.prototype.setReferenceCode = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wallet.Filter} returns this
 */
proto.wallet.Filter.prototype.clearReferenceCode = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wallet.Filter.prototype.hasReferenceCode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 from_date = 2;
 * @return {number}
 */
proto.wallet.Filter.prototype.getFromDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wallet.Filter} returns this
 */
proto.wallet.Filter.prototype.setFromDate = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wallet.Filter} returns this
 */
proto.wallet.Filter.prototype.clearFromDate = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wallet.Filter.prototype.hasFromDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 to_date = 3;
 * @return {number}
 */
proto.wallet.Filter.prototype.getToDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wallet.Filter} returns this
 */
proto.wallet.Filter.prototype.setToDate = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wallet.Filter} returns this
 */
proto.wallet.Filter.prototype.clearToDate = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wallet.Filter.prototype.hasToDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string booker_id = 4;
 * @return {string}
 */
proto.wallet.Filter.prototype.getBookerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.Filter} returns this
 */
proto.wallet.Filter.prototype.setBookerId = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wallet.Filter} returns this
 */
proto.wallet.Filter.prototype.clearBookerId = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wallet.Filter.prototype.hasBookerId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string partner_shop_id = 5;
 * @return {string}
 */
proto.wallet.Filter.prototype.getPartnerShopId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.Filter} returns this
 */
proto.wallet.Filter.prototype.setPartnerShopId = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wallet.Filter} returns this
 */
proto.wallet.Filter.prototype.clearPartnerShopId = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wallet.Filter.prototype.hasPartnerShopId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional base.TransactionType transaction_type = 6;
 * @return {!proto.base.TransactionType}
 */
proto.wallet.Filter.prototype.getTransactionType = function() {
  return /** @type {!proto.base.TransactionType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.base.TransactionType} value
 * @return {!proto.wallet.Filter} returns this
 */
proto.wallet.Filter.prototype.setTransactionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string transaction_source = 7;
 * @return {string}
 */
proto.wallet.Filter.prototype.getTransactionSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.Filter} returns this
 */
proto.wallet.Filter.prototype.setTransactionSource = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wallet.Filter} returns this
 */
proto.wallet.Filter.prototype.clearTransactionSource = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wallet.Filter.prototype.hasTransactionSource = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional base.ProductType product_type = 8;
 * @return {!proto.base.ProductType}
 */
proto.wallet.Filter.prototype.getProductType = function() {
  return /** @type {!proto.base.ProductType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.base.ProductType} value
 * @return {!proto.wallet.Filter} returns this
 */
proto.wallet.Filter.prototype.setProductType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wallet.Transaction.prototype.toObject = function(opt_includeInstance) {
  return proto.wallet.Transaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wallet.Transaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.Transaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    partnershipId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    partnerShopId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    transactionType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    agentCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0),
    paymentMethod: jspb.Message.getFieldWithDefault(msg, 8, 0),
    sender: (f = msg.getSender()) && wallet_base_pb.Sender.toObject(includeInstance, f),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 11, ""),
    fee: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    description: jspb.Message.getFieldWithDefault(msg, 13, ""),
    transactionSource: jspb.Message.getFieldWithDefault(msg, 14, ""),
    referenceCode: jspb.Message.getFieldWithDefault(msg, 15, ""),
    productType: jspb.Message.getFieldWithDefault(msg, 16, 0),
    transactionId: jspb.Message.getFieldWithDefault(msg, 17, ""),
    serviceMetadata: (f = msg.getServiceMetadata()) && proto.wallet.ServiceMetadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wallet.Transaction}
 */
proto.wallet.Transaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wallet.Transaction;
  return proto.wallet.Transaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wallet.Transaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wallet.Transaction}
 */
proto.wallet.Transaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnershipId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnerShopId(value);
      break;
    case 5:
      var value = /** @type {!proto.base.TransactionType} */ (reader.readEnum());
      msg.setTransactionType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentCode(value);
      break;
    case 7:
      var value = /** @type {!proto.base.TransactionStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {!proto.base.PaymentMethod} */ (reader.readEnum());
      msg.setPaymentMethod(value);
      break;
    case 9:
      var value = new wallet_base_pb.Sender;
      reader.readMessage(value,wallet_base_pb.Sender.deserializeBinaryFromReader);
      msg.setSender(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFee(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionSource(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferenceCode(value);
      break;
    case 16:
      var value = /** @type {!proto.base.ProductType} */ (reader.readEnum());
      msg.setProductType(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionId(value);
      break;
    case 18:
      var value = new proto.wallet.ServiceMetadata;
      reader.readMessage(value,proto.wallet.ServiceMetadata.deserializeBinaryFromReader);
      msg.setServiceMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wallet.Transaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wallet.Transaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wallet.Transaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.Transaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPartnershipId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPartnerShopId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTransactionType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getAgentCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getPaymentMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getSender();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      wallet_base_pb.Sender.serializeBinaryToWriter
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getFee();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getTransactionSource();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getReferenceCode();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getProductType();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getTransactionId();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getServiceMetadata();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.wallet.ServiceMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wallet.Transaction.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.Transaction} returns this
 */
proto.wallet.Transaction.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 created_at = 2;
 * @return {number}
 */
proto.wallet.Transaction.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wallet.Transaction} returns this
 */
proto.wallet.Transaction.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string partnership_id = 3;
 * @return {string}
 */
proto.wallet.Transaction.prototype.getPartnershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.Transaction} returns this
 */
proto.wallet.Transaction.prototype.setPartnershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string partner_shop_id = 4;
 * @return {string}
 */
proto.wallet.Transaction.prototype.getPartnerShopId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.Transaction} returns this
 */
proto.wallet.Transaction.prototype.setPartnerShopId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional base.TransactionType transaction_type = 5;
 * @return {!proto.base.TransactionType}
 */
proto.wallet.Transaction.prototype.getTransactionType = function() {
  return /** @type {!proto.base.TransactionType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.base.TransactionType} value
 * @return {!proto.wallet.Transaction} returns this
 */
proto.wallet.Transaction.prototype.setTransactionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string agent_code = 6;
 * @return {string}
 */
proto.wallet.Transaction.prototype.getAgentCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.Transaction} returns this
 */
proto.wallet.Transaction.prototype.setAgentCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional base.TransactionStatus status = 7;
 * @return {!proto.base.TransactionStatus}
 */
proto.wallet.Transaction.prototype.getStatus = function() {
  return /** @type {!proto.base.TransactionStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.base.TransactionStatus} value
 * @return {!proto.wallet.Transaction} returns this
 */
proto.wallet.Transaction.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional base.PaymentMethod payment_method = 8;
 * @return {!proto.base.PaymentMethod}
 */
proto.wallet.Transaction.prototype.getPaymentMethod = function() {
  return /** @type {!proto.base.PaymentMethod} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.base.PaymentMethod} value
 * @return {!proto.wallet.Transaction} returns this
 */
proto.wallet.Transaction.prototype.setPaymentMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional Sender sender = 9;
 * @return {?proto.wallet.Sender}
 */
proto.wallet.Transaction.prototype.getSender = function() {
  return /** @type{?proto.wallet.Sender} */ (
    jspb.Message.getWrapperField(this, wallet_base_pb.Sender, 9));
};


/**
 * @param {?proto.wallet.Sender|undefined} value
 * @return {!proto.wallet.Transaction} returns this
*/
proto.wallet.Transaction.prototype.setSender = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wallet.Transaction} returns this
 */
proto.wallet.Transaction.prototype.clearSender = function() {
  return this.setSender(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wallet.Transaction.prototype.hasSender = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional double amount = 10;
 * @return {number}
 */
proto.wallet.Transaction.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wallet.Transaction} returns this
 */
proto.wallet.Transaction.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional string currency = 11;
 * @return {string}
 */
proto.wallet.Transaction.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.Transaction} returns this
 */
proto.wallet.Transaction.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional double fee = 12;
 * @return {number}
 */
proto.wallet.Transaction.prototype.getFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wallet.Transaction} returns this
 */
proto.wallet.Transaction.prototype.setFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional string description = 13;
 * @return {string}
 */
proto.wallet.Transaction.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.Transaction} returns this
 */
proto.wallet.Transaction.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string transaction_source = 14;
 * @return {string}
 */
proto.wallet.Transaction.prototype.getTransactionSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.Transaction} returns this
 */
proto.wallet.Transaction.prototype.setTransactionSource = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string reference_code = 15;
 * @return {string}
 */
proto.wallet.Transaction.prototype.getReferenceCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.Transaction} returns this
 */
proto.wallet.Transaction.prototype.setReferenceCode = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional base.ProductType product_type = 16;
 * @return {!proto.base.ProductType}
 */
proto.wallet.Transaction.prototype.getProductType = function() {
  return /** @type {!proto.base.ProductType} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.base.ProductType} value
 * @return {!proto.wallet.Transaction} returns this
 */
proto.wallet.Transaction.prototype.setProductType = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional string transaction_id = 17;
 * @return {string}
 */
proto.wallet.Transaction.prototype.getTransactionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.Transaction} returns this
 */
proto.wallet.Transaction.prototype.setTransactionId = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional ServiceMetadata service_metadata = 18;
 * @return {?proto.wallet.ServiceMetadata}
 */
proto.wallet.Transaction.prototype.getServiceMetadata = function() {
  return /** @type{?proto.wallet.ServiceMetadata} */ (
    jspb.Message.getWrapperField(this, proto.wallet.ServiceMetadata, 18));
};


/**
 * @param {?proto.wallet.ServiceMetadata|undefined} value
 * @return {!proto.wallet.Transaction} returns this
*/
proto.wallet.Transaction.prototype.setServiceMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wallet.Transaction} returns this
 */
proto.wallet.Transaction.prototype.clearServiceMetadata = function() {
  return this.setServiceMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wallet.Transaction.prototype.hasServiceMetadata = function() {
  return jspb.Message.getField(this, 18) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wallet.ServiceMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.wallet.ServiceMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wallet.ServiceMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.ServiceMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    referenceCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    agentGroupCommissionFee: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    makeupFee: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    discountAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wallet.ServiceMetadata}
 */
proto.wallet.ServiceMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wallet.ServiceMetadata;
  return proto.wallet.ServiceMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wallet.ServiceMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wallet.ServiceMetadata}
 */
proto.wallet.ServiceMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferenceCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAgentGroupCommissionFee(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMakeupFee(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDiscountAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wallet.ServiceMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wallet.ServiceMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wallet.ServiceMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.ServiceMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReferenceCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAgentGroupCommissionFee();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getMakeupFee();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getDiscountAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional string reference_code = 1;
 * @return {string}
 */
proto.wallet.ServiceMetadata.prototype.getReferenceCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.ServiceMetadata} returns this
 */
proto.wallet.ServiceMetadata.prototype.setReferenceCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double agent_group_commission_fee = 2;
 * @return {number}
 */
proto.wallet.ServiceMetadata.prototype.getAgentGroupCommissionFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wallet.ServiceMetadata} returns this
 */
proto.wallet.ServiceMetadata.prototype.setAgentGroupCommissionFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double makeup_fee = 3;
 * @return {number}
 */
proto.wallet.ServiceMetadata.prototype.getMakeupFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wallet.ServiceMetadata} returns this
 */
proto.wallet.ServiceMetadata.prototype.setMakeupFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double amount = 4;
 * @return {number}
 */
proto.wallet.ServiceMetadata.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wallet.ServiceMetadata} returns this
 */
proto.wallet.ServiceMetadata.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double discount_amount = 5;
 * @return {number}
 */
proto.wallet.ServiceMetadata.prototype.getDiscountAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wallet.ServiceMetadata} returns this
 */
proto.wallet.ServiceMetadata.prototype.setDiscountAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wallet.TopUpPaymentActionData.prototype.toObject = function(opt_includeInstance) {
  return proto.wallet.TopUpPaymentActionData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wallet.TopUpPaymentActionData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.TopUpPaymentActionData.toObject = function(includeInstance, msg) {
  var f, obj = {
    webviewUrl: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wallet.TopUpPaymentActionData}
 */
proto.wallet.TopUpPaymentActionData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wallet.TopUpPaymentActionData;
  return proto.wallet.TopUpPaymentActionData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wallet.TopUpPaymentActionData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wallet.TopUpPaymentActionData}
 */
proto.wallet.TopUpPaymentActionData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebviewUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wallet.TopUpPaymentActionData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wallet.TopUpPaymentActionData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wallet.TopUpPaymentActionData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.TopUpPaymentActionData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWebviewUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string webview_url = 1;
 * @return {string}
 */
proto.wallet.TopUpPaymentActionData.prototype.getWebviewUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.TopUpPaymentActionData} returns this
 */
proto.wallet.TopUpPaymentActionData.prototype.setWebviewUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wallet.TopUpPaymentInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.wallet.TopUpPaymentInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wallet.TopUpPaymentInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.TopUpPaymentInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transactionStatus: jspb.Message.getFieldWithDefault(msg, 2, 0),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    fee: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    totalPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    currencyCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    paymentMethod: (f = msg.getPaymentMethod()) && proto.wallet.TopUpPaymentMethod.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wallet.TopUpPaymentInfo}
 */
proto.wallet.TopUpPaymentInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wallet.TopUpPaymentInfo;
  return proto.wallet.TopUpPaymentInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wallet.TopUpPaymentInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wallet.TopUpPaymentInfo}
 */
proto.wallet.TopUpPaymentInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionId(value);
      break;
    case 2:
      var value = /** @type {!proto.base.TransactionStatus} */ (reader.readEnum());
      msg.setTransactionStatus(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFee(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalPrice(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    case 7:
      var value = new proto.wallet.TopUpPaymentMethod;
      reader.readMessage(value,proto.wallet.TopUpPaymentMethod.deserializeBinaryFromReader);
      msg.setPaymentMethod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wallet.TopUpPaymentInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wallet.TopUpPaymentInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wallet.TopUpPaymentInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.TopUpPaymentInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransactionStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getFee();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getTotalPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPaymentMethod();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.wallet.TopUpPaymentMethod.serializeBinaryToWriter
    );
  }
};


/**
 * optional string transaction_id = 1;
 * @return {string}
 */
proto.wallet.TopUpPaymentInfo.prototype.getTransactionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.TopUpPaymentInfo} returns this
 */
proto.wallet.TopUpPaymentInfo.prototype.setTransactionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional base.TransactionStatus transaction_status = 2;
 * @return {!proto.base.TransactionStatus}
 */
proto.wallet.TopUpPaymentInfo.prototype.getTransactionStatus = function() {
  return /** @type {!proto.base.TransactionStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.base.TransactionStatus} value
 * @return {!proto.wallet.TopUpPaymentInfo} returns this
 */
proto.wallet.TopUpPaymentInfo.prototype.setTransactionStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional double amount = 3;
 * @return {number}
 */
proto.wallet.TopUpPaymentInfo.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wallet.TopUpPaymentInfo} returns this
 */
proto.wallet.TopUpPaymentInfo.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double fee = 4;
 * @return {number}
 */
proto.wallet.TopUpPaymentInfo.prototype.getFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wallet.TopUpPaymentInfo} returns this
 */
proto.wallet.TopUpPaymentInfo.prototype.setFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double total_price = 5;
 * @return {number}
 */
proto.wallet.TopUpPaymentInfo.prototype.getTotalPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wallet.TopUpPaymentInfo} returns this
 */
proto.wallet.TopUpPaymentInfo.prototype.setTotalPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string currency_code = 6;
 * @return {string}
 */
proto.wallet.TopUpPaymentInfo.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.TopUpPaymentInfo} returns this
 */
proto.wallet.TopUpPaymentInfo.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional TopUpPaymentMethod payment_method = 7;
 * @return {?proto.wallet.TopUpPaymentMethod}
 */
proto.wallet.TopUpPaymentInfo.prototype.getPaymentMethod = function() {
  return /** @type{?proto.wallet.TopUpPaymentMethod} */ (
    jspb.Message.getWrapperField(this, proto.wallet.TopUpPaymentMethod, 7));
};


/**
 * @param {?proto.wallet.TopUpPaymentMethod|undefined} value
 * @return {!proto.wallet.TopUpPaymentInfo} returns this
*/
proto.wallet.TopUpPaymentInfo.prototype.setPaymentMethod = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wallet.TopUpPaymentInfo} returns this
 */
proto.wallet.TopUpPaymentInfo.prototype.clearPaymentMethod = function() {
  return this.setPaymentMethod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wallet.TopUpPaymentInfo.prototype.hasPaymentMethod = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wallet.TopUpPaymentMethod.prototype.toObject = function(opt_includeInstance) {
  return proto.wallet.TopUpPaymentMethod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wallet.TopUpPaymentMethod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.TopUpPaymentMethod.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    code: jspb.Message.getFieldWithDefault(msg, 3, ""),
    method: jspb.Message.getFieldWithDefault(msg, 4, 0),
    fee: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    additionalFee: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    displayFee: jspb.Message.getFieldWithDefault(msg, 7, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wallet.TopUpPaymentMethod}
 */
proto.wallet.TopUpPaymentMethod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wallet.TopUpPaymentMethod;
  return proto.wallet.TopUpPaymentMethod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wallet.TopUpPaymentMethod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wallet.TopUpPaymentMethod}
 */
proto.wallet.TopUpPaymentMethod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMethod(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFee(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAdditionalFee(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayFee(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wallet.TopUpPaymentMethod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wallet.TopUpPaymentMethod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wallet.TopUpPaymentMethod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wallet.TopUpPaymentMethod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMethod();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getFee();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getAdditionalFee();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getDisplayFee();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wallet.TopUpPaymentMethod.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.TopUpPaymentMethod} returns this
 */
proto.wallet.TopUpPaymentMethod.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wallet.TopUpPaymentMethod.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.TopUpPaymentMethod} returns this
 */
proto.wallet.TopUpPaymentMethod.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.wallet.TopUpPaymentMethod.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.TopUpPaymentMethod} returns this
 */
proto.wallet.TopUpPaymentMethod.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 method = 4;
 * @return {number}
 */
proto.wallet.TopUpPaymentMethod.prototype.getMethod = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.wallet.TopUpPaymentMethod} returns this
 */
proto.wallet.TopUpPaymentMethod.prototype.setMethod = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double fee = 5;
 * @return {number}
 */
proto.wallet.TopUpPaymentMethod.prototype.getFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wallet.TopUpPaymentMethod} returns this
 */
proto.wallet.TopUpPaymentMethod.prototype.setFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double additional_fee = 6;
 * @return {number}
 */
proto.wallet.TopUpPaymentMethod.prototype.getAdditionalFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wallet.TopUpPaymentMethod} returns this
 */
proto.wallet.TopUpPaymentMethod.prototype.setAdditionalFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional string display_fee = 7;
 * @return {string}
 */
proto.wallet.TopUpPaymentMethod.prototype.getDisplayFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.TopUpPaymentMethod} returns this
 */
proto.wallet.TopUpPaymentMethod.prototype.setDisplayFee = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string icon = 8;
 * @return {string}
 */
proto.wallet.TopUpPaymentMethod.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wallet.TopUpPaymentMethod} returns this
 */
proto.wallet.TopUpPaymentMethod.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


goog.object.extend(exports, proto.wallet);
