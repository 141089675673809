import {
  BookingIcon,
  Button,
  ButtonLoading,
  FileExcelLineIcon,
  Form,
  FormInput,
  FormInputDateFromTo,
  FormSelect,
  InfoIcon,
  SearchLineIcon,
  TFromToDate,
  TopUpIcon,
} from '@common-ui';
import { addDay, subDay } from '@core/utils';
import {
  IGetTopupRequestsReq,
  topUpServiceApi,
} from '@tixlabs/grpc-client/web';

import { useAppMutation, useTime } from '@payment-portal/hooks/internals';
import { useUserStore } from '@payment-portal/hooks/stores';
import {
  EPartnershipRole,
  ONE_DAY_TIMESTAMP,
  SELECT_TOPUP_REQUEST_STATUS_OPTIONS,
  SELECT_TOPUP_REQUEST_STATUS_OPTIONS_FOR_APPRAISAL_MANAGER,
  checkRoleUser,
} from '@payment-portal/utils';
import { exportTopUpRequests } from '@tixlabs/axios-client';
import {
  ETopUpRequestStatus,
  IPaginationRes,
  ITopUpRequestInfo,
} from '@tixlabs/grpc-client';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import TableContent from './TableContent';

type Props = {
  //
};

const DEFAULT_PAGE_LIMIT = 10;

const MAX_FILTER_DAY = 30;

export const ListTopUpCtn = (props: Props) => {
  const { userData } = useUserStore();
  const navigate = useNavigate();
  const DEFALT_TOPUP_REQUESTS_FILTER: Pick<IGetTopupRequestsReq, 'filter'> & {
    filterDate: TFromToDate;
  } = {
    filter: {
      agentCode: '',
      from: new Date().setHours(0, 0, 0, 0) - ONE_DAY_TIMESTAMP * 7,
      status: checkRoleUser(EPartnershipRole.ACCOUNTANT, userData)
        ? ETopUpRequestStatus.TOPUPREQUESTINVALID
        : checkRoleUser(EPartnershipRole.APPRAISAL, userData)
        ? ETopUpRequestStatus.WAITINGAPPROVAL
        : ETopUpRequestStatus.TOPUPREQUESTINVALID,
      to: new Date().setHours(23, 59, 59, 999),
    },
    filterDate: {
      startDate: new Date(
        new Date(Date.now() - ONE_DAY_TIMESTAMP * 7).setHours(0, 0, 0, 0)
      ),
      endDate: new Date(new Date().setHours(23, 59, 59, 999)),
    },
  };

  const methods = useForm<
    Pick<IGetTopupRequestsReq, 'filter'> & {
      filterDate: TFromToDate;
    }
  >({
    defaultValues: DEFALT_TOPUP_REQUESTS_FILTER,
  });

  const [paginationRes, setPaginationRes] = useState<IPaginationRes>({
    pageCurrent: 1,
    pageLimit: DEFAULT_PAGE_LIMIT,
    totalPage: 1,
    totalRecord: 0,
  });
  const [filterReq, setFilterReq] = useState<
    Pick<IGetTopupRequestsReq, 'filter'>
  >({
    filter: DEFALT_TOPUP_REQUESTS_FILTER.filter,
  });
  const { formatDateCustom } = useTime();
  const [amountComingExpireTopUp, setAmountComingExpireTopUp] = useState(0);
  const [listTopUp, setListTopUp] = useState<ITopUpRequestInfo[]>([]);
  const {
    mutateAsync: getTopupRequests,
    isLoading: isLoadingGetTopUpRequests,
  } = useAppMutation({
    mutationKey: ['topUpServiceApi', 'getTopupRequests'],
    mutationFn: topUpServiceApi.getTopupRequests,
    onSuccess: ({ isSuccess, itemsList, errorCode, pagination }) => {
      if (isSuccess) {
        pagination && setPaginationRes(pagination);
        // filter order has itinerariesList empty
        setListTopUp(itemsList);
      }
    },
  });

  const { mutateAsync: getLongCreatedTopupRequestAmount } = useAppMutation({
    mutationKey: ['topUpServiceApi', 'getLongCreatedTopupRequestAmount'],
    mutationFn: topUpServiceApi.getLongCreatedTopupRequestAmount,
    onSuccess: ({ isSuccess, data, errorCode }) => {
      if (isSuccess && data) {
        setAmountComingExpireTopUp(data.amount);
      }
    },
  });

  const handleSubmit = async (
    data: Pick<IGetTopupRequestsReq, 'filter'> & {
      filterDate: TFromToDate;
    }
  ) => {
    if (data.filter) {
      setFilterReq({
        filter: {
          ...data.filter,
          from: data.filterDate.startDate
            ? new Date(data.filterDate.startDate).getTime()
            : data.filter.from,
          to: data.filterDate.endDate
            ? new Date(data.filterDate.endDate).getTime()
            : data.filter.to,
        },
      });
      await getTopupRequests({
        filter: {
          ...data.filter,
          from: data.filterDate.startDate
            ? new Date(data.filterDate.startDate).getTime()
            : data.filter.from,
          to: data.filterDate.endDate
            ? new Date(data.filterDate.endDate).getTime()
            : data.filter.to,
        },
        pagination: {
          pageLimit: DEFAULT_PAGE_LIMIT,
          pageNumber: 1,
        },
        orderByList: [],
      });
    }
  };

  const handlePagination = async (page: number) => {
    await getTopupRequests({
      filter: filterReq.filter,
      pagination: {
        pageLimit: DEFAULT_PAGE_LIMIT,
        pageNumber: page,
      },
      orderByList: [],
    });
  };

  const handleDeleteTopUpSuccess = async () => {
    await getListTopUp();
  };

  const handleExportTopUpRequests = async () => {
    if (filterReq.filter) {
      const data = await exportTopUpRequests({
        agent_code: filterReq.filter.agentCode,
        from: filterReq.filter.from,
        to: filterReq.filter.to,
        status: filterReq.filter.status,
      });
      if (data.error) {
        toast.error('Lỗi xảy ra khi tải về danh sách nạp tiền.');
        return;
      }
      const blob = new Blob([data.data], { type: 'text/csv' });

      // Create a link to the Blob
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `Danhsachyeucaunaptien_${formatDateCustom('DDMMYYYY')(
        filterReq.filter.from
      )}_${formatDateCustom('DDMMYYYY')(filterReq.filter.to)}.csv`;
      document.body.appendChild(link);

      // Trigger a click on the link to start the download
      link.click();
      document.body.removeChild(link);
    }
  };

  const getListTopUp = useCallback(async () => {
    await getTopupRequests({
      filter: DEFALT_TOPUP_REQUESTS_FILTER.filter,
      pagination: {
        pageLimit: DEFAULT_PAGE_LIMIT,
        pageNumber: 1,
      },
      orderByList: [],
    });
  }, []);

  const getAmountTopUpComingExpire = async () => {
    await getLongCreatedTopupRequestAmount({});
  };

  useEffect(() => {
    getListTopUp();
    !checkRoleUser(EPartnershipRole.ACCOUNTANT, userData) &&
      getAmountTopUpComingExpire();
  }, []);

  const { startDate: fromDate, endDate: toDate } = methods.watch('filterDate');

  return (
    <div className='flex flex-col space-y-5'>
      <div className='flex space-x-2.5 items-center'>
        <BookingIcon className='w-5 h-5 shrink-0' />
        <span className='font-semibold text-lg'>Quản lý yêu cầu nạp tiền</span>
      </div>
      {amountComingExpireTopUp ? (
        <div className='flex items-center self-start gap-2 px-3 py-2 rounded-md bg-secondary-3 '>
          <InfoIcon className='w-5 h-5' />
          <span>
            Bạn có {amountComingExpireTopUp} biểu mẫu sắp hết hạn cần xét duyệt
          </span>
        </div>
      ) : null}
      <div className='w-full bg-white border rounded p-3.5'>
        <Form
          methods={methods}
          onSubmit={handleSubmit}
          className='flex justify-between pb-4 border-b mb-4'>
          <div className='flex space-x-2.5'>
            <FormInputDateFromTo
              inputProps={{
                placeholderStart: 'Từ ngày',
                placeholderEnd: 'Đến ngày',
                showFilter: true,
                inputSize: 'sm',
                minStartDate: toDate
                  ? subDay(toDate, MAX_FILTER_DAY)
                  : undefined,
                maxStartDate: new Date(),
                maxEndDate: fromDate
                  ? addDay(fromDate, MAX_FILTER_DAY) < new Date()
                    ? addDay(fromDate, MAX_FILTER_DAY)
                    : new Date()
                  : new Date(),
                isFullTime: true,
              }}
              label='Thời gian'
              name='filterDate'
            />
            <FormInput
              name='filter.agentCode'
              label='Mã đại lý'
              placeholder='Nhập mã đại lý'
            />
            {checkRoleUser(EPartnershipRole.APPRAISAL_MANAGER, userData) && (
              <FormSelect
                name='filter.status'
                selectOptions={
                  SELECT_TOPUP_REQUEST_STATUS_OPTIONS_FOR_APPRAISAL_MANAGER
                }
                label={'Trạng thái'}
                inputProps={{
                  isOnlyValue: true,
                }}
              />
            )}
            {checkRoleUser(EPartnershipRole.ACCOUNTANT, userData) && (
              <FormSelect
                name='filter.status'
                selectOptions={SELECT_TOPUP_REQUEST_STATUS_OPTIONS}
                label={'Trạng thái'}
                inputProps={{
                  isOnlyValue: true,
                }}
              />
            )}
            <div className='flex flex-col justify-end'>
              <div className='flex items-center gap-2.5'>
                <ButtonLoading
                  loading={isLoadingGetTopUpRequests}
                  isShowChildWhenLoading
                  type='submit'
                  theme='secondary'
                  size='sm'
                  prefixIcon={<SearchLineIcon />}
                  className='font-semibold !px-5'>
                  Tìm kiếm
                </ButtonLoading>
                {checkRoleUser(EPartnershipRole.ACCOUNTANT, userData) ? (
                  <Button
                    theme='green'
                    size='sm'
                    prefixIcon={<TopUpIcon className='w-5 h-5' />}
                    className='font-semibold !px-5'
                    onClick={() => {
                      navigate('/dashboard/create-top-up-request');
                    }}>
                    Tạo biểu mẫu
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
          <div className='flex space-x-2.5'>
            <div className='flex flex-col justify-end'>
              <Button
                size='sm'
                theme='green'
                onClick={handleExportTopUpRequests}
                prefixIcon={<FileExcelLineIcon />}
                className='!px-5'>
                <span className='whitespace-nowrap'>Tải về</span>
              </Button>
            </div>
          </div>
        </Form>
        <TableContent
          listTopUp={listTopUp}
          isLoading={isLoadingGetTopUpRequests}
          pagination={paginationRes}
          onPageChange={handlePagination}
          handleDeleteTopUpSuccess={handleDeleteTopUpSuccess}
        />
      </div>
    </div>
  );
};

export default ListTopUpCtn;
