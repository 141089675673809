// source: skyhub/base.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var base_enum_pb = require('../base/enum_pb.js');
goog.object.extend(proto, base_enum_pb);
goog.exportSymbol('proto.skyhub.Agent', null, global);
goog.exportSymbol('proto.skyhub.HotelBookingStatus', null, global);
goog.exportSymbol('proto.skyhub.NotificationStatus', null, global);
goog.exportSymbol('proto.skyhub.TopUpRequestInfo', null, global);
goog.exportSymbol('proto.skyhub.TopUpRequestInfo.UserInfo', null, global);
goog.exportSymbol('proto.skyhub.TopUpRequestStatus', null, global);
goog.exportSymbol('proto.skyhub.Transaction', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.TopUpRequestInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skyhub.TopUpRequestInfo.repeatedFields_, null);
};
goog.inherits(proto.skyhub.TopUpRequestInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.TopUpRequestInfo.displayName = 'proto.skyhub.TopUpRequestInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.TopUpRequestInfo.UserInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skyhub.TopUpRequestInfo.UserInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.TopUpRequestInfo.UserInfo.displayName = 'proto.skyhub.TopUpRequestInfo.UserInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.Agent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skyhub.Agent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.Agent.displayName = 'proto.skyhub.Agent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.Transaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skyhub.Transaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.Transaction.displayName = 'proto.skyhub.Transaction';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skyhub.TopUpRequestInfo.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.TopUpRequestInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.TopUpRequestInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.TopUpRequestInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.TopUpRequestInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    agentInfo: (f = msg.getAgentInfo()) && proto.skyhub.Agent.toObject(includeInstance, f),
    topupAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 5, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    expiredAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
    note: jspb.Message.getFieldWithDefault(msg, 8, ""),
    createdBy: jspb.Message.getFieldWithDefault(msg, 9, ""),
    attachmentsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    createdUser: (f = msg.getCreatedUser()) && proto.skyhub.TopUpRequestInfo.UserInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.TopUpRequestInfo}
 */
proto.skyhub.TopUpRequestInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.TopUpRequestInfo;
  return proto.skyhub.TopUpRequestInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.TopUpRequestInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.TopUpRequestInfo}
 */
proto.skyhub.TopUpRequestInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.skyhub.Agent;
      reader.readMessage(value,proto.skyhub.Agent.deserializeBinaryFromReader);
      msg.setAgentInfo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTopupAmount(value);
      break;
    case 4:
      var value = /** @type {!proto.skyhub.TopUpRequestStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiredAt(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addAttachments(value);
      break;
    case 11:
      var value = new proto.skyhub.TopUpRequestInfo.UserInfo;
      reader.readMessage(value,proto.skyhub.TopUpRequestInfo.UserInfo.deserializeBinaryFromReader);
      msg.setCreatedUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.TopUpRequestInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.TopUpRequestInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.TopUpRequestInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.TopUpRequestInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAgentInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.skyhub.Agent.serializeBinaryToWriter
    );
  }
  f = message.getTopupAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getExpiredAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAttachmentsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getCreatedUser();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.skyhub.TopUpRequestInfo.UserInfo.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.TopUpRequestInfo.UserInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.TopUpRequestInfo.UserInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.TopUpRequestInfo.UserInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.TopUpRequestInfo.UserInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    userName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    phoneCode: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.TopUpRequestInfo.UserInfo}
 */
proto.skyhub.TopUpRequestInfo.UserInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.TopUpRequestInfo.UserInfo;
  return proto.skyhub.TopUpRequestInfo.UserInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.TopUpRequestInfo.UserInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.TopUpRequestInfo.UserInfo}
 */
proto.skyhub.TopUpRequestInfo.UserInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.TopUpRequestInfo.UserInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.TopUpRequestInfo.UserInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.TopUpRequestInfo.UserInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.TopUpRequestInfo.UserInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPhoneCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string user_name = 1;
 * @return {string}
 */
proto.skyhub.TopUpRequestInfo.UserInfo.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.TopUpRequestInfo.UserInfo} returns this
 */
proto.skyhub.TopUpRequestInfo.UserInfo.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.skyhub.TopUpRequestInfo.UserInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.TopUpRequestInfo.UserInfo} returns this
 */
proto.skyhub.TopUpRequestInfo.UserInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string phone = 3;
 * @return {string}
 */
proto.skyhub.TopUpRequestInfo.UserInfo.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.TopUpRequestInfo.UserInfo} returns this
 */
proto.skyhub.TopUpRequestInfo.UserInfo.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.skyhub.TopUpRequestInfo.UserInfo.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.TopUpRequestInfo.UserInfo} returns this
 */
proto.skyhub.TopUpRequestInfo.UserInfo.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string phone_number = 5;
 * @return {string}
 */
proto.skyhub.TopUpRequestInfo.UserInfo.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.TopUpRequestInfo.UserInfo} returns this
 */
proto.skyhub.TopUpRequestInfo.UserInfo.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string phone_code = 6;
 * @return {string}
 */
proto.skyhub.TopUpRequestInfo.UserInfo.prototype.getPhoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.TopUpRequestInfo.UserInfo} returns this
 */
proto.skyhub.TopUpRequestInfo.UserInfo.prototype.setPhoneCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.skyhub.TopUpRequestInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.TopUpRequestInfo} returns this
 */
proto.skyhub.TopUpRequestInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Agent agent_info = 2;
 * @return {?proto.skyhub.Agent}
 */
proto.skyhub.TopUpRequestInfo.prototype.getAgentInfo = function() {
  return /** @type{?proto.skyhub.Agent} */ (
    jspb.Message.getWrapperField(this, proto.skyhub.Agent, 2));
};


/**
 * @param {?proto.skyhub.Agent|undefined} value
 * @return {!proto.skyhub.TopUpRequestInfo} returns this
*/
proto.skyhub.TopUpRequestInfo.prototype.setAgentInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skyhub.TopUpRequestInfo} returns this
 */
proto.skyhub.TopUpRequestInfo.prototype.clearAgentInfo = function() {
  return this.setAgentInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skyhub.TopUpRequestInfo.prototype.hasAgentInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double topup_amount = 3;
 * @return {number}
 */
proto.skyhub.TopUpRequestInfo.prototype.getTopupAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.TopUpRequestInfo} returns this
 */
proto.skyhub.TopUpRequestInfo.prototype.setTopupAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional TopUpRequestStatus status = 4;
 * @return {!proto.skyhub.TopUpRequestStatus}
 */
proto.skyhub.TopUpRequestInfo.prototype.getStatus = function() {
  return /** @type {!proto.skyhub.TopUpRequestStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.skyhub.TopUpRequestStatus} value
 * @return {!proto.skyhub.TopUpRequestInfo} returns this
 */
proto.skyhub.TopUpRequestInfo.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int64 created_at = 5;
 * @return {number}
 */
proto.skyhub.TopUpRequestInfo.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.TopUpRequestInfo} returns this
 */
proto.skyhub.TopUpRequestInfo.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 updated_at = 6;
 * @return {number}
 */
proto.skyhub.TopUpRequestInfo.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.TopUpRequestInfo} returns this
 */
proto.skyhub.TopUpRequestInfo.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 expired_at = 7;
 * @return {number}
 */
proto.skyhub.TopUpRequestInfo.prototype.getExpiredAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.TopUpRequestInfo} returns this
 */
proto.skyhub.TopUpRequestInfo.prototype.setExpiredAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string note = 8;
 * @return {string}
 */
proto.skyhub.TopUpRequestInfo.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.TopUpRequestInfo} returns this
 */
proto.skyhub.TopUpRequestInfo.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string created_by = 9;
 * @return {string}
 */
proto.skyhub.TopUpRequestInfo.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.TopUpRequestInfo} returns this
 */
proto.skyhub.TopUpRequestInfo.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated string attachments = 10;
 * @return {!Array<string>}
 */
proto.skyhub.TopUpRequestInfo.prototype.getAttachmentsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.skyhub.TopUpRequestInfo} returns this
 */
proto.skyhub.TopUpRequestInfo.prototype.setAttachmentsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.skyhub.TopUpRequestInfo} returns this
 */
proto.skyhub.TopUpRequestInfo.prototype.addAttachments = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skyhub.TopUpRequestInfo} returns this
 */
proto.skyhub.TopUpRequestInfo.prototype.clearAttachmentsList = function() {
  return this.setAttachmentsList([]);
};


/**
 * optional UserInfo created_user = 11;
 * @return {?proto.skyhub.TopUpRequestInfo.UserInfo}
 */
proto.skyhub.TopUpRequestInfo.prototype.getCreatedUser = function() {
  return /** @type{?proto.skyhub.TopUpRequestInfo.UserInfo} */ (
    jspb.Message.getWrapperField(this, proto.skyhub.TopUpRequestInfo.UserInfo, 11));
};


/**
 * @param {?proto.skyhub.TopUpRequestInfo.UserInfo|undefined} value
 * @return {!proto.skyhub.TopUpRequestInfo} returns this
*/
proto.skyhub.TopUpRequestInfo.prototype.setCreatedUser = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skyhub.TopUpRequestInfo} returns this
 */
proto.skyhub.TopUpRequestInfo.prototype.clearCreatedUser = function() {
  return this.setCreatedUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skyhub.TopUpRequestInfo.prototype.hasCreatedUser = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.Agent.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.Agent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.Agent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.Agent.toObject = function(includeInstance, msg) {
  var f, obj = {
    agentCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    agentName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.Agent}
 */
proto.skyhub.Agent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.Agent;
  return proto.skyhub.Agent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.Agent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.Agent}
 */
proto.skyhub.Agent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.Agent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.Agent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.Agent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.Agent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAgentCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAgentName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string agent_code = 1;
 * @return {string}
 */
proto.skyhub.Agent.prototype.getAgentCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.Agent} returns this
 */
proto.skyhub.Agent.prototype.setAgentCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string agent_name = 2;
 * @return {string}
 */
proto.skyhub.Agent.prototype.getAgentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.Agent} returns this
 */
proto.skyhub.Agent.prototype.setAgentName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.Transaction.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.Transaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.Transaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.Transaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    agentInfo: (f = msg.getAgentInfo()) && proto.skyhub.Agent.toObject(includeInstance, f),
    provider: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 4, 0),
    bookingCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    type: jspb.Message.getFieldWithDefault(msg, 7, 0),
    method: jspb.Message.getFieldWithDefault(msg, 8, 0),
    note: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.Transaction}
 */
proto.skyhub.Transaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.Transaction;
  return proto.skyhub.Transaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.Transaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.Transaction}
 */
proto.skyhub.Transaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.skyhub.Agent;
      reader.readMessage(value,proto.skyhub.Agent.deserializeBinaryFromReader);
      msg.setAgentInfo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProvider(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 7:
      var value = /** @type {!proto.base.TransactionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 8:
      var value = /** @type {!proto.base.PaymentMethod} */ (reader.readEnum());
      msg.setMethod(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.Transaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.Transaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.Transaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.Transaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAgentInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.skyhub.Agent.serializeBinaryToWriter
    );
  }
  f = message.getProvider();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.skyhub.Transaction.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.Transaction} returns this
 */
proto.skyhub.Transaction.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Agent agent_info = 2;
 * @return {?proto.skyhub.Agent}
 */
proto.skyhub.Transaction.prototype.getAgentInfo = function() {
  return /** @type{?proto.skyhub.Agent} */ (
    jspb.Message.getWrapperField(this, proto.skyhub.Agent, 2));
};


/**
 * @param {?proto.skyhub.Agent|undefined} value
 * @return {!proto.skyhub.Transaction} returns this
*/
proto.skyhub.Transaction.prototype.setAgentInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skyhub.Transaction} returns this
 */
proto.skyhub.Transaction.prototype.clearAgentInfo = function() {
  return this.setAgentInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skyhub.Transaction.prototype.hasAgentInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string provider = 3;
 * @return {string}
 */
proto.skyhub.Transaction.prototype.getProvider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.Transaction} returns this
 */
proto.skyhub.Transaction.prototype.setProvider = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 created_at = 4;
 * @return {number}
 */
proto.skyhub.Transaction.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.Transaction} returns this
 */
proto.skyhub.Transaction.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string booking_code = 5;
 * @return {string}
 */
proto.skyhub.Transaction.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.Transaction} returns this
 */
proto.skyhub.Transaction.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional double amount = 6;
 * @return {number}
 */
proto.skyhub.Transaction.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.Transaction} returns this
 */
proto.skyhub.Transaction.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional base.TransactionType type = 7;
 * @return {!proto.base.TransactionType}
 */
proto.skyhub.Transaction.prototype.getType = function() {
  return /** @type {!proto.base.TransactionType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.base.TransactionType} value
 * @return {!proto.skyhub.Transaction} returns this
 */
proto.skyhub.Transaction.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional base.PaymentMethod method = 8;
 * @return {!proto.base.PaymentMethod}
 */
proto.skyhub.Transaction.prototype.getMethod = function() {
  return /** @type {!proto.base.PaymentMethod} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.base.PaymentMethod} value
 * @return {!proto.skyhub.Transaction} returns this
 */
proto.skyhub.Transaction.prototype.setMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional string note = 9;
 * @return {string}
 */
proto.skyhub.Transaction.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.Transaction} returns this
 */
proto.skyhub.Transaction.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * @enum {number}
 */
proto.skyhub.TopUpRequestStatus = {
  TOPUPREQUESTINVALID: 0,
  WAITINGAPPROVAL: 1,
  PROCESSING: 2,
  APPROVED: 3,
  REJECTED: 4,
  COMPLETED: 5,
  FAILED: 6
};

/**
 * @enum {number}
 */
proto.skyhub.NotificationStatus = {
  NOTIFICATIONSTATUSNONE: 0,
  NOTIFICATIONSTATUSSENT: 1,
  NOTIFICATIONSTATUSFAIL: 2
};

/**
 * @enum {number}
 */
proto.skyhub.HotelBookingStatus = {
  HOTELBOOKINGSTATUSNONE: 0,
  HOTELBOOKINGSTATUSSUCCESS: 1,
  HOTELBOOKINGSTATUSPENDING: 2,
  HOTELBOOKINGSTATUSFAILED: 3,
  HOTELBOOKINGSTATUSCANCELLED: 4,
  HOTELBOOKINGSTATUSDRAFT: 5,
  HOTELBOOKINGSTATUSCONFIRMED: 6,
  HOTELBOOKINGSTATUSREFUNDED: 7,
  HOTELBOOKINGSTATUSEXPIRED: 8
};

goog.object.extend(exports, proto.skyhub);
