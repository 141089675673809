import { default as getObjValue } from 'lodash.get';

import { useState } from 'react';

import Papa from 'papaparse';

interface ArrayColumnConfig {
  arrayRootKey: string;
  columns: TExportColumn[];
}
type ColumnValues = Record<string, any>;
type TExportColumn<
  T extends ColumnValues = ColumnValues,
  TKey extends keyof T = keyof ColumnValues,
  TValue extends T[TKey] = ColumnValues[keyof ColumnValues]
> = {
  key: TKey;
  name: string;
  preventExport?: boolean;
  formatValue?: (value: TValue, row: T) => string | number | null;
};

type TExportAgr<T extends object> = {
  rows?: T[];
  columns: TExportColumn<T>[];
  filename?: string;
  extendArray: boolean;
  arrayColumnConfig?: ArrayColumnConfig[];
};
export function useExportDataCustomize<T extends object>({
  rows,
  columns,
  filename = 'download',
  extendArray = false,
  arrayColumnConfig,
}: TExportAgr<T>) {
  const [isProcessingCustomize, setIsProcessingCustomize] = useState(false);
  const downLoadFilename = `${filename}.csv`;

  function downloadByUrl(url: string) {
    const tempLink = document.createElement('a');
    tempLink.href = url;
    tempLink.setAttribute('download', downLoadFilename);
    tempLink.click();
  }

  function convertData(rows: T[], columns: TExportColumn<T>[]) {
    if (!columns?.length) {
      return {
        fields: [],
        data: [],
      };
    }

    const fields: string[] = [];
    const filterColumns: Omit<TExportColumn<T>, 'name' | 'preventExport'>[] =
      [];

    columns.forEach(({ name, preventExport, ...rest }) => {
      if (!preventExport) {
        fields.push(name);
        filterColumns.push(rest);
      }
    });

    if (extendArray && arrayColumnConfig) {
      arrayColumnConfig?.forEach((element) => {
        const columnsDictionary = element.columns.reduce((acc, column) => {
          acc[column.key] = column.name;
          return acc;
        }, {});
        const { arrayRootKey } = element;
        let tempObjectItemMap: object[] = [];
        let maxLengthArray = 0;
        rows.map((row, index) => {
          const arrayColumn = row[arrayRootKey];
          if (Array.isArray(arrayColumn)) {
            if (Object.values(arrayColumn).length > maxLengthArray) {
              maxLengthArray = Object.values(arrayColumn).length;
              tempObjectItemMap = arrayColumn as object[];
            }
          }
        });

        tempObjectItemMap.map((item, itemIdex) => {
          const itemObj = item as object;

          return Object.keys(itemObj).map((itemKey) => {
            const name = columnsDictionary[itemKey];

            fields.push(`${name} ${itemIdex + 1}`);
            filterColumns.push({
              key: `${arrayRootKey}[${itemIdex}].${itemKey}`,
            });
          });
        });
      });
    }
    return {
      fields,
      data: rows.map((row, index) => {
        return filterColumns.map(({ key, formatValue }) => {
          return typeof formatValue === 'function'
            ? formatValue(getObjValue(row, key), row)
            : getObjValue(row, key);
        });
      }),
    };
  }

  async function handleExportCustomize(rowsData: T[]) {
    try {
      setIsProcessingCustomize(true);
      // const t0 = performance.now()
      const { data, fields } = convertData(rowsData ?? rows, columns);
      // const t1 = performance.now()

      // console.log(`convertData: ${t1 - t0} milliseconds.`)

      const csv = Papa.unparse({
        data,
        fields,
      });

      // const t2 = performance.now()

      // console.log(`Papa.unparse: ${t2 - t1} milliseconds.`)

      const csvData = new Blob([`\uFEFF${csv}`], { type: 'text/csv;charset=utf-8;' });

      let csvURL = '';
      if ('msSaveBlob' in navigator) {
        // csvURL = navigator.msSaveBlob(csvData, downLoadFilename);
      } else {
        csvURL = window.URL.createObjectURL(csvData);
      }

      // const t3 = performance.now()
      // console.log(`createObjectURL: ${t3 - t2} milliseconds.`)
      downloadByUrl(csvURL);
      // const t4 = performance.now()
      // console.log(`download: ${t4 - t3} milliseconds.`)
    } catch (error) {
      // const { message } = errorHandler(error);
      console.error('error', error);
    } finally {
      setIsProcessingCustomize(false);
    }
  }

  return {
    handleExportCustomize,
    isProcessingCustomize,
  };
}
export default useExportDataCustomize;
