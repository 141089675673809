import cn from 'classnames';
import {
  ETopUpRequestStatus,
  IPaginationRes,
  ITopUpRequestInfo,
} from '@tixlabs/grpc-client';

import {
  useAppMutation,
  useCurrency,
  useTime,
} from '@payment-portal/hooks/internals';
import { Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import {
  EPartnershipRole,
  checkRoleUser,
  topUpRequestStatusLabel,
} from '@payment-portal/utils';
import {
  Button,
  ButtonLoading,
  Edit2FillIcon,
  RecycleBinIcon,
} from '@common-ui';
import { useNavigate } from 'react-router-dom';
import { topUpServiceApi } from '@tixlabs/grpc-client/web';
import { useUserStore } from '@payment-portal/hooks/stores';
import toast from 'react-hot-toast';
import Modal from 'antd/es/modal/Modal';
import ModalContent from '@payment-portal/components/Modal/ModalContent';
import { EModalDeleteTopUp } from '@payment-portal/types/ui/modal';
import { useState } from 'react';
import { EModalMode } from '@payment-portal/types';

type Props = {
  listTopUp: ITopUpRequestInfo[];
  isLoading: boolean;
  pagination: IPaginationRes;
  onPageChange: (page: number) => void;
  handleDeleteTopUpSuccess: () => Promise<void>;
};

const TitleColumns = ({ title }: { title: string }) => {
  return <span className='text-neutral-7'>{title}</span>;
};

const TableContent = ({
  isLoading,
  listTopUp,
  pagination,
  onPageChange,
  handleDeleteTopUpSuccess,
}: Props) => {
  const { userData } = useUserStore();
  const [topUpInfo, setTopUpInfo] = useState<ITopUpRequestInfo>();
  const [stateModal, setStateModal] = useState(EModalDeleteTopUp.CONFIRM);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { formatDateTime } = useTime();
  const { formatPrice } = useCurrency();
  const navigate = useNavigate();
  const { mutateAsync: deleteTopupRequest, isLoading: isLoadingDelete } =
    useAppMutation({
      mutationKey: ['topUpServiceApi', 'deleteTopupRequest'],
      mutationFn: topUpServiceApi.deleteTopupRequest,
      onSuccess: async ({ errorCode, isSuccess }) => {
        if (isSuccess) {
          setStateModal(EModalDeleteTopUp.SUCCESS);
          await handleDeleteTopUpSuccess();
        } else {
          setStateModal(EModalDeleteTopUp.ERROR);
          toast.error('Lỗi khi xoá yêu cầu nạp tiền:' + errorCode);
        }
      },
      onError: (error) => {
        console.log('err', error);
        toast.error('Lỗi chưa xác định');
      },
    });

  const columns: ColumnProps<ITopUpRequestInfo>[] = [
    {
      title: () => {
        return <TitleColumns title='Mã đại lý' />;
      },
      dataIndex: 'agentInfo',
      key: 'agentInfoCode',
      render: (_, topUpInfo) => {
        return <span>{topUpInfo.agentInfo?.agentCode}</span>;
      },
    },
    {
      title: () => {
        return <TitleColumns title='Tên đại lý' />;
      },
      dataIndex: 'agentInfo',
      key: 'agentInfoName',
      render: (_, topUpInfo) => {
        return <span>{topUpInfo.agentInfo?.agentName}</span>;
      },
    },
    {
      title: () => {
        return <TitleColumns title='Số tiền' />;
      },
      dataIndex: 'topupAmount',
      key: 'topupAmount',
      render: (topupAmount) => {
        return <span>{formatPrice(topupAmount)} VNĐ</span>;
      },
    },
    {
      title: () => {
        return <TitleColumns title='Thời gian' />;
      },
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => {
        return <span>{formatDateTime(createdAt)}</span>;
      },
    },
    {
      title: () => {
        return <TitleColumns title='Trạng thái' />;
      },
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        return topUpRequestStatusLabel[status];
      },
      className: 'min-w-[150px]',
    },
    {
      title: () => {
        return <TitleColumns title='Ghi chú' />;
      },
      dataIndex: 'note',
      key: 'note',
      className: 'max-w-[300px] overflow-hidden',
    },
  ];

  (checkRoleUser(EPartnershipRole.APPRAISAL, userData) ||
    checkRoleUser(EPartnershipRole.APPRAISAL_MANAGER, userData)) &&
    columns.push({
      title: () => {
        return <TitleColumns title='Người tạo đơn' />;
      },
      dataIndex: 'createdUser',
      key: 'createdUser',
      render: (_, topUpInfo) => {
        return <span>{topUpInfo.createdUser?.name}</span>;
      },
    });

  checkRoleUser(EPartnershipRole.ACCOUNTANT, userData) &&
    columns.push({
      title: () => {
        return <TitleColumns title='Thao tác' />;
      },
      dataIndex: 'id',
      key: 'action',
      render: (id, topupInfoRecord) => {
        return (
          <div className='flex gap-2.5 justify-end'>
            <Button
              variant='ghost'
              theme='primary'
              className={cn('space-x-[5px] opacity-0 invisible', {
                '!opacity-100 !visible':
                  topupInfoRecord.status ===
                    ETopUpRequestStatus.WAITINGAPPROVAL ||
                  topupInfoRecord.status === ETopUpRequestStatus.REJECTED,
              })}
              prefixIcon={<Edit2FillIcon />}
              onClick={() => {
                // navigate(`/dashboard/`);
                navigate(`/dashboard/top-up-request-management/edit/${id}`);
              }}>
              Sửa thông tin
            </Button>
            <Button
              className={cn('space-x-[5px] opacity-0 invisible ', {
                '!opacity-100 !visible':
                  topupInfoRecord.status ===
                    ETopUpRequestStatus.WAITINGAPPROVAL ||
                  topupInfoRecord.status === ETopUpRequestStatus.REJECTED,
              })}
              prefixIcon={<RecycleBinIcon className='w-5 h-5' />}
              variant='ghost'
              theme='red'
              onClick={() => {
                if (
                  topupInfoRecord.status !==
                    ETopUpRequestStatus.WAITINGAPPROVAL &&
                  topupInfoRecord.status !== ETopUpRequestStatus.REJECTED
                ) {
                  toast.error(
                    'Bạn không thể xoá yêu cầu nạp tiền đang/đã được xử lý'
                  );
                } else {
                  setIsOpenModal(true);
                  setTopUpInfo(topupInfoRecord);
                }
              }}>
              Xoá
            </Button>
          </div>
        );
      },
      className: 'flex justify-end h-full',
    });

  const genarateModalFooter = (state: EModalDeleteTopUp) => {
    const content = {
      [EModalDeleteTopUp.SUCCESS]: (
        <div className='flex flex-col'>
          <Button
            onClick={() => {
              setIsOpenModal(false);
              setStateModal(EModalDeleteTopUp.CONFIRM);
            }}
            className='px-7 py-2.5'>
            Đóng
          </Button>
        </div>
      ),
      [EModalDeleteTopUp.CONFIRM]: (
        <div className='flex w-full space-x-2.5'>
          <Button
            theme='neutral'
            className='flex-1'
            onClick={() => {
              setIsOpenModal(false);
            }}
            disabled={isLoadingDelete}>
            Huỷ bỏ
          </Button>
          <ButtonLoading
            className='flex-1'
            onClick={async () => {
              await deleteTopupRequest({
                id: topUpInfo?.id || '',
              });
            }}
            loading={isLoadingDelete}>
            Tiếp tục xoá
          </ButtonLoading>
        </div>
      ),
      [EModalDeleteTopUp.ERROR]: (
        <div className='flex flex-col'>
          <Button
            onClick={() => {
              setIsOpenModal(false);
              setStateModal(EModalDeleteTopUp.CONFIRM);
            }}
            className='px-7 py-2.5'>
            Đóng
          </Button>
        </div>
      ),
    };
    return content[state];
  };

  const genarateModalContent = (state: EModalDeleteTopUp) => {
    const content = {
      [EModalDeleteTopUp.SUCCESS]: (
        <ModalContent
          className='w-full'
          mode={EModalMode.SUCCESS}
          content='Xoá yêu cầu nạp tiền thành công'
        />
      ),
      [EModalDeleteTopUp.ERROR]: (
        <ModalContent
          className='w-full'
          mode={EModalMode.ERROR}
          content='Xoá yêu cầu nạp tiền thất bại'
        />
      ),
      [EModalDeleteTopUp.CONFIRM]: (
        <ModalContent
          className='w-full'
          mode={EModalMode.CONFIRM}
          title='Xác nhận xoá yêu cầu nạp tiền'
          content='Bạn đang xoá yêu cầu nạp tiền, bạn thực sự muốn xoá?'
        />
      ),
    };
    return content[state];
  };

  return (
    <>
      <Modal
        open={isOpenModal}
        closeIcon={false}
        footer={genarateModalFooter(stateModal)}
        centered={true}>
        {genarateModalContent(stateModal)}
      </Modal>
      <Table
        dataSource={listTopUp.map((order, index) => {
          return {
            ...order,
            key: index,
          };
        })}
        columns={columns}
        size='small'
        rowClassName={(topUpInfo, index) => {
          return cn(`whitespace-nowrap`, {
            'cursor-pointer':
              !checkRoleUser(EPartnershipRole.ACCOUNTANT, userData) ||
              (checkRoleUser(EPartnershipRole.ACCOUNTANT, userData) &&
                topUpInfo.status === ETopUpRequestStatus.COMPLETED),
          });
          // return cn('font-semibold whitespace-nowrap', {
          //   'bg-[#F8F8FB]': index % 2 === 0,
          // });
        }}
        onRow={(topUpInfo, rowIndex) => {
          return {
            onClick: topUpInfo
              ? !checkRoleUser(EPartnershipRole.ACCOUNTANT, userData) ||
                (checkRoleUser(EPartnershipRole.ACCOUNTANT, userData) &&
                  topUpInfo.status === ETopUpRequestStatus.COMPLETED)
                ? () => {
                    navigate(
                      `/dashboard/top-up-request-management/detail/${topUpInfo.id}`
                    );
                  }
                : () => {
                    //
                  }
              : () => {
                  //
                },
          };
        }}
        scroll={{ x: 1000 }}
        pagination={{
          pageSize: pagination.pageLimit,
          current: pagination.pageCurrent,
          total: pagination.totalRecord,
          className: 'space-x-2.5',
          onChange: onPageChange,
        }}
        loading={isLoading}
      />
    </>
  );
};

export default TableContent;
