import { PaginationReq } from '@api/base/base_pb';
import { HotelProvider } from '@api/partner/base_pb';
import { PartnerServiceClient } from '@api/partner/web_partnership/partner_grpc_web_pb';
import {
  ChangeOfficePasswordReq,
  ChangeOfficePasswordRes,
  CreateOfficeReq,
  CreateOfficeRes,
  CreateOfficeV2Req,
  CreateOfficeV2Res,
  GetDomainsReq,
  GetDomainsRes,
  GetOfficeDetailReq,
  GetOfficeDetailRes,
  GetProvidersReq,
  GetProvidersRes,
  GetUnassignedOfficesReq,
  GetUnassignedOfficesRes,
  ListMyOfficesReq,
  ListMyOfficesRes,
  UpdateOfficeProvidersReq,
  UpdateOfficeProvidersRes,
  UpdateOfficeReq,
  UpdateOfficeRes,
  GetListCARes,
  GetListCAReq
} from '@api/partner/web_partnership/partner_pb';
import { BaseApi, BaseApiOptions } from '@core/grpc-client';

export class PartnerServiceApi extends BaseApi<PartnerServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new PartnerServiceClient(this.url, null, this.optsDev);
  }

  createOffice = ({
    email,
    enabledProvidersList,
    name,
    password,
    phoneCode,
    phoneNumber,
    shopName,
    // status,
    userName,
    logo,
    branch,
    hotelProvidersList,
    caCode,
    caName
  }: CreateOfficeReq.AsObject): Promise<CreateOfficeRes.AsObject> => {
    const req = new CreateOfficeReq();
    req.setEmail(email);
    req.setEnabledProvidersList(enabledProvidersList);
    if (hotelProvidersList) {
      const hotelProvidersReq = hotelProvidersList.map((hpObj) => {
        const hotelProvider = new HotelProvider();
        hotelProvider.setProvider(hpObj.provider);
        hotelProvider.setName(hpObj.name);
        return hotelProvider;
      });
      req.setHotelProvidersList(hotelProvidersReq);
    }
    req.setName(name);
    req.setPassword(password);
    req.setPhoneCode(phoneCode);
    req.setPhoneNumber(phoneNumber);
    req.setShopName(shopName);
    caCode && req.setCaCode(caCode);
    caName && req.setCaName(caName);
    // req.setStatus(status);
    req.setUserName(userName);
    if (branch) {
      req.setBranch(branch);
    }
    if (logo && logo !== '') {
      req.setLogo(logo);
    }
    return this.grpc<
      CreateOfficeReq,
      CreateOfficeRes,
      CreateOfficeRes.AsObject
    >(this.serviceClient.createOffice, req, {});
  };

  createOfficeV2 = ({
    email,
    enabledProvidersList,
    name,
    password,
    phoneCode,
    phoneNumber,
    shopName,
    // status,
    userName,
    saleCode,
    referralCode,
    logo,
    branch,
    hotelProvidersList,
    redirectUrl,
    loginUrl,
    caCode,
    caName
  }: CreateOfficeV2Req.AsObject): Promise<CreateOfficeV2Res.AsObject> => {
    const req = new CreateOfficeV2Req();
    req.setEmail(email);
    req.setEnabledProvidersList(enabledProvidersList);
    if (hotelProvidersList) {
      const hotelProvidersReq = hotelProvidersList.map((hpObj) => {
        const hotelProvider = new HotelProvider();
        hotelProvider.setProvider(hpObj.provider);
        hotelProvider.setName(hpObj.name);
        return hotelProvider;
      });
      req.setHotelProvidersList(hotelProvidersReq);
    }
    req.setName(name);
    req.setPassword(password);
    req.setPhoneCode(phoneCode);
    req.setPhoneNumber(phoneNumber);
    req.setShopName(shopName);
    caName && req.setCaName(caName);
    caCode && req.setCaCode(caCode);
    // req.setStatus(status);
    req.setUserName(userName);
    if (branch) {
      req.setBranch(branch);
    }
    if (saleCode) {
      req.setSaleCode(saleCode);
    }
    if (referralCode) {
      req.setReferralCode(referralCode);
    }
    if (logo && logo !== '') {
      req.setLogo(logo);
    }
    req.setLoginUrl(loginUrl);
    req.setRedirectUrl(redirectUrl);
    return this.grpc<
      CreateOfficeV2Req,
      CreateOfficeV2Res,
      CreateOfficeV2Res.AsObject
    >(this.serviceClient.createOfficeV2, req, {});
  };

  listMyOffices = ({
    filter,
    pagination,
  }: ListMyOfficesReq.AsObject): Promise<ListMyOfficesRes.AsObject> => {
    const req = new ListMyOfficesReq();

    if (filter) {
      const filterReq = new ListMyOfficesReq.Filter();
      filter?.provider && filterReq.setProvider(filter.provider);
      filter?.agentCode && filterReq.setAgentCode(filter.agentCode);
      filter?.status && filterReq.setStatus(filter.status);
      filter?.shopName && filterReq.setShopName(filter.shopName);
      filter?.referralCode && filterReq.setReferralCode(filter.referralCode);
      filter?.saleCode && filterReq.setSaleCode(filter.saleCode);
      req.setFilter(filterReq);
    }

    if (pagination) {
      const paginationObj = new PaginationReq();
      paginationObj.setPageLimit(pagination.pageLimit);
      paginationObj.setPageNumber(pagination.pageNumber);
      req.setPagination(paginationObj);
    }

    return this.grpc<
      ListMyOfficesReq,
      ListMyOfficesRes,
      ListMyOfficesRes.AsObject
    >(this.serviceClient.listMyOffices, req, {});
  };

  getOfficeDetail = ({
    id,
  }: GetOfficeDetailReq.AsObject): Promise<GetOfficeDetailRes.AsObject> => {
    const req = new GetOfficeDetailReq();
    req.setId(id);

    return this.grpc<
      GetOfficeDetailReq,
      GetOfficeDetailRes,
      GetOfficeDetailRes.AsObject
    >(this.serviceClient.getOfficeDetail, req);
  };

  updateOffice = ({
    email,
    id,
    name,
    phoneCode,
    phoneNumber,
    shopName,
    status,
    branch,
    referralCode,
    saleCode,
    logo,
    caCode,
    caName
  }: UpdateOfficeReq.AsObject): Promise<UpdateOfficeRes.AsObject> => {
    const req = new UpdateOfficeReq();

    req.setId(id);
    req.setEmail(email);
    req.setName(name);
    req.setPhoneCode(phoneCode);
    req.setPhoneNumber(phoneNumber);
    req.setShopName(shopName);
    req.setStatus(status);
    caCode && req.setCaCode(caCode);
    caName && req.setCaName(caName);
    if (branch) {
      req.setBranch(branch);
    }
    if (saleCode) {
      req.setSaleCode(saleCode);
    }
    if (referralCode) {
      req.setReferralCode(referralCode);
    }

    if (logo && logo !== '') {
      req.setLogo(logo);
    }
    return this.grpc<
      UpdateOfficeReq,
      UpdateOfficeRes,
      UpdateOfficeRes.AsObject
    >(this.serviceClient.updateOffice, req, {});
  };

  getDomains = (): Promise<GetDomainsRes.AsObject> => {
    const req = new GetDomainsReq();
    return this.grpc<GetDomainsReq, GetDomainsRes, GetDomainsRes.AsObject>(
      this.serviceClient.getDomains,
      req,
      {}
    );
  };

  updateOfficeProviders = ({
    id,
    enabledProvidersList,
  }: UpdateOfficeProvidersReq.AsObject): Promise<UpdateOfficeProvidersRes.AsObject> => {
    const req = new UpdateOfficeProvidersReq();

    req.setId(id);
    req.setEnabledProvidersList(enabledProvidersList);

    return this.grpc<
      UpdateOfficeProvidersReq,
      UpdateOfficeProvidersRes,
      UpdateOfficeProvidersRes.AsObject
    >(this.serviceClient.updateOfficeProviders, req, {});
  };

  getProviders = (): Promise<GetProvidersRes.AsObject> => {
    const req = new GetProvidersReq();
    return this.grpc<
      GetProvidersReq,
      GetProvidersRes,
      GetProvidersRes.AsObject
    >(this.serviceClient.getProviders, req, {});
  };

  getUnassignedOffices = (): Promise<GetUnassignedOfficesRes.AsObject> => {
    const req = new GetUnassignedOfficesReq();
    return this.grpc<
      GetUnassignedOfficesReq,
      GetUnassignedOfficesRes,
      GetUnassignedOfficesRes.AsObject
    >(this.serviceClient.getUnassignedOffices, req, {});
  };

  changeOfficePassword = ({
    newPassword,
    shopId,
    userId,
  }: ChangeOfficePasswordReq.AsObject): Promise<ChangeOfficePasswordRes.AsObject> => {
    const req = new ChangeOfficePasswordReq();
    req.setNewPassword(newPassword);
    req.setShopId(shopId);
    req.setUserId(userId);
    return this.grpc<
      ChangeOfficePasswordReq,
      ChangeOfficePasswordRes,
      ChangeOfficePasswordRes.AsObject
    >(this.serviceClient.changeOfficePassword, req, {});
  };

  getListCA = ({}: GetListCAReq.AsObject): Promise<GetListCARes.AsObject> => {
    const req = new GetDomainsReq();
      return this.grpc<GetDomainsReq, GetListCARes, GetListCARes.AsObject>(
        this.serviceClient?.getListCA,
        req,
        {}
      );
    };
}

export const partnerService = new PartnerServiceApi();
export default partnerService;
