import {
  Button,
  ButtonLoading,
  Form,
  FormGroupRadio,
  FormInput,
  FormInputPassword,
  User3FillIcon,
} from '@common-ui';
import {
  PartnerUserStatusListCreateOptions,
  ROLE_USER,
  patternValidateEmail,
  patternValidatePhoneNumber,
  patternValidateUsername,
} from '@sky-booking-config/utils';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useCreateAndUpdateIssuer from '../useCreateAndUpdateIssuer';

type Props = {
  //
};

export const CreateIssuerCtn = (props: Props) => {
  const navigate = useNavigate();

  const {
    createIssuerMethod,
    isLoadingCreateIssuer,
    handleSubmitCreateIssuer,
  } = useCreateAndUpdateIssuer();
  useEffect(() => {
    createIssuerMethod.setValue('rolesList', [ROLE_USER.AGENCY_ISSUER]);
  }, []);

  return (
    <div className='space-y-5'>
      <div className='flex space-x-2.5 items-center'>
        <User3FillIcon className='w-5 h-5 shrink-0' />
        <div className='font-semibold text-lg'>Thêm người dùng</div>
      </div>
      <div className='max-w-[1100px]'></div>
      <Form
        autoComplete='off'
        methods={createIssuerMethod}
        onSubmit={handleSubmitCreateIssuer}
        className='max-w-[1100px] flex flex-col gap-y-5'>
        <div className='flex flex-col gap-y-5 p-4 border rounded'>
          <div className='font-semibold text-lg'>
            Thông tin tài khoản
            <hr />
          </div>
          <div className='flex gap-x-5'>
            <div className='w-1/3'>
              <FormInput
                name='userName'
                label='Tên đăng nhập'
                placeholder='Nhập tên đăng nhập'
                rules={{
                  required: 'Nhập tên đăng nhập',
                  validate: {
                    maxLength: (value: string) =>
                      value.length <= 20 || 'Tối đa 20 kí tự',
                    minLength: (value: string) =>
                      value.length >= 8 || 'Tối thiểu 8 kí tự',
                  },
                  pattern: {
                    value: patternValidateUsername,
                    message: 'Tên đăng nhập không hợp lệ',
                  },
                  onChange(event) {
                    createIssuerMethod.clearErrors('userName');
                  },
                }}
                inputProps={{
                  autoComplete: 'new-username',
                }}
              />
            </div>
            <div className='w-1/3'>
              <FormInputPassword
                name='password'
                label='Mật khẩu'
                placeholder='Nhập mật khẩu'
                rules={{
                  required: 'Nhập mật khẩu',
                  validate: {
                    minLength: (value: string) =>
                      value.length >= 8 || 'Tối thiểu 8 kí tự',
                  },
                  onChange(event) {
                    createIssuerMethod.clearErrors('password');
                  },
                }}
                inputProps={{
                  autoComplete: 'new-password',
                }}
              />
            </div>
          </div>
          <div className='w-1/3'>
            <FormGroupRadio
              name='status'
              label='Trạng thái'
              className='flex'
              radioOptions={PartnerUserStatusListCreateOptions}
            />
          </div>
        </div>

        <div className='flex flex-col gap-y-2 p-4 border rounded'>
          <span className='text-lg font-semibold'>
            Thông tin liên hệ
            <hr />
          </span>
          <div className='flex flex-col gap-y-5'>
            <div className='flex gap-x-5'>
              <div className='w-1/3'>
                <FormInput
                  name='name'
                  label='Tên liên hệ'
                  placeholder='Nhập liên hệ'
                  rules={{
                    required: 'Nhập liên hệ',
                    onChange(event) {
                      createIssuerMethod.clearErrors('name');
                    },
                  }}
                />
              </div>
              <div className='w-1/3'>
                <FormInput
                  name='email'
                  label='Email'
                  placeholder='Nhập email'
                  rules={{
                    required: 'Nhập email',
                    validate: {
                      emailPattern: (value: string) => {
                        if (value.length > 0) {
                          return (
                            value.match(patternValidateEmail) ||
                            'Email không hợp lệ'
                          );
                        } else {
                          return true;
                        }
                      },
                    },
                    onChange(event) {
                      createIssuerMethod.clearErrors('email');
                    },
                  }}
                />
              </div>
              <div className='w-1/3'>
                <FormInput
                  name='phoneNumber'
                  label='Số điện thoại'
                  placeholder='Nhập điện thoại'
                  rules={{
                    required: 'Nhập diện thoại',
                    validate: {
                      phonePattern: (value: string) => {
                        if (value.length > 0) {
                          return (
                            value.match(patternValidatePhoneNumber) ||
                            'Số điện thoại không hợp lệ'
                          );
                        } else {
                          return true;
                        }
                      },
                    },
                    onChange(event) {
                      createIssuerMethod.clearErrors('phoneNumber');
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='flex justify-end gap-x-5'>
          <Button
            variant='solid'
            theme='red'
            className='w-[150px]'
            onClick={() => {
              // Go back router
              navigate(-1);
            }}>
            Hủy
          </Button>
          <ButtonLoading
            loading={isLoadingCreateIssuer}
            isShowChildWhenLoading
            className='w-[150px]'
            theme='green'
            type='submit'>
            Thêm người dùng
          </ButtonLoading>
        </div>
      </Form>
    </div>
  );
};

export default CreateIssuerCtn;
