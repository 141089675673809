import {
  EUserStatus,
  EUserStatusPartner,
  EOfficeStatusPartner,
} from '@tixlabs/grpc-client';
import { IUserInfo } from '@tixlabs/grpc-client/web-partnership';
import { ROLE_USER } from './general';

export const LabelUserStatus = {
  [EUserStatus.USER_STATUS_INVALID]: 'Tất cả',
  [EUserStatus.ACTIVE]: 'Hoạt động',
  [EUserStatus.INACTIVE]: 'Tạm khóa',
};

export const LabelUserStatusPartner = {
  [EUserStatusPartner.NONE]: 'Tất cả',
  [EUserStatusPartner.ACTIVE]: 'Hoạt động',
  [EUserStatusPartner.INACTIVE]: 'Tạm khóa',
};

export const LabelOfficeStatusPartner = {
  [EOfficeStatusPartner.OFFICESTATUSNONE]: 'Tất cả',
  [EOfficeStatusPartner.OFFICESTATUSACTIVE]: 'Hoạt động',
  [EOfficeStatusPartner.OFFICESTATUSINACTIVE]: 'Tạm khóa',
  [EOfficeStatusPartner.OFFICESTATUSPENDING]: 'Chờ kích hoạt',
};

export const LabelPartnerUserRole = {
  [ROLE_USER.AGENCY_ISSUER]: 'Issuer',
};

export const UserStatusListFilterOptions = [
  {
    label: LabelUserStatus[EUserStatus.USER_STATUS_INVALID],
    value: EUserStatus.USER_STATUS_INVALID,
  },
  { label: LabelUserStatus[EUserStatus.ACTIVE], value: EUserStatus.ACTIVE },
  { label: LabelUserStatus[EUserStatus.INACTIVE], value: EUserStatus.INACTIVE },
];

export const UserStatusListCreateOptions = [
  { label: LabelUserStatus[EUserStatus.ACTIVE], value: EUserStatus.ACTIVE },
  { label: LabelUserStatus[EUserStatus.INACTIVE], value: EUserStatus.INACTIVE },
];

export const LabelPartnerUserStatus = {
  [EUserStatusPartner.ACTIVE]: 'Hoạt động',
  [EUserStatusPartner.INACTIVE]: 'Tạm khóa',
};

export const PartnerUserStatusListCreateOptions = [
  {
    label: LabelOfficeStatusPartner[EOfficeStatusPartner.OFFICESTATUSACTIVE],
    value: EOfficeStatusPartner.OFFICESTATUSACTIVE,
  },
  {
    label: LabelOfficeStatusPartner[EOfficeStatusPartner.OFFICESTATUSINACTIVE],
    value: EOfficeStatusPartner.OFFICESTATUSINACTIVE,
  },
];
export const checkRoleUser = (role: ROLE_USER, userData: IUserInfo | null) => {
  if (!userData) return false;
  return role === userData.rolesList[0];
};

export const getLabelRoleUser = (userData: IUserInfo | null) => {
  if (!userData) return false;
  let roleUser = '';
  switch (userData.rolesList[0]) {
    case ROLE_USER.ACCOUNTANT:
      roleUser = 'Accountant';
      break;
    case ROLE_USER.APPRAISAL:
      roleUser = 'Appraisal';
      break;
    case ROLE_USER.APPRAISAL_MANAGER:
      roleUser = 'Appraisal Manager';
      break;
    case ROLE_USER.SUPER_ADMIN:
      roleUser = 'Super Admin';
      break;
    case ROLE_USER.OFFICE_MANAGER:
      roleUser = 'Office Manager';
      break;
    case ROLE_USER.SKY_ADMIN:
      roleUser = 'Sky Admin';
      break;

    default:
      break;
  }

  return roleUser;
};
