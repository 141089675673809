import { WalletServiceClient } from '@api/wallet/web/wallet_grpc_web_pb';
import {
  CalculateTopUpAmountReq,
  CalculateTopUpAmountV2Res,
  MyWalletReq,
  MyWalletRes,
  TopUpReq,
  TopUpRes,
} from '@api/wallet/web/wallet_pb';
import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';

export class WalletApi extends BaseApi<WalletServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new WalletServiceClient(this.url, null, this.optsDev);
  }

  calculateTopUpAmount = ({
    amount,
    paymentMethodCode,
    currencyCode,
  }: CalculateTopUpAmountReq.AsObject): Promise<CalculateTopUpAmountV2Res.AsObject> => {
    const req = new CalculateTopUpAmountReq();

    req.setAmount(amount);
    req.setPaymentMethodCode(paymentMethodCode);
    req.setCurrencyCode(currencyCode);

    return this.grpc<
      CalculateTopUpAmountReq,
      CalculateTopUpAmountV2Res,
      CalculateTopUpAmountV2Res.AsObject
    >(this.serviceClient?.calculateTopUpAmountV2, req, {
      useAnonymousToken: true,
    });
  };

  topUp = ({
    amount,
    paymentMethodCode,
    currencyCode,
    purchaseOrderId,
    redirectUrl,
  }: TopUpReq.AsObject): Promise<TopUpRes.AsObject> => {
    const req = new TopUpReq();
    req.setAmount(amount);
    req.setPaymentMethodCode(paymentMethodCode);
    req.setCurrencyCode(currencyCode);
    req.setPurchaseOrderId(purchaseOrderId);
    req.setRedirectUrl(redirectUrl);

    return this.grpc<TopUpReq, TopUpRes, TopUpRes.AsObject>(
      this.serviceClient.topUp,
      req,
      { useAnonymousToken: true }
    );
  };

  getWallet = (): Promise<MyWalletRes.AsObject> => {
    const req = new MyWalletReq();
    return this.grpc<MyWalletReq, MyWalletRes, MyWalletRes.AsObject>(
      this.serviceClient.myWallet,
      req,
      { useAnonymousToken: false }
    );
  };
}

export const walletApiService = new WalletApi();
export default walletApiService;
