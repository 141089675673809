// source: airplane/web/flight.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var base_base_pb = require('../../base/base_pb.js');
goog.object.extend(proto, base_base_pb);
var airplane_base_pb = require('../../airplane/base_pb.js');
goog.object.extend(proto, airplane_base_pb);
var airplane_flight_pb = require('../../airplane/flight_pb.js');
goog.object.extend(proto, airplane_flight_pb);
var airplane_domestic_flight_pb = require('../../airplane/domestic_flight_pb.js');
goog.object.extend(proto, airplane_domestic_flight_pb);
var airplane_hub_pb = require('../../airplane/hub_pb.js');
goog.object.extend(proto, airplane_hub_pb);
var airplane_seat_pb = require('../../airplane/seat_pb.js');
goog.object.extend(proto, airplane_seat_pb);
var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.airplane.web.AirportInfo', null, global);
goog.exportSymbol('proto.airplane.web.BaggageSelection', null, global);
goog.exportSymbol('proto.airplane.web.BookingContactInfo', null, global);
goog.exportSymbol('proto.airplane.web.BookingFlightDomesticReq', null, global);
goog.exportSymbol('proto.airplane.web.BookingFlightDomesticReq.ContactInfo', null, global);
goog.exportSymbol('proto.airplane.web.BookingFlightDomesticReq.FlightPair', null, global);
goog.exportSymbol('proto.airplane.web.BookingFlightDomesticReq.Passenger', null, global);
goog.exportSymbol('proto.airplane.web.BookingFlightDomesticRes', null, global);
goog.exportSymbol('proto.airplane.web.BookingFlightReq', null, global);
goog.exportSymbol('proto.airplane.web.BookingFlightRes', null, global);
goog.exportSymbol('proto.airplane.web.BookingFlightRes.Data', null, global);
goog.exportSymbol('proto.airplane.web.BookingFlightV2Req', null, global);
goog.exportSymbol('proto.airplane.web.BookingFlightV2Res', null, global);
goog.exportSymbol('proto.airplane.web.BookingPassenger', null, global);
goog.exportSymbol('proto.airplane.web.CalculationFlightFeeDomesticReq', null, global);
goog.exportSymbol('proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair', null, global);
goog.exportSymbol('proto.airplane.web.CalculationFlightFeeDomesticRes', null, global);
goog.exportSymbol('proto.airplane.web.CalculationFlightFeeReq', null, global);
goog.exportSymbol('proto.airplane.web.CalculationFlightFeeRes', null, global);
goog.exportSymbol('proto.airplane.web.CalculationFlightFeeV2Req', null, global);
goog.exportSymbol('proto.airplane.web.ContactInfo', null, global);
goog.exportSymbol('proto.airplane.web.ETicket', null, global);
goog.exportSymbol('proto.airplane.web.FareCheckDomesticReq', null, global);
goog.exportSymbol('proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo', null, global);
goog.exportSymbol('proto.airplane.web.FareCheckDomesticRes', null, global);
goog.exportSymbol('proto.airplane.web.FareCheckDomesticRes.Data', null, global);
goog.exportSymbol('proto.airplane.web.FareCheckReq', null, global);
goog.exportSymbol('proto.airplane.web.FareCheckRes', null, global);
goog.exportSymbol('proto.airplane.web.FareCheckRes.Data', null, global);
goog.exportSymbol('proto.airplane.web.FareCheckV2Req', null, global);
goog.exportSymbol('proto.airplane.web.FareData', null, global);
goog.exportSymbol('proto.airplane.web.FlightBookingInfo', null, global);
goog.exportSymbol('proto.airplane.web.FlightClass', null, global);
goog.exportSymbol('proto.airplane.web.FlightClass.FlightData', null, global);
goog.exportSymbol('proto.airplane.web.FlightInfo', null, global);
goog.exportSymbol('proto.airplane.web.FlightPair', null, global);
goog.exportSymbol('proto.airplane.web.FlightReq', null, global);
goog.exportSymbol('proto.airplane.web.GetAirportsReq', null, global);
goog.exportSymbol('proto.airplane.web.GetAirportsRes', null, global);
goog.exportSymbol('proto.airplane.web.GetBaggageOptionsReq', null, global);
goog.exportSymbol('proto.airplane.web.GetBaggageOptionsRes', null, global);
goog.exportSymbol('proto.airplane.web.GetSeatMapReq', null, global);
goog.exportSymbol('proto.airplane.web.GetSeatMapRes', null, global);
goog.exportSymbol('proto.airplane.web.IssueTicketReq', null, global);
goog.exportSymbol('proto.airplane.web.IssueTicketRes', null, global);
goog.exportSymbol('proto.airplane.web.ListBookingFlightReq', null, global);
goog.exportSymbol('proto.airplane.web.ListBookingFlightReq.Filter', null, global);
goog.exportSymbol('proto.airplane.web.ListBookingFlightRes', null, global);
goog.exportSymbol('proto.airplane.web.MarkBookingAsFailedAndCancelReq', null, global);
goog.exportSymbol('proto.airplane.web.MarkBookingAsFailedAndCancelRes', null, global);
goog.exportSymbol('proto.airplane.web.Passenger', null, global);
goog.exportSymbol('proto.airplane.web.PassengerQuantity', null, global);
goog.exportSymbol('proto.airplane.web.PaxFare', null, global);
goog.exportSymbol('proto.airplane.web.PromoApplyRes', null, global);
goog.exportSymbol('proto.airplane.web.PromoErrorRes', null, global);
goog.exportSymbol('proto.airplane.web.PromoLog', null, global);
goog.exportSymbol('proto.airplane.web.RetrieveBookingFlightReq', null, global);
goog.exportSymbol('proto.airplane.web.RetrieveBookingFlightRes', null, global);
goog.exportSymbol('proto.airplane.web.RetrieveBookingFlightRes.Data', null, global);
goog.exportSymbol('proto.airplane.web.Routing', null, global);
goog.exportSymbol('proto.airplane.web.SearchAirportsFilter', null, global);
goog.exportSymbol('proto.airplane.web.SearchAirportsReq', null, global);
goog.exportSymbol('proto.airplane.web.SearchAirportsRes', null, global);
goog.exportSymbol('proto.airplane.web.SearchFlightDomesticFilterReq', null, global);
goog.exportSymbol('proto.airplane.web.SearchFlightDomesticReq', null, global);
goog.exportSymbol('proto.airplane.web.SearchFlightDomesticRes', null, global);
goog.exportSymbol('proto.airplane.web.SearchFlightDomesticV2Req', null, global);
goog.exportSymbol('proto.airplane.web.SearchFlightDomesticV2Res', null, global);
goog.exportSymbol('proto.airplane.web.SearchFlightFilterReq', null, global);
goog.exportSymbol('proto.airplane.web.SearchFlightReq', null, global);
goog.exportSymbol('proto.airplane.web.SearchFlightRes', null, global);
goog.exportSymbol('proto.airplane.web.SearchMinFareReq', null, global);
goog.exportSymbol('proto.airplane.web.SearchMinFareRes', null, global);
goog.exportSymbol('proto.airplane.web.SearchMinFareRes.MinFare', null, global);
goog.exportSymbol('proto.airplane.web.SeatSelection', null, global);
goog.exportSymbol('proto.airplane.web.Segment', null, global);
goog.exportSymbol('proto.airplane.web.SortItem', null, global);
goog.exportSymbol('proto.airplane.web.SortItemType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.PromoErrorRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.PromoErrorRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.PromoErrorRes.displayName = 'proto.airplane.web.PromoErrorRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.PromoApplyRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.PromoApplyRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.PromoApplyRes.displayName = 'proto.airplane.web.PromoApplyRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.FlightPair = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.FlightPair, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.FlightPair.displayName = 'proto.airplane.web.FlightPair';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.BookingFlightV2Req = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web.BookingFlightV2Req.repeatedFields_, null);
};
goog.inherits(proto.airplane.web.BookingFlightV2Req, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.BookingFlightV2Req.displayName = 'proto.airplane.web.BookingFlightV2Req';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.BookingFlightV2Res = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.BookingFlightV2Res, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.BookingFlightV2Res.displayName = 'proto.airplane.web.BookingFlightV2Res';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.SearchFlightDomesticV2Req = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web.SearchFlightDomesticV2Req.repeatedFields_, null);
};
goog.inherits(proto.airplane.web.SearchFlightDomesticV2Req, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.SearchFlightDomesticV2Req.displayName = 'proto.airplane.web.SearchFlightDomesticV2Req';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.SearchFlightDomesticV2Res = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web.SearchFlightDomesticV2Res.repeatedFields_, null);
};
goog.inherits(proto.airplane.web.SearchFlightDomesticV2Res, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.SearchFlightDomesticV2Res.displayName = 'proto.airplane.web.SearchFlightDomesticV2Res';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.ListBookingFlightReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web.ListBookingFlightReq.repeatedFields_, null);
};
goog.inherits(proto.airplane.web.ListBookingFlightReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.ListBookingFlightReq.displayName = 'proto.airplane.web.ListBookingFlightReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.ListBookingFlightReq.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web.ListBookingFlightReq.Filter.repeatedFields_, null);
};
goog.inherits(proto.airplane.web.ListBookingFlightReq.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.ListBookingFlightReq.Filter.displayName = 'proto.airplane.web.ListBookingFlightReq.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.ListBookingFlightRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web.ListBookingFlightRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web.ListBookingFlightRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.ListBookingFlightRes.displayName = 'proto.airplane.web.ListBookingFlightRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.SearchFlightReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web.SearchFlightReq.repeatedFields_, null);
};
goog.inherits(proto.airplane.web.SearchFlightReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.SearchFlightReq.displayName = 'proto.airplane.web.SearchFlightReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.SearchFlightFilterReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web.SearchFlightFilterReq.repeatedFields_, null);
};
goog.inherits(proto.airplane.web.SearchFlightFilterReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.SearchFlightFilterReq.displayName = 'proto.airplane.web.SearchFlightFilterReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.FlightReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.FlightReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.FlightReq.displayName = 'proto.airplane.web.FlightReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.SortItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.SortItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.SortItem.displayName = 'proto.airplane.web.SortItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.PassengerQuantity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.PassengerQuantity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.PassengerQuantity.displayName = 'proto.airplane.web.PassengerQuantity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.SearchFlightRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web.SearchFlightRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web.SearchFlightRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.SearchFlightRes.displayName = 'proto.airplane.web.SearchFlightRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.FlightInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web.FlightInfo.repeatedFields_, null);
};
goog.inherits(proto.airplane.web.FlightInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.FlightInfo.displayName = 'proto.airplane.web.FlightInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.Segment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.Segment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.Segment.displayName = 'proto.airplane.web.Segment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.FlightClass = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web.FlightClass.repeatedFields_, null);
};
goog.inherits(proto.airplane.web.FlightClass, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.FlightClass.displayName = 'proto.airplane.web.FlightClass';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.FlightClass.FlightData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.FlightClass.FlightData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.FlightClass.FlightData.displayName = 'proto.airplane.web.FlightClass.FlightData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.PaxFare = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.PaxFare, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.PaxFare.displayName = 'proto.airplane.web.PaxFare';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.AirportInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.AirportInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.AirportInfo.displayName = 'proto.airplane.web.AirportInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.SearchAirportsFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.SearchAirportsFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.SearchAirportsFilter.displayName = 'proto.airplane.web.SearchAirportsFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.SearchAirportsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web.SearchAirportsReq.repeatedFields_, null);
};
goog.inherits(proto.airplane.web.SearchAirportsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.SearchAirportsReq.displayName = 'proto.airplane.web.SearchAirportsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.SearchAirportsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web.SearchAirportsRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web.SearchAirportsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.SearchAirportsRes.displayName = 'proto.airplane.web.SearchAirportsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.GetAirportsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web.GetAirportsReq.repeatedFields_, null);
};
goog.inherits(proto.airplane.web.GetAirportsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.GetAirportsReq.displayName = 'proto.airplane.web.GetAirportsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.GetAirportsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web.GetAirportsRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web.GetAirportsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.GetAirportsRes.displayName = 'proto.airplane.web.GetAirportsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.Routing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.Routing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.Routing.displayName = 'proto.airplane.web.Routing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.FareCheckReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.FareCheckReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.FareCheckReq.displayName = 'proto.airplane.web.FareCheckReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.FareCheckV2Req = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web.FareCheckV2Req.repeatedFields_, null);
};
goog.inherits(proto.airplane.web.FareCheckV2Req, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.FareCheckV2Req.displayName = 'proto.airplane.web.FareCheckV2Req';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.FareData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web.FareData.repeatedFields_, null);
};
goog.inherits(proto.airplane.web.FareData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.FareData.displayName = 'proto.airplane.web.FareData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.FareCheckRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.FareCheckRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.FareCheckRes.displayName = 'proto.airplane.web.FareCheckRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.FareCheckRes.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web.FareCheckRes.Data.repeatedFields_, null);
};
goog.inherits(proto.airplane.web.FareCheckRes.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.FareCheckRes.Data.displayName = 'proto.airplane.web.FareCheckRes.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.SearchMinFareReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.SearchMinFareReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.SearchMinFareReq.displayName = 'proto.airplane.web.SearchMinFareReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.SearchMinFareRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web.SearchMinFareRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web.SearchMinFareRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.SearchMinFareRes.displayName = 'proto.airplane.web.SearchMinFareRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.SearchMinFareRes.MinFare = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.SearchMinFareRes.MinFare, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.SearchMinFareRes.MinFare.displayName = 'proto.airplane.web.SearchMinFareRes.MinFare';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.Passenger = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.Passenger, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.Passenger.displayName = 'proto.airplane.web.Passenger';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.ContactInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.ContactInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.ContactInfo.displayName = 'proto.airplane.web.ContactInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.BookingFlightReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web.BookingFlightReq.repeatedFields_, null);
};
goog.inherits(proto.airplane.web.BookingFlightReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.BookingFlightReq.displayName = 'proto.airplane.web.BookingFlightReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.BookingFlightRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web.BookingFlightRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web.BookingFlightRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.BookingFlightRes.displayName = 'proto.airplane.web.BookingFlightRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.BookingFlightRes.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.BookingFlightRes.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.BookingFlightRes.Data.displayName = 'proto.airplane.web.BookingFlightRes.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.RetrieveBookingFlightReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.RetrieveBookingFlightReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.RetrieveBookingFlightReq.displayName = 'proto.airplane.web.RetrieveBookingFlightReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.PromoLog = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.PromoLog, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.PromoLog.displayName = 'proto.airplane.web.PromoLog';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.RetrieveBookingFlightRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.RetrieveBookingFlightRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.RetrieveBookingFlightRes.displayName = 'proto.airplane.web.RetrieveBookingFlightRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.RetrieveBookingFlightRes.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web.RetrieveBookingFlightRes.Data.repeatedFields_, null);
};
goog.inherits(proto.airplane.web.RetrieveBookingFlightRes.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.RetrieveBookingFlightRes.Data.displayName = 'proto.airplane.web.RetrieveBookingFlightRes.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.ETicket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.ETicket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.ETicket.displayName = 'proto.airplane.web.ETicket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.BookingPassenger = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.BookingPassenger, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.BookingPassenger.displayName = 'proto.airplane.web.BookingPassenger';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.BookingContactInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.BookingContactInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.BookingContactInfo.displayName = 'proto.airplane.web.BookingContactInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.CalculationFlightFeeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.CalculationFlightFeeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.CalculationFlightFeeReq.displayName = 'proto.airplane.web.CalculationFlightFeeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.CalculationFlightFeeV2Req = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web.CalculationFlightFeeV2Req.repeatedFields_, null);
};
goog.inherits(proto.airplane.web.CalculationFlightFeeV2Req, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.CalculationFlightFeeV2Req.displayName = 'proto.airplane.web.CalculationFlightFeeV2Req';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.CalculationFlightFeeRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.CalculationFlightFeeRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.CalculationFlightFeeRes.displayName = 'proto.airplane.web.CalculationFlightFeeRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.GetSeatMapReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.GetSeatMapReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.GetSeatMapReq.displayName = 'proto.airplane.web.GetSeatMapReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.GetSeatMapRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web.GetSeatMapRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web.GetSeatMapRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.GetSeatMapRes.displayName = 'proto.airplane.web.GetSeatMapRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.GetBaggageOptionsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.GetBaggageOptionsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.GetBaggageOptionsReq.displayName = 'proto.airplane.web.GetBaggageOptionsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.GetBaggageOptionsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web.GetBaggageOptionsRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web.GetBaggageOptionsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.GetBaggageOptionsRes.displayName = 'proto.airplane.web.GetBaggageOptionsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.IssueTicketReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.IssueTicketReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.IssueTicketReq.displayName = 'proto.airplane.web.IssueTicketReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.IssueTicketRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.IssueTicketRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.IssueTicketRes.displayName = 'proto.airplane.web.IssueTicketRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.MarkBookingAsFailedAndCancelReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.MarkBookingAsFailedAndCancelReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.MarkBookingAsFailedAndCancelReq.displayName = 'proto.airplane.web.MarkBookingAsFailedAndCancelReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.MarkBookingAsFailedAndCancelRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.MarkBookingAsFailedAndCancelRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.MarkBookingAsFailedAndCancelRes.displayName = 'proto.airplane.web.MarkBookingAsFailedAndCancelRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.FareCheckDomesticReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web.FareCheckDomesticReq.repeatedFields_, null);
};
goog.inherits(proto.airplane.web.FareCheckDomesticReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.FareCheckDomesticReq.displayName = 'proto.airplane.web.FareCheckDomesticReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo.displayName = 'proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.FareCheckDomesticRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.FareCheckDomesticRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.FareCheckDomesticRes.displayName = 'proto.airplane.web.FareCheckDomesticRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.FareCheckDomesticRes.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web.FareCheckDomesticRes.Data.repeatedFields_, null);
};
goog.inherits(proto.airplane.web.FareCheckDomesticRes.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.FareCheckDomesticRes.Data.displayName = 'proto.airplane.web.FareCheckDomesticRes.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.CalculationFlightFeeDomesticReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web.CalculationFlightFeeDomesticReq.repeatedFields_, null);
};
goog.inherits(proto.airplane.web.CalculationFlightFeeDomesticReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.CalculationFlightFeeDomesticReq.displayName = 'proto.airplane.web.CalculationFlightFeeDomesticReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair.displayName = 'proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.CalculationFlightFeeDomesticRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web.CalculationFlightFeeDomesticRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web.CalculationFlightFeeDomesticRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.CalculationFlightFeeDomesticRes.displayName = 'proto.airplane.web.CalculationFlightFeeDomesticRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.BookingFlightDomesticRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web.BookingFlightDomesticRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web.BookingFlightDomesticRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.BookingFlightDomesticRes.displayName = 'proto.airplane.web.BookingFlightDomesticRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.FlightBookingInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.FlightBookingInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.FlightBookingInfo.displayName = 'proto.airplane.web.FlightBookingInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.BookingFlightDomesticReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web.BookingFlightDomesticReq.repeatedFields_, null);
};
goog.inherits(proto.airplane.web.BookingFlightDomesticReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.BookingFlightDomesticReq.displayName = 'proto.airplane.web.BookingFlightDomesticReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web.BookingFlightDomesticReq.Passenger.repeatedFields_, null);
};
goog.inherits(proto.airplane.web.BookingFlightDomesticReq.Passenger, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.BookingFlightDomesticReq.Passenger.displayName = 'proto.airplane.web.BookingFlightDomesticReq.Passenger';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.BookingFlightDomesticReq.ContactInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.BookingFlightDomesticReq.ContactInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.BookingFlightDomesticReq.ContactInfo.displayName = 'proto.airplane.web.BookingFlightDomesticReq.ContactInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.BookingFlightDomesticReq.FlightPair = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.BookingFlightDomesticReq.FlightPair, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.BookingFlightDomesticReq.FlightPair.displayName = 'proto.airplane.web.BookingFlightDomesticReq.FlightPair';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.SeatSelection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.SeatSelection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.SeatSelection.displayName = 'proto.airplane.web.SeatSelection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.BaggageSelection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.BaggageSelection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.BaggageSelection.displayName = 'proto.airplane.web.BaggageSelection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.SearchFlightDomesticReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web.SearchFlightDomesticReq.repeatedFields_, null);
};
goog.inherits(proto.airplane.web.SearchFlightDomesticReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.SearchFlightDomesticReq.displayName = 'proto.airplane.web.SearchFlightDomesticReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.SearchFlightDomesticRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web.SearchFlightDomesticRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web.SearchFlightDomesticRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.SearchFlightDomesticRes.displayName = 'proto.airplane.web.SearchFlightDomesticRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web.SearchFlightDomesticFilterReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web.SearchFlightDomesticFilterReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web.SearchFlightDomesticFilterReq.displayName = 'proto.airplane.web.SearchFlightDomesticFilterReq';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.PromoErrorRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.PromoErrorRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.PromoErrorRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.PromoErrorRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.PromoErrorRes}
 */
proto.airplane.web.PromoErrorRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.PromoErrorRes;
  return proto.airplane.web.PromoErrorRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.PromoErrorRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.PromoErrorRes}
 */
proto.airplane.web.PromoErrorRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.PromoErrorRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.PromoErrorRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.PromoErrorRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.PromoErrorRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.airplane.web.PromoErrorRes.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.PromoErrorRes} returns this
 */
proto.airplane.web.PromoErrorRes.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.airplane.web.PromoErrorRes.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.PromoErrorRes} returns this
 */
proto.airplane.web.PromoErrorRes.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.PromoApplyRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.PromoApplyRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.PromoApplyRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.PromoApplyRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.PromoApplyRes}
 */
proto.airplane.web.PromoApplyRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.PromoApplyRes;
  return proto.airplane.web.PromoApplyRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.PromoApplyRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.PromoApplyRes}
 */
proto.airplane.web.PromoApplyRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.PromoApplyRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.PromoApplyRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.PromoApplyRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.PromoApplyRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.airplane.web.PromoApplyRes.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.PromoApplyRes} returns this
 */
proto.airplane.web.PromoApplyRes.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double amount = 2;
 * @return {number}
 */
proto.airplane.web.PromoApplyRes.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.PromoApplyRes} returns this
 */
proto.airplane.web.PromoApplyRes.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.FlightPair.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.FlightPair.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.FlightPair} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.FlightPair.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    flightId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.FlightPair}
 */
proto.airplane.web.FlightPair.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.FlightPair;
  return proto.airplane.web.FlightPair.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.FlightPair} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.FlightPair}
 */
proto.airplane.web.FlightPair.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.FlightPair.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.FlightPair.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.FlightPair} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.FlightPair.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.airplane.web.FlightPair.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.FlightPair} returns this
 */
proto.airplane.web.FlightPair.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string flight_id = 2;
 * @return {string}
 */
proto.airplane.web.FlightPair.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.FlightPair} returns this
 */
proto.airplane.web.FlightPair.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web.BookingFlightV2Req.repeatedFields_ = [3,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.BookingFlightV2Req.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.BookingFlightV2Req.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.BookingFlightV2Req} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.BookingFlightV2Req.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    contactInfo: (f = msg.getContactInfo()) && proto.airplane.web.ContactInfo.toObject(includeInstance, f),
    passengersList: jspb.Message.toObjectList(msg.getPassengersList(),
    proto.airplane.web.Passenger.toObject, includeInstance),
    confirmedPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    flightPairsList: jspb.Message.toObjectList(msg.getFlightPairsList(),
    proto.airplane.web.FlightPair.toObject, includeInstance),
    staticLastTicketDate: jspb.Message.getFieldWithDefault(msg, 6, 0),
    affiliateMarketingNetworkInfo: (f = msg.getAffiliateMarketingNetworkInfo()) && airplane_flight_pb.AffiliateMarketingNetworkInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.BookingFlightV2Req}
 */
proto.airplane.web.BookingFlightV2Req.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.BookingFlightV2Req;
  return proto.airplane.web.BookingFlightV2Req.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.BookingFlightV2Req} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.BookingFlightV2Req}
 */
proto.airplane.web.BookingFlightV2Req.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 2:
      var value = new proto.airplane.web.ContactInfo;
      reader.readMessage(value,proto.airplane.web.ContactInfo.deserializeBinaryFromReader);
      msg.setContactInfo(value);
      break;
    case 3:
      var value = new proto.airplane.web.Passenger;
      reader.readMessage(value,proto.airplane.web.Passenger.deserializeBinaryFromReader);
      msg.addPassengers(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setConfirmedPrice(value);
      break;
    case 5:
      var value = new proto.airplane.web.FlightPair;
      reader.readMessage(value,proto.airplane.web.FlightPair.deserializeBinaryFromReader);
      msg.addFlightPairs(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStaticLastTicketDate(value);
      break;
    case 7:
      var value = new airplane_flight_pb.AffiliateMarketingNetworkInfo;
      reader.readMessage(value,airplane_flight_pb.AffiliateMarketingNetworkInfo.deserializeBinaryFromReader);
      msg.setAffiliateMarketingNetworkInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.BookingFlightV2Req.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.BookingFlightV2Req.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.BookingFlightV2Req} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.BookingFlightV2Req.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContactInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.airplane.web.ContactInfo.serializeBinaryToWriter
    );
  }
  f = message.getPassengersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.airplane.web.Passenger.serializeBinaryToWriter
    );
  }
  f = message.getConfirmedPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getFlightPairsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.airplane.web.FlightPair.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getAffiliateMarketingNetworkInfo();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      airplane_flight_pb.AffiliateMarketingNetworkInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.airplane.web.BookingFlightV2Req.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightV2Req} returns this
 */
proto.airplane.web.BookingFlightV2Req.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ContactInfo contact_info = 2;
 * @return {?proto.airplane.web.ContactInfo}
 */
proto.airplane.web.BookingFlightV2Req.prototype.getContactInfo = function() {
  return /** @type{?proto.airplane.web.ContactInfo} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web.ContactInfo, 2));
};


/**
 * @param {?proto.airplane.web.ContactInfo|undefined} value
 * @return {!proto.airplane.web.BookingFlightV2Req} returns this
*/
proto.airplane.web.BookingFlightV2Req.prototype.setContactInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.BookingFlightV2Req} returns this
 */
proto.airplane.web.BookingFlightV2Req.prototype.clearContactInfo = function() {
  return this.setContactInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.BookingFlightV2Req.prototype.hasContactInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Passenger passengers = 3;
 * @return {!Array<!proto.airplane.web.Passenger>}
 */
proto.airplane.web.BookingFlightV2Req.prototype.getPassengersList = function() {
  return /** @type{!Array<!proto.airplane.web.Passenger>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web.Passenger, 3));
};


/**
 * @param {!Array<!proto.airplane.web.Passenger>} value
 * @return {!proto.airplane.web.BookingFlightV2Req} returns this
*/
proto.airplane.web.BookingFlightV2Req.prototype.setPassengersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.web.Passenger=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web.Passenger}
 */
proto.airplane.web.BookingFlightV2Req.prototype.addPassengers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.web.Passenger, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.BookingFlightV2Req} returns this
 */
proto.airplane.web.BookingFlightV2Req.prototype.clearPassengersList = function() {
  return this.setPassengersList([]);
};


/**
 * optional double confirmed_price = 4;
 * @return {number}
 */
proto.airplane.web.BookingFlightV2Req.prototype.getConfirmedPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.BookingFlightV2Req} returns this
 */
proto.airplane.web.BookingFlightV2Req.prototype.setConfirmedPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * repeated FlightPair flight_pairs = 5;
 * @return {!Array<!proto.airplane.web.FlightPair>}
 */
proto.airplane.web.BookingFlightV2Req.prototype.getFlightPairsList = function() {
  return /** @type{!Array<!proto.airplane.web.FlightPair>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web.FlightPair, 5));
};


/**
 * @param {!Array<!proto.airplane.web.FlightPair>} value
 * @return {!proto.airplane.web.BookingFlightV2Req} returns this
*/
proto.airplane.web.BookingFlightV2Req.prototype.setFlightPairsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.airplane.web.FlightPair=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web.FlightPair}
 */
proto.airplane.web.BookingFlightV2Req.prototype.addFlightPairs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.airplane.web.FlightPair, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.BookingFlightV2Req} returns this
 */
proto.airplane.web.BookingFlightV2Req.prototype.clearFlightPairsList = function() {
  return this.setFlightPairsList([]);
};


/**
 * optional int64 static_last_ticket_date = 6;
 * @return {number}
 */
proto.airplane.web.BookingFlightV2Req.prototype.getStaticLastTicketDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.BookingFlightV2Req} returns this
 */
proto.airplane.web.BookingFlightV2Req.prototype.setStaticLastTicketDate = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web.BookingFlightV2Req} returns this
 */
proto.airplane.web.BookingFlightV2Req.prototype.clearStaticLastTicketDate = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.BookingFlightV2Req.prototype.hasStaticLastTicketDate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional airplane.AffiliateMarketingNetworkInfo affiliate_marketing_network_info = 7;
 * @return {?proto.airplane.AffiliateMarketingNetworkInfo}
 */
proto.airplane.web.BookingFlightV2Req.prototype.getAffiliateMarketingNetworkInfo = function() {
  return /** @type{?proto.airplane.AffiliateMarketingNetworkInfo} */ (
    jspb.Message.getWrapperField(this, airplane_flight_pb.AffiliateMarketingNetworkInfo, 7));
};


/**
 * @param {?proto.airplane.AffiliateMarketingNetworkInfo|undefined} value
 * @return {!proto.airplane.web.BookingFlightV2Req} returns this
*/
proto.airplane.web.BookingFlightV2Req.prototype.setAffiliateMarketingNetworkInfo = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.BookingFlightV2Req} returns this
 */
proto.airplane.web.BookingFlightV2Req.prototype.clearAffiliateMarketingNetworkInfo = function() {
  return this.setAffiliateMarketingNetworkInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.BookingFlightV2Req.prototype.hasAffiliateMarketingNetworkInfo = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.BookingFlightV2Res.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.BookingFlightV2Res.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.BookingFlightV2Res} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.BookingFlightV2Res.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errorMsg: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sessionId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    newFareInfo: (f = msg.getNewFareInfo()) && airplane_base_pb.CalculationFlightFeeResData.toObject(includeInstance, f),
    orderId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    bookingId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    bookingCode: jspb.Message.getFieldWithDefault(msg, 8, ""),
    oldFareInfo: (f = msg.getOldFareInfo()) && airplane_base_pb.CalculationFlightFeeResData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.BookingFlightV2Res}
 */
proto.airplane.web.BookingFlightV2Res.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.BookingFlightV2Res;
  return proto.airplane.web.BookingFlightV2Res.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.BookingFlightV2Res} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.BookingFlightV2Res}
 */
proto.airplane.web.BookingFlightV2Res.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorMsg(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 5:
      var value = new airplane_base_pb.CalculationFlightFeeResData;
      reader.readMessage(value,airplane_base_pb.CalculationFlightFeeResData.deserializeBinaryFromReader);
      msg.setNewFareInfo(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    case 9:
      var value = new airplane_base_pb.CalculationFlightFeeResData;
      reader.readMessage(value,airplane_base_pb.CalculationFlightFeeResData.deserializeBinaryFromReader);
      msg.setOldFareInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.BookingFlightV2Res.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.BookingFlightV2Res.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.BookingFlightV2Res} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.BookingFlightV2Res.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrorMsg();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNewFareInfo();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      airplane_base_pb.CalculationFlightFeeResData.serializeBinaryToWriter
    );
  }
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBookingId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getOldFareInfo();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      airplane_base_pb.CalculationFlightFeeResData.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web.BookingFlightV2Res.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web.BookingFlightV2Res} returns this
 */
proto.airplane.web.BookingFlightV2Res.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web.BookingFlightV2Res.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightV2Res} returns this
 */
proto.airplane.web.BookingFlightV2Res.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string error_msg = 3;
 * @return {string}
 */
proto.airplane.web.BookingFlightV2Res.prototype.getErrorMsg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightV2Res} returns this
 */
proto.airplane.web.BookingFlightV2Res.prototype.setErrorMsg = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string session_id = 4;
 * @return {string}
 */
proto.airplane.web.BookingFlightV2Res.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightV2Res} returns this
 */
proto.airplane.web.BookingFlightV2Res.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional airplane.CalculationFlightFeeResData new_fare_info = 5;
 * @return {?proto.airplane.CalculationFlightFeeResData}
 */
proto.airplane.web.BookingFlightV2Res.prototype.getNewFareInfo = function() {
  return /** @type{?proto.airplane.CalculationFlightFeeResData} */ (
    jspb.Message.getWrapperField(this, airplane_base_pb.CalculationFlightFeeResData, 5));
};


/**
 * @param {?proto.airplane.CalculationFlightFeeResData|undefined} value
 * @return {!proto.airplane.web.BookingFlightV2Res} returns this
*/
proto.airplane.web.BookingFlightV2Res.prototype.setNewFareInfo = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.BookingFlightV2Res} returns this
 */
proto.airplane.web.BookingFlightV2Res.prototype.clearNewFareInfo = function() {
  return this.setNewFareInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.BookingFlightV2Res.prototype.hasNewFareInfo = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string order_id = 6;
 * @return {string}
 */
proto.airplane.web.BookingFlightV2Res.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightV2Res} returns this
 */
proto.airplane.web.BookingFlightV2Res.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string booking_id = 7;
 * @return {string}
 */
proto.airplane.web.BookingFlightV2Res.prototype.getBookingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightV2Res} returns this
 */
proto.airplane.web.BookingFlightV2Res.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string booking_code = 8;
 * @return {string}
 */
proto.airplane.web.BookingFlightV2Res.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightV2Res} returns this
 */
proto.airplane.web.BookingFlightV2Res.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional airplane.CalculationFlightFeeResData old_fare_info = 9;
 * @return {?proto.airplane.CalculationFlightFeeResData}
 */
proto.airplane.web.BookingFlightV2Res.prototype.getOldFareInfo = function() {
  return /** @type{?proto.airplane.CalculationFlightFeeResData} */ (
    jspb.Message.getWrapperField(this, airplane_base_pb.CalculationFlightFeeResData, 9));
};


/**
 * @param {?proto.airplane.CalculationFlightFeeResData|undefined} value
 * @return {!proto.airplane.web.BookingFlightV2Res} returns this
*/
proto.airplane.web.BookingFlightV2Res.prototype.setOldFareInfo = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.BookingFlightV2Res} returns this
 */
proto.airplane.web.BookingFlightV2Res.prototype.clearOldFareInfo = function() {
  return this.setOldFareInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.BookingFlightV2Res.prototype.hasOldFareInfo = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web.SearchFlightDomesticV2Req.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.SearchFlightDomesticV2Req.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.SearchFlightDomesticV2Req.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.SearchFlightDomesticV2Req} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.SearchFlightDomesticV2Req.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.airplane.web.SearchFlightFilterReq.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f),
    sortsList: jspb.Message.toObjectList(msg.getSortsList(),
    proto.airplane.web.SortItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.SearchFlightDomesticV2Req}
 */
proto.airplane.web.SearchFlightDomesticV2Req.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.SearchFlightDomesticV2Req;
  return proto.airplane.web.SearchFlightDomesticV2Req.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.SearchFlightDomesticV2Req} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.SearchFlightDomesticV2Req}
 */
proto.airplane.web.SearchFlightDomesticV2Req.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web.SearchFlightFilterReq;
      reader.readMessage(value,proto.airplane.web.SearchFlightFilterReq.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var value = new proto.airplane.web.SortItem;
      reader.readMessage(value,proto.airplane.web.SortItem.deserializeBinaryFromReader);
      msg.addSorts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.SearchFlightDomesticV2Req.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.SearchFlightDomesticV2Req.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.SearchFlightDomesticV2Req} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.SearchFlightDomesticV2Req.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.airplane.web.SearchFlightFilterReq.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
  f = message.getSortsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.airplane.web.SortItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional SearchFlightFilterReq filter = 1;
 * @return {?proto.airplane.web.SearchFlightFilterReq}
 */
proto.airplane.web.SearchFlightDomesticV2Req.prototype.getFilter = function() {
  return /** @type{?proto.airplane.web.SearchFlightFilterReq} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web.SearchFlightFilterReq, 1));
};


/**
 * @param {?proto.airplane.web.SearchFlightFilterReq|undefined} value
 * @return {!proto.airplane.web.SearchFlightDomesticV2Req} returns this
*/
proto.airplane.web.SearchFlightDomesticV2Req.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.SearchFlightDomesticV2Req} returns this
 */
proto.airplane.web.SearchFlightDomesticV2Req.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.SearchFlightDomesticV2Req.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional base.PaginationReq pagination = 2;
 * @return {?proto.base.PaginationReq}
 */
proto.airplane.web.SearchFlightDomesticV2Req.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 2));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.airplane.web.SearchFlightDomesticV2Req} returns this
*/
proto.airplane.web.SearchFlightDomesticV2Req.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.SearchFlightDomesticV2Req} returns this
 */
proto.airplane.web.SearchFlightDomesticV2Req.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.SearchFlightDomesticV2Req.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated SortItem sorts = 3;
 * @return {!Array<!proto.airplane.web.SortItem>}
 */
proto.airplane.web.SearchFlightDomesticV2Req.prototype.getSortsList = function() {
  return /** @type{!Array<!proto.airplane.web.SortItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web.SortItem, 3));
};


/**
 * @param {!Array<!proto.airplane.web.SortItem>} value
 * @return {!proto.airplane.web.SearchFlightDomesticV2Req} returns this
*/
proto.airplane.web.SearchFlightDomesticV2Req.prototype.setSortsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.web.SortItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web.SortItem}
 */
proto.airplane.web.SearchFlightDomesticV2Req.prototype.addSorts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.web.SortItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.SearchFlightDomesticV2Req} returns this
 */
proto.airplane.web.SearchFlightDomesticV2Req.prototype.clearSortsList = function() {
  return this.setSortsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web.SearchFlightDomesticV2Res.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.SearchFlightDomesticV2Res.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.SearchFlightDomesticV2Res.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.SearchFlightDomesticV2Res} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.SearchFlightDomesticV2Res.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    airplane_domestic_flight_pb.DomesticFlightInfo.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f),
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    expiredAt: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.SearchFlightDomesticV2Res}
 */
proto.airplane.web.SearchFlightDomesticV2Res.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.SearchFlightDomesticV2Res;
  return proto.airplane.web.SearchFlightDomesticV2Res.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.SearchFlightDomesticV2Res} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.SearchFlightDomesticV2Res}
 */
proto.airplane.web.SearchFlightDomesticV2Res.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = new airplane_domestic_flight_pb.DomesticFlightInfo;
      reader.readMessage(value,airplane_domestic_flight_pb.DomesticFlightInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 3:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiredAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.SearchFlightDomesticV2Res.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.SearchFlightDomesticV2Res.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.SearchFlightDomesticV2Res} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.SearchFlightDomesticV2Res.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      airplane_domestic_flight_pb.DomesticFlightInfo.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getExpiredAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.airplane.web.SearchFlightDomesticV2Res.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.SearchFlightDomesticV2Res} returns this
 */
proto.airplane.web.SearchFlightDomesticV2Res.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated airplane.DomesticFlightInfo items = 2;
 * @return {!Array<!proto.airplane.DomesticFlightInfo>}
 */
proto.airplane.web.SearchFlightDomesticV2Res.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.DomesticFlightInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, airplane_domestic_flight_pb.DomesticFlightInfo, 2));
};


/**
 * @param {!Array<!proto.airplane.DomesticFlightInfo>} value
 * @return {!proto.airplane.web.SearchFlightDomesticV2Res} returns this
*/
proto.airplane.web.SearchFlightDomesticV2Res.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.airplane.DomesticFlightInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.DomesticFlightInfo}
 */
proto.airplane.web.SearchFlightDomesticV2Res.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.airplane.DomesticFlightInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.SearchFlightDomesticV2Res} returns this
 */
proto.airplane.web.SearchFlightDomesticV2Res.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional base.PaginationRes pagination = 3;
 * @return {?proto.base.PaginationRes}
 */
proto.airplane.web.SearchFlightDomesticV2Res.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 3));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.airplane.web.SearchFlightDomesticV2Res} returns this
*/
proto.airplane.web.SearchFlightDomesticV2Res.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.SearchFlightDomesticV2Res} returns this
 */
proto.airplane.web.SearchFlightDomesticV2Res.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.SearchFlightDomesticV2Res.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool is_success = 4;
 * @return {boolean}
 */
proto.airplane.web.SearchFlightDomesticV2Res.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web.SearchFlightDomesticV2Res} returns this
 */
proto.airplane.web.SearchFlightDomesticV2Res.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string error_code = 5;
 * @return {string}
 */
proto.airplane.web.SearchFlightDomesticV2Res.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.SearchFlightDomesticV2Res} returns this
 */
proto.airplane.web.SearchFlightDomesticV2Res.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 expired_at = 6;
 * @return {number}
 */
proto.airplane.web.SearchFlightDomesticV2Res.prototype.getExpiredAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.SearchFlightDomesticV2Res} returns this
 */
proto.airplane.web.SearchFlightDomesticV2Res.prototype.setExpiredAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web.ListBookingFlightReq.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.ListBookingFlightReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.ListBookingFlightReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.ListBookingFlightReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.ListBookingFlightReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.airplane.web.ListBookingFlightReq.Filter.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f),
    sortsList: jspb.Message.toObjectList(msg.getSortsList(),
    proto.airplane.web.SortItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.ListBookingFlightReq}
 */
proto.airplane.web.ListBookingFlightReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.ListBookingFlightReq;
  return proto.airplane.web.ListBookingFlightReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.ListBookingFlightReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.ListBookingFlightReq}
 */
proto.airplane.web.ListBookingFlightReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web.ListBookingFlightReq.Filter;
      reader.readMessage(value,proto.airplane.web.ListBookingFlightReq.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var value = new proto.airplane.web.SortItem;
      reader.readMessage(value,proto.airplane.web.SortItem.deserializeBinaryFromReader);
      msg.addSorts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.ListBookingFlightReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.ListBookingFlightReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.ListBookingFlightReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.ListBookingFlightReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.airplane.web.ListBookingFlightReq.Filter.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
  f = message.getSortsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.airplane.web.SortItem.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web.ListBookingFlightReq.Filter.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.ListBookingFlightReq.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.ListBookingFlightReq.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.ListBookingFlightReq.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.ListBookingFlightReq.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    statusesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.ListBookingFlightReq.Filter}
 */
proto.airplane.web.ListBookingFlightReq.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.ListBookingFlightReq.Filter;
  return proto.airplane.web.ListBookingFlightReq.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.ListBookingFlightReq.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.ListBookingFlightReq.Filter}
 */
proto.airplane.web.ListBookingFlightReq.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.airplane.BookingStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatuses(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.ListBookingFlightReq.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.ListBookingFlightReq.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.ListBookingFlightReq.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.ListBookingFlightReq.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatusesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
};


/**
 * optional string booking_code = 1;
 * @return {string}
 */
proto.airplane.web.ListBookingFlightReq.Filter.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web.ListBookingFlightReq.Filter.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated airplane.BookingStatus statuses = 2;
 * @return {!Array<!proto.airplane.BookingStatus>}
 */
proto.airplane.web.ListBookingFlightReq.Filter.prototype.getStatusesList = function() {
  return /** @type {!Array<!proto.airplane.BookingStatus>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.airplane.BookingStatus>} value
 * @return {!proto.airplane.web.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web.ListBookingFlightReq.Filter.prototype.setStatusesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.airplane.BookingStatus} value
 * @param {number=} opt_index
 * @return {!proto.airplane.web.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web.ListBookingFlightReq.Filter.prototype.addStatuses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web.ListBookingFlightReq.Filter.prototype.clearStatusesList = function() {
  return this.setStatusesList([]);
};


/**
 * optional Filter filter = 1;
 * @return {?proto.airplane.web.ListBookingFlightReq.Filter}
 */
proto.airplane.web.ListBookingFlightReq.prototype.getFilter = function() {
  return /** @type{?proto.airplane.web.ListBookingFlightReq.Filter} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web.ListBookingFlightReq.Filter, 1));
};


/**
 * @param {?proto.airplane.web.ListBookingFlightReq.Filter|undefined} value
 * @return {!proto.airplane.web.ListBookingFlightReq} returns this
*/
proto.airplane.web.ListBookingFlightReq.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.ListBookingFlightReq} returns this
 */
proto.airplane.web.ListBookingFlightReq.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.ListBookingFlightReq.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional base.PaginationReq pagination = 2;
 * @return {?proto.base.PaginationReq}
 */
proto.airplane.web.ListBookingFlightReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 2));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.airplane.web.ListBookingFlightReq} returns this
*/
proto.airplane.web.ListBookingFlightReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.ListBookingFlightReq} returns this
 */
proto.airplane.web.ListBookingFlightReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.ListBookingFlightReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated SortItem sorts = 3;
 * @return {!Array<!proto.airplane.web.SortItem>}
 */
proto.airplane.web.ListBookingFlightReq.prototype.getSortsList = function() {
  return /** @type{!Array<!proto.airplane.web.SortItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web.SortItem, 3));
};


/**
 * @param {!Array<!proto.airplane.web.SortItem>} value
 * @return {!proto.airplane.web.ListBookingFlightReq} returns this
*/
proto.airplane.web.ListBookingFlightReq.prototype.setSortsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.web.SortItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web.SortItem}
 */
proto.airplane.web.ListBookingFlightReq.prototype.addSorts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.web.SortItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.ListBookingFlightReq} returns this
 */
proto.airplane.web.ListBookingFlightReq.prototype.clearSortsList = function() {
  return this.setSortsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web.ListBookingFlightRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.ListBookingFlightRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.ListBookingFlightRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.ListBookingFlightRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.ListBookingFlightRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.airplane.web.RetrieveBookingFlightRes.Data.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.ListBookingFlightRes}
 */
proto.airplane.web.ListBookingFlightRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.ListBookingFlightRes;
  return proto.airplane.web.ListBookingFlightRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.ListBookingFlightRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.ListBookingFlightRes}
 */
proto.airplane.web.ListBookingFlightRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web.RetrieveBookingFlightRes.Data;
      reader.readMessage(value,proto.airplane.web.RetrieveBookingFlightRes.Data.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    case 4:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.ListBookingFlightRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.ListBookingFlightRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.ListBookingFlightRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.ListBookingFlightRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.airplane.web.RetrieveBookingFlightRes.Data.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web.ListBookingFlightRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web.ListBookingFlightRes} returns this
 */
proto.airplane.web.ListBookingFlightRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web.ListBookingFlightRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.ListBookingFlightRes} returns this
 */
proto.airplane.web.ListBookingFlightRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated RetrieveBookingFlightRes.Data data = 3;
 * @return {!Array<!proto.airplane.web.RetrieveBookingFlightRes.Data>}
 */
proto.airplane.web.ListBookingFlightRes.prototype.getDataList = function() {
  return /** @type{!Array<!proto.airplane.web.RetrieveBookingFlightRes.Data>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web.RetrieveBookingFlightRes.Data, 3));
};


/**
 * @param {!Array<!proto.airplane.web.RetrieveBookingFlightRes.Data>} value
 * @return {!proto.airplane.web.ListBookingFlightRes} returns this
*/
proto.airplane.web.ListBookingFlightRes.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.web.RetrieveBookingFlightRes.Data=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web.RetrieveBookingFlightRes.Data}
 */
proto.airplane.web.ListBookingFlightRes.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.web.RetrieveBookingFlightRes.Data, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.ListBookingFlightRes} returns this
 */
proto.airplane.web.ListBookingFlightRes.prototype.clearDataList = function() {
  return this.setDataList([]);
};


/**
 * optional base.PaginationRes pagination = 4;
 * @return {?proto.base.PaginationRes}
 */
proto.airplane.web.ListBookingFlightRes.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 4));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.airplane.web.ListBookingFlightRes} returns this
*/
proto.airplane.web.ListBookingFlightRes.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.ListBookingFlightRes} returns this
 */
proto.airplane.web.ListBookingFlightRes.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.ListBookingFlightRes.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web.SearchFlightReq.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.SearchFlightReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.SearchFlightReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.SearchFlightReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.SearchFlightReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.airplane.web.SearchFlightFilterReq.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f),
    sortsList: jspb.Message.toObjectList(msg.getSortsList(),
    proto.airplane.web.SortItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.SearchFlightReq}
 */
proto.airplane.web.SearchFlightReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.SearchFlightReq;
  return proto.airplane.web.SearchFlightReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.SearchFlightReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.SearchFlightReq}
 */
proto.airplane.web.SearchFlightReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web.SearchFlightFilterReq;
      reader.readMessage(value,proto.airplane.web.SearchFlightFilterReq.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var value = new proto.airplane.web.SortItem;
      reader.readMessage(value,proto.airplane.web.SortItem.deserializeBinaryFromReader);
      msg.addSorts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.SearchFlightReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.SearchFlightReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.SearchFlightReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.SearchFlightReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.airplane.web.SearchFlightFilterReq.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
  f = message.getSortsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.airplane.web.SortItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional SearchFlightFilterReq filter = 1;
 * @return {?proto.airplane.web.SearchFlightFilterReq}
 */
proto.airplane.web.SearchFlightReq.prototype.getFilter = function() {
  return /** @type{?proto.airplane.web.SearchFlightFilterReq} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web.SearchFlightFilterReq, 1));
};


/**
 * @param {?proto.airplane.web.SearchFlightFilterReq|undefined} value
 * @return {!proto.airplane.web.SearchFlightReq} returns this
*/
proto.airplane.web.SearchFlightReq.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.SearchFlightReq} returns this
 */
proto.airplane.web.SearchFlightReq.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.SearchFlightReq.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional base.PaginationReq pagination = 2;
 * @return {?proto.base.PaginationReq}
 */
proto.airplane.web.SearchFlightReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 2));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.airplane.web.SearchFlightReq} returns this
*/
proto.airplane.web.SearchFlightReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.SearchFlightReq} returns this
 */
proto.airplane.web.SearchFlightReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.SearchFlightReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated SortItem sorts = 3;
 * @return {!Array<!proto.airplane.web.SortItem>}
 */
proto.airplane.web.SearchFlightReq.prototype.getSortsList = function() {
  return /** @type{!Array<!proto.airplane.web.SortItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web.SortItem, 3));
};


/**
 * @param {!Array<!proto.airplane.web.SortItem>} value
 * @return {!proto.airplane.web.SearchFlightReq} returns this
*/
proto.airplane.web.SearchFlightReq.prototype.setSortsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.web.SortItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web.SortItem}
 */
proto.airplane.web.SearchFlightReq.prototype.addSorts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.web.SortItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.SearchFlightReq} returns this
 */
proto.airplane.web.SearchFlightReq.prototype.clearSortsList = function() {
  return this.setSortsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web.SearchFlightFilterReq.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.SearchFlightFilterReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.SearchFlightFilterReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.SearchFlightFilterReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.SearchFlightFilterReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    passengerQuantity: (f = msg.getPassengerQuantity()) && proto.airplane.web.PassengerQuantity.toObject(includeInstance, f),
    flightsList: jspb.Message.toObjectList(msg.getFlightsList(),
    proto.airplane.web.FlightReq.toObject, includeInstance),
    currency: jspb.Message.getFieldWithDefault(msg, 3, ""),
    externalHiddenFee: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.SearchFlightFilterReq}
 */
proto.airplane.web.SearchFlightFilterReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.SearchFlightFilterReq;
  return proto.airplane.web.SearchFlightFilterReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.SearchFlightFilterReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.SearchFlightFilterReq}
 */
proto.airplane.web.SearchFlightFilterReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web.PassengerQuantity;
      reader.readMessage(value,proto.airplane.web.PassengerQuantity.deserializeBinaryFromReader);
      msg.setPassengerQuantity(value);
      break;
    case 2:
      var value = new proto.airplane.web.FlightReq;
      reader.readMessage(value,proto.airplane.web.FlightReq.deserializeBinaryFromReader);
      msg.addFlights(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalHiddenFee(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.SearchFlightFilterReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.SearchFlightFilterReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.SearchFlightFilterReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.SearchFlightFilterReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPassengerQuantity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.airplane.web.PassengerQuantity.serializeBinaryToWriter
    );
  }
  f = message.getFlightsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.airplane.web.FlightReq.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExternalHiddenFee();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional PassengerQuantity passenger_quantity = 1;
 * @return {?proto.airplane.web.PassengerQuantity}
 */
proto.airplane.web.SearchFlightFilterReq.prototype.getPassengerQuantity = function() {
  return /** @type{?proto.airplane.web.PassengerQuantity} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web.PassengerQuantity, 1));
};


/**
 * @param {?proto.airplane.web.PassengerQuantity|undefined} value
 * @return {!proto.airplane.web.SearchFlightFilterReq} returns this
*/
proto.airplane.web.SearchFlightFilterReq.prototype.setPassengerQuantity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.SearchFlightFilterReq} returns this
 */
proto.airplane.web.SearchFlightFilterReq.prototype.clearPassengerQuantity = function() {
  return this.setPassengerQuantity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.SearchFlightFilterReq.prototype.hasPassengerQuantity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated FlightReq flights = 2;
 * @return {!Array<!proto.airplane.web.FlightReq>}
 */
proto.airplane.web.SearchFlightFilterReq.prototype.getFlightsList = function() {
  return /** @type{!Array<!proto.airplane.web.FlightReq>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web.FlightReq, 2));
};


/**
 * @param {!Array<!proto.airplane.web.FlightReq>} value
 * @return {!proto.airplane.web.SearchFlightFilterReq} returns this
*/
proto.airplane.web.SearchFlightFilterReq.prototype.setFlightsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.airplane.web.FlightReq=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web.FlightReq}
 */
proto.airplane.web.SearchFlightFilterReq.prototype.addFlights = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.airplane.web.FlightReq, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.SearchFlightFilterReq} returns this
 */
proto.airplane.web.SearchFlightFilterReq.prototype.clearFlightsList = function() {
  return this.setFlightsList([]);
};


/**
 * optional string currency = 3;
 * @return {string}
 */
proto.airplane.web.SearchFlightFilterReq.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.SearchFlightFilterReq} returns this
 */
proto.airplane.web.SearchFlightFilterReq.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string external_hidden_fee = 4;
 * @return {string}
 */
proto.airplane.web.SearchFlightFilterReq.prototype.getExternalHiddenFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.SearchFlightFilterReq} returns this
 */
proto.airplane.web.SearchFlightFilterReq.prototype.setExternalHiddenFee = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.FlightReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.FlightReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.FlightReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.FlightReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    startPoint: jspb.Message.getFieldWithDefault(msg, 1, ""),
    endPoint: jspb.Message.getFieldWithDefault(msg, 2, ""),
    departDate: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.FlightReq}
 */
proto.airplane.web.FlightReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.FlightReq;
  return proto.airplane.web.FlightReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.FlightReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.FlightReq}
 */
proto.airplane.web.FlightReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartPoint(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndPoint(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDepartDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.FlightReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.FlightReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.FlightReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.FlightReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartPoint();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEndPoint();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDepartDate();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string start_point = 1;
 * @return {string}
 */
proto.airplane.web.FlightReq.prototype.getStartPoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.FlightReq} returns this
 */
proto.airplane.web.FlightReq.prototype.setStartPoint = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string end_point = 2;
 * @return {string}
 */
proto.airplane.web.FlightReq.prototype.getEndPoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.FlightReq} returns this
 */
proto.airplane.web.FlightReq.prototype.setEndPoint = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 depart_date = 3;
 * @return {number}
 */
proto.airplane.web.FlightReq.prototype.getDepartDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.FlightReq} returns this
 */
proto.airplane.web.FlightReq.prototype.setDepartDate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.SortItem.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.SortItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.SortItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.SortItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    desc: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.SortItem}
 */
proto.airplane.web.SortItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.SortItem;
  return proto.airplane.web.SortItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.SortItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.SortItem}
 */
proto.airplane.web.SortItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDesc(value);
      break;
    case 2:
      var value = /** @type {!proto.airplane.web.SortItemType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.SortItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.SortItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.SortItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.SortItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDesc();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional bool desc = 1;
 * @return {boolean}
 */
proto.airplane.web.SortItem.prototype.getDesc = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web.SortItem} returns this
 */
proto.airplane.web.SortItem.prototype.setDesc = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional SortItemType type = 2;
 * @return {!proto.airplane.web.SortItemType}
 */
proto.airplane.web.SortItem.prototype.getType = function() {
  return /** @type {!proto.airplane.web.SortItemType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.airplane.web.SortItemType} value
 * @return {!proto.airplane.web.SortItem} returns this
 */
proto.airplane.web.SortItem.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.PassengerQuantity.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.PassengerQuantity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.PassengerQuantity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.PassengerQuantity.toObject = function(includeInstance, msg) {
  var f, obj = {
    adt: jspb.Message.getFieldWithDefault(msg, 1, 0),
    chd: jspb.Message.getFieldWithDefault(msg, 2, 0),
    inf: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.PassengerQuantity}
 */
proto.airplane.web.PassengerQuantity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.PassengerQuantity;
  return proto.airplane.web.PassengerQuantity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.PassengerQuantity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.PassengerQuantity}
 */
proto.airplane.web.PassengerQuantity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdt(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setChd(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInf(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.PassengerQuantity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.PassengerQuantity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.PassengerQuantity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.PassengerQuantity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdt();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getChd();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getInf();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 adt = 1;
 * @return {number}
 */
proto.airplane.web.PassengerQuantity.prototype.getAdt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.PassengerQuantity} returns this
 */
proto.airplane.web.PassengerQuantity.prototype.setAdt = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 chd = 2;
 * @return {number}
 */
proto.airplane.web.PassengerQuantity.prototype.getChd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.PassengerQuantity} returns this
 */
proto.airplane.web.PassengerQuantity.prototype.setChd = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 inf = 3;
 * @return {number}
 */
proto.airplane.web.PassengerQuantity.prototype.getInf = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.PassengerQuantity} returns this
 */
proto.airplane.web.PassengerQuantity.prototype.setInf = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web.SearchFlightRes.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.SearchFlightRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.SearchFlightRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.SearchFlightRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.SearchFlightRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.airplane.web.FlightInfo.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f),
    itineraryType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    expiredAt: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.SearchFlightRes}
 */
proto.airplane.web.SearchFlightRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.SearchFlightRes;
  return proto.airplane.web.SearchFlightRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.SearchFlightRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.SearchFlightRes}
 */
proto.airplane.web.SearchFlightRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = new proto.airplane.web.FlightInfo;
      reader.readMessage(value,proto.airplane.web.FlightInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 3:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var value = /** @type {!proto.airplane.FlightItineraryType} */ (reader.readEnum());
      msg.setItineraryType(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiredAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.SearchFlightRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.SearchFlightRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.SearchFlightRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.SearchFlightRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.airplane.web.FlightInfo.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
  f = message.getItineraryType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getExpiredAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.airplane.web.SearchFlightRes.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.SearchFlightRes} returns this
 */
proto.airplane.web.SearchFlightRes.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated FlightInfo items = 2;
 * @return {!Array<!proto.airplane.web.FlightInfo>}
 */
proto.airplane.web.SearchFlightRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.web.FlightInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web.FlightInfo, 2));
};


/**
 * @param {!Array<!proto.airplane.web.FlightInfo>} value
 * @return {!proto.airplane.web.SearchFlightRes} returns this
*/
proto.airplane.web.SearchFlightRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.airplane.web.FlightInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web.FlightInfo}
 */
proto.airplane.web.SearchFlightRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.airplane.web.FlightInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.SearchFlightRes} returns this
 */
proto.airplane.web.SearchFlightRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional base.PaginationRes pagination = 3;
 * @return {?proto.base.PaginationRes}
 */
proto.airplane.web.SearchFlightRes.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 3));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.airplane.web.SearchFlightRes} returns this
*/
proto.airplane.web.SearchFlightRes.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.SearchFlightRes} returns this
 */
proto.airplane.web.SearchFlightRes.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.SearchFlightRes.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional airplane.FlightItineraryType itinerary_type = 4;
 * @return {!proto.airplane.FlightItineraryType}
 */
proto.airplane.web.SearchFlightRes.prototype.getItineraryType = function() {
  return /** @type {!proto.airplane.FlightItineraryType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.airplane.FlightItineraryType} value
 * @return {!proto.airplane.web.SearchFlightRes} returns this
 */
proto.airplane.web.SearchFlightRes.prototype.setItineraryType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional bool is_success = 5;
 * @return {boolean}
 */
proto.airplane.web.SearchFlightRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web.SearchFlightRes} returns this
 */
proto.airplane.web.SearchFlightRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string error_code = 6;
 * @return {string}
 */
proto.airplane.web.SearchFlightRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.SearchFlightRes} returns this
 */
proto.airplane.web.SearchFlightRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 expired_at = 7;
 * @return {number}
 */
proto.airplane.web.SearchFlightRes.prototype.getExpiredAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.SearchFlightRes} returns this
 */
proto.airplane.web.SearchFlightRes.prototype.setExpiredAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web.FlightInfo.repeatedFields_ = [13,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.FlightInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.FlightInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.FlightInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.FlightInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    itineraryKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    stopNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    departPlace: jspb.Message.getFieldWithDefault(msg, 3, ""),
    departDate: jspb.Message.getFieldWithDefault(msg, 4, 0),
    arrivalPlace: jspb.Message.getFieldWithDefault(msg, 5, ""),
    arrivalDate: jspb.Message.getFieldWithDefault(msg, 6, 0),
    carrierMarketing: jspb.Message.getFieldWithDefault(msg, 7, ""),
    carrierOperator: jspb.Message.getFieldWithDefault(msg, 8, ""),
    flightNumber: jspb.Message.getFieldWithDefault(msg, 9, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 10, ""),
    isFirstFlight: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    flightDuration: jspb.Message.getFieldWithDefault(msg, 12, 0),
    segmentsList: jspb.Message.toObjectList(msg.getSegmentsList(),
    proto.airplane.web.Segment.toObject, includeInstance),
    classesList: jspb.Message.toObjectList(msg.getClassesList(),
    proto.airplane.web.FlightClass.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.FlightInfo}
 */
proto.airplane.web.FlightInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.FlightInfo;
  return proto.airplane.web.FlightInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.FlightInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.FlightInfo}
 */
proto.airplane.web.FlightInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setItineraryKey(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStopNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartPlace(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDepartDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrivalPlace(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setArrivalDate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarrierMarketing(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarrierOperator(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightNumber(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFirstFlight(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlightDuration(value);
      break;
    case 13:
      var value = new proto.airplane.web.Segment;
      reader.readMessage(value,proto.airplane.web.Segment.deserializeBinaryFromReader);
      msg.addSegments(value);
      break;
    case 14:
      var value = new proto.airplane.web.FlightClass;
      reader.readMessage(value,proto.airplane.web.FlightClass.deserializeBinaryFromReader);
      msg.addClasses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.FlightInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.FlightInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.FlightInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.FlightInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItineraryKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStopNumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDepartPlace();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDepartDate();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getArrivalPlace();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getArrivalDate();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getCarrierMarketing();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCarrierOperator();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFlightNumber();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getIsFirstFlight();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getFlightDuration();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getSegmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.airplane.web.Segment.serializeBinaryToWriter
    );
  }
  f = message.getClassesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.airplane.web.FlightClass.serializeBinaryToWriter
    );
  }
};


/**
 * optional string itinerary_key = 1;
 * @return {string}
 */
proto.airplane.web.FlightInfo.prototype.getItineraryKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.FlightInfo} returns this
 */
proto.airplane.web.FlightInfo.prototype.setItineraryKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 stop_number = 2;
 * @return {number}
 */
proto.airplane.web.FlightInfo.prototype.getStopNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.FlightInfo} returns this
 */
proto.airplane.web.FlightInfo.prototype.setStopNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string depart_place = 3;
 * @return {string}
 */
proto.airplane.web.FlightInfo.prototype.getDepartPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.FlightInfo} returns this
 */
proto.airplane.web.FlightInfo.prototype.setDepartPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 depart_date = 4;
 * @return {number}
 */
proto.airplane.web.FlightInfo.prototype.getDepartDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.FlightInfo} returns this
 */
proto.airplane.web.FlightInfo.prototype.setDepartDate = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string arrival_place = 5;
 * @return {string}
 */
proto.airplane.web.FlightInfo.prototype.getArrivalPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.FlightInfo} returns this
 */
proto.airplane.web.FlightInfo.prototype.setArrivalPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 arrival_date = 6;
 * @return {number}
 */
proto.airplane.web.FlightInfo.prototype.getArrivalDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.FlightInfo} returns this
 */
proto.airplane.web.FlightInfo.prototype.setArrivalDate = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string carrier_marketing = 7;
 * @return {string}
 */
proto.airplane.web.FlightInfo.prototype.getCarrierMarketing = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.FlightInfo} returns this
 */
proto.airplane.web.FlightInfo.prototype.setCarrierMarketing = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string carrier_operator = 8;
 * @return {string}
 */
proto.airplane.web.FlightInfo.prototype.getCarrierOperator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.FlightInfo} returns this
 */
proto.airplane.web.FlightInfo.prototype.setCarrierOperator = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string flight_number = 9;
 * @return {string}
 */
proto.airplane.web.FlightInfo.prototype.getFlightNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.FlightInfo} returns this
 */
proto.airplane.web.FlightInfo.prototype.setFlightNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string currency = 10;
 * @return {string}
 */
proto.airplane.web.FlightInfo.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.FlightInfo} returns this
 */
proto.airplane.web.FlightInfo.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool is_first_flight = 11;
 * @return {boolean}
 */
proto.airplane.web.FlightInfo.prototype.getIsFirstFlight = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web.FlightInfo} returns this
 */
proto.airplane.web.FlightInfo.prototype.setIsFirstFlight = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional int32 flight_duration = 12;
 * @return {number}
 */
proto.airplane.web.FlightInfo.prototype.getFlightDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.FlightInfo} returns this
 */
proto.airplane.web.FlightInfo.prototype.setFlightDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * repeated Segment segments = 13;
 * @return {!Array<!proto.airplane.web.Segment>}
 */
proto.airplane.web.FlightInfo.prototype.getSegmentsList = function() {
  return /** @type{!Array<!proto.airplane.web.Segment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web.Segment, 13));
};


/**
 * @param {!Array<!proto.airplane.web.Segment>} value
 * @return {!proto.airplane.web.FlightInfo} returns this
*/
proto.airplane.web.FlightInfo.prototype.setSegmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.airplane.web.Segment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web.Segment}
 */
proto.airplane.web.FlightInfo.prototype.addSegments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.airplane.web.Segment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.FlightInfo} returns this
 */
proto.airplane.web.FlightInfo.prototype.clearSegmentsList = function() {
  return this.setSegmentsList([]);
};


/**
 * repeated FlightClass classes = 14;
 * @return {!Array<!proto.airplane.web.FlightClass>}
 */
proto.airplane.web.FlightInfo.prototype.getClassesList = function() {
  return /** @type{!Array<!proto.airplane.web.FlightClass>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web.FlightClass, 14));
};


/**
 * @param {!Array<!proto.airplane.web.FlightClass>} value
 * @return {!proto.airplane.web.FlightInfo} returns this
*/
proto.airplane.web.FlightInfo.prototype.setClassesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.airplane.web.FlightClass=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web.FlightClass}
 */
proto.airplane.web.FlightInfo.prototype.addClasses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.airplane.web.FlightClass, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.FlightInfo} returns this
 */
proto.airplane.web.FlightInfo.prototype.clearClassesList = function() {
  return this.setClassesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.Segment.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.Segment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.Segment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.Segment.toObject = function(includeInstance, msg) {
  var f, obj = {
    index: jspb.Message.getFieldWithDefault(msg, 1, 0),
    departPlace: jspb.Message.getFieldWithDefault(msg, 2, ""),
    departDate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    arrivalPlace: jspb.Message.getFieldWithDefault(msg, 4, ""),
    arrivalDate: jspb.Message.getFieldWithDefault(msg, 5, 0),
    carrierMarketing: jspb.Message.getFieldWithDefault(msg, 6, ""),
    carrierOperator: jspb.Message.getFieldWithDefault(msg, 7, ""),
    flightNumber: jspb.Message.getFieldWithDefault(msg, 8, ""),
    aircraft: jspb.Message.getFieldWithDefault(msg, 9, ""),
    flightDuration: jspb.Message.getFieldWithDefault(msg, 10, 0),
    departTerminal: jspb.Message.getFieldWithDefault(msg, 11, ""),
    arrivalTerminal: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.Segment}
 */
proto.airplane.web.Segment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.Segment;
  return proto.airplane.web.Segment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.Segment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.Segment}
 */
proto.airplane.web.Segment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndex(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartPlace(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDepartDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrivalPlace(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setArrivalDate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarrierMarketing(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarrierOperator(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightNumber(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAircraft(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlightDuration(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartTerminal(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrivalTerminal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.Segment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.Segment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.Segment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.Segment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndex();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDepartPlace();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDepartDate();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getArrivalPlace();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getArrivalDate();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getCarrierMarketing();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCarrierOperator();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getFlightNumber();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAircraft();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getFlightDuration();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getDepartTerminal();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getArrivalTerminal();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional int32 index = 1;
 * @return {number}
 */
proto.airplane.web.Segment.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.Segment} returns this
 */
proto.airplane.web.Segment.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string depart_place = 2;
 * @return {string}
 */
proto.airplane.web.Segment.prototype.getDepartPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.Segment} returns this
 */
proto.airplane.web.Segment.prototype.setDepartPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 depart_date = 3;
 * @return {number}
 */
proto.airplane.web.Segment.prototype.getDepartDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.Segment} returns this
 */
proto.airplane.web.Segment.prototype.setDepartDate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string arrival_place = 4;
 * @return {string}
 */
proto.airplane.web.Segment.prototype.getArrivalPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.Segment} returns this
 */
proto.airplane.web.Segment.prototype.setArrivalPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 arrival_date = 5;
 * @return {number}
 */
proto.airplane.web.Segment.prototype.getArrivalDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.Segment} returns this
 */
proto.airplane.web.Segment.prototype.setArrivalDate = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string carrier_marketing = 6;
 * @return {string}
 */
proto.airplane.web.Segment.prototype.getCarrierMarketing = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.Segment} returns this
 */
proto.airplane.web.Segment.prototype.setCarrierMarketing = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string carrier_operator = 7;
 * @return {string}
 */
proto.airplane.web.Segment.prototype.getCarrierOperator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.Segment} returns this
 */
proto.airplane.web.Segment.prototype.setCarrierOperator = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string flight_number = 8;
 * @return {string}
 */
proto.airplane.web.Segment.prototype.getFlightNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.Segment} returns this
 */
proto.airplane.web.Segment.prototype.setFlightNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string aircraft = 9;
 * @return {string}
 */
proto.airplane.web.Segment.prototype.getAircraft = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.Segment} returns this
 */
proto.airplane.web.Segment.prototype.setAircraft = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 flight_duration = 10;
 * @return {number}
 */
proto.airplane.web.Segment.prototype.getFlightDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.Segment} returns this
 */
proto.airplane.web.Segment.prototype.setFlightDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string depart_terminal = 11;
 * @return {string}
 */
proto.airplane.web.Segment.prototype.getDepartTerminal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.Segment} returns this
 */
proto.airplane.web.Segment.prototype.setDepartTerminal = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string arrival_terminal = 12;
 * @return {string}
 */
proto.airplane.web.Segment.prototype.getArrivalTerminal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.Segment} returns this
 */
proto.airplane.web.Segment.prototype.setArrivalTerminal = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web.FlightClass.repeatedFields_ = [14,17,18];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.FlightClass.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.FlightClass.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.FlightClass} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.FlightClass.toObject = function(includeInstance, msg) {
  var f, obj = {
    flightId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itineraryId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    availability: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cabinClass: jspb.Message.getFieldWithDefault(msg, 4, ""),
    bookingClass: jspb.Message.getFieldWithDefault(msg, 5, ""),
    fareBasis: jspb.Message.getFieldWithDefault(msg, 6, ""),
    totalFareAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    totalFareBasic: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    totalTaxAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    totalPaxFareAdt: (f = msg.getTotalPaxFareAdt()) && proto.airplane.web.PaxFare.toObject(includeInstance, f),
    totalPaxFareChd: (f = msg.getTotalPaxFareChd()) && proto.airplane.web.PaxFare.toObject(includeInstance, f),
    totalPaxFareInf: (f = msg.getTotalPaxFareInf()) && proto.airplane.web.PaxFare.toObject(includeInstance, f),
    vat: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    nextItineraryKeyList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
    displayPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    displayProfit: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    flightsList: jspb.Message.toObjectList(msg.getFlightsList(),
    proto.airplane.web.FlightClass.FlightData.toObject, includeInstance),
    freeBaggageList: jspb.Message.toObjectList(msg.getFreeBaggageList(),
    airplane_hub_pb.HubBaggage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.FlightClass}
 */
proto.airplane.web.FlightClass.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.FlightClass;
  return proto.airplane.web.FlightClass.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.FlightClass} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.FlightClass}
 */
proto.airplane.web.FlightClass.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setItineraryId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvailability(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCabinClass(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingClass(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFareBasis(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalFareAmount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalFareBasic(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalTaxAmount(value);
      break;
    case 10:
      var value = new proto.airplane.web.PaxFare;
      reader.readMessage(value,proto.airplane.web.PaxFare.deserializeBinaryFromReader);
      msg.setTotalPaxFareAdt(value);
      break;
    case 11:
      var value = new proto.airplane.web.PaxFare;
      reader.readMessage(value,proto.airplane.web.PaxFare.deserializeBinaryFromReader);
      msg.setTotalPaxFareChd(value);
      break;
    case 12:
      var value = new proto.airplane.web.PaxFare;
      reader.readMessage(value,proto.airplane.web.PaxFare.deserializeBinaryFromReader);
      msg.setTotalPaxFareInf(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVat(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.addNextItineraryKey(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDisplayPrice(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDisplayProfit(value);
      break;
    case 17:
      var value = new proto.airplane.web.FlightClass.FlightData;
      reader.readMessage(value,proto.airplane.web.FlightClass.FlightData.deserializeBinaryFromReader);
      msg.addFlights(value);
      break;
    case 18:
      var value = new airplane_hub_pb.HubBaggage;
      reader.readMessage(value,airplane_hub_pb.HubBaggage.deserializeBinaryFromReader);
      msg.addFreeBaggage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.FlightClass.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.FlightClass.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.FlightClass} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.FlightClass.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItineraryId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAvailability();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCabinClass();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBookingClass();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFareBasis();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTotalFareAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getTotalFareBasic();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getTotalTaxAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getTotalPaxFareAdt();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.airplane.web.PaxFare.serializeBinaryToWriter
    );
  }
  f = message.getTotalPaxFareChd();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.airplane.web.PaxFare.serializeBinaryToWriter
    );
  }
  f = message.getTotalPaxFareInf();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.airplane.web.PaxFare.serializeBinaryToWriter
    );
  }
  f = message.getVat();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getNextItineraryKeyList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      14,
      f
    );
  }
  f = message.getDisplayPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
  f = message.getDisplayProfit();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
  f = message.getFlightsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.airplane.web.FlightClass.FlightData.serializeBinaryToWriter
    );
  }
  f = message.getFreeBaggageList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      airplane_hub_pb.HubBaggage.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.FlightClass.FlightData.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.FlightClass.FlightData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.FlightClass.FlightData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.FlightClass.FlightData.toObject = function(includeInstance, msg) {
  var f, obj = {
    flightId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    prevItineraryClass: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itineraryClass: jspb.Message.getFieldWithDefault(msg, 3, ""),
    totalFareAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    totalFareBasic: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    totalTaxAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    totalPaxFareAdt: (f = msg.getTotalPaxFareAdt()) && proto.airplane.web.PaxFare.toObject(includeInstance, f),
    totalPaxFareChd: (f = msg.getTotalPaxFareChd()) && proto.airplane.web.PaxFare.toObject(includeInstance, f),
    totalPaxFareInf: (f = msg.getTotalPaxFareInf()) && proto.airplane.web.PaxFare.toObject(includeInstance, f),
    vat: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    displayPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    displayProfit: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    itineraryIndex: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.FlightClass.FlightData}
 */
proto.airplane.web.FlightClass.FlightData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.FlightClass.FlightData;
  return proto.airplane.web.FlightClass.FlightData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.FlightClass.FlightData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.FlightClass.FlightData}
 */
proto.airplane.web.FlightClass.FlightData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrevItineraryClass(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setItineraryClass(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalFareAmount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalFareBasic(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalTaxAmount(value);
      break;
    case 7:
      var value = new proto.airplane.web.PaxFare;
      reader.readMessage(value,proto.airplane.web.PaxFare.deserializeBinaryFromReader);
      msg.setTotalPaxFareAdt(value);
      break;
    case 8:
      var value = new proto.airplane.web.PaxFare;
      reader.readMessage(value,proto.airplane.web.PaxFare.deserializeBinaryFromReader);
      msg.setTotalPaxFareChd(value);
      break;
    case 9:
      var value = new proto.airplane.web.PaxFare;
      reader.readMessage(value,proto.airplane.web.PaxFare.deserializeBinaryFromReader);
      msg.setTotalPaxFareInf(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVat(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDisplayPrice(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDisplayProfit(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setItineraryIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.FlightClass.FlightData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.FlightClass.FlightData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.FlightClass.FlightData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.FlightClass.FlightData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrevItineraryClass();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItineraryClass();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTotalFareAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getTotalFareBasic();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getTotalTaxAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getTotalPaxFareAdt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.airplane.web.PaxFare.serializeBinaryToWriter
    );
  }
  f = message.getTotalPaxFareChd();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.airplane.web.PaxFare.serializeBinaryToWriter
    );
  }
  f = message.getTotalPaxFareInf();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.airplane.web.PaxFare.serializeBinaryToWriter
    );
  }
  f = message.getVat();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getDisplayPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getDisplayProfit();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getItineraryIndex();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
};


/**
 * optional string flight_id = 1;
 * @return {string}
 */
proto.airplane.web.FlightClass.FlightData.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.FlightClass.FlightData} returns this
 */
proto.airplane.web.FlightClass.FlightData.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string prev_itinerary_class = 2;
 * @return {string}
 */
proto.airplane.web.FlightClass.FlightData.prototype.getPrevItineraryClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.FlightClass.FlightData} returns this
 */
proto.airplane.web.FlightClass.FlightData.prototype.setPrevItineraryClass = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string itinerary_class = 3;
 * @return {string}
 */
proto.airplane.web.FlightClass.FlightData.prototype.getItineraryClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.FlightClass.FlightData} returns this
 */
proto.airplane.web.FlightClass.FlightData.prototype.setItineraryClass = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double total_fare_amount = 4;
 * @return {number}
 */
proto.airplane.web.FlightClass.FlightData.prototype.getTotalFareAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.FlightClass.FlightData} returns this
 */
proto.airplane.web.FlightClass.FlightData.prototype.setTotalFareAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double total_fare_basic = 5;
 * @return {number}
 */
proto.airplane.web.FlightClass.FlightData.prototype.getTotalFareBasic = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.FlightClass.FlightData} returns this
 */
proto.airplane.web.FlightClass.FlightData.prototype.setTotalFareBasic = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double total_tax_amount = 6;
 * @return {number}
 */
proto.airplane.web.FlightClass.FlightData.prototype.getTotalTaxAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.FlightClass.FlightData} returns this
 */
proto.airplane.web.FlightClass.FlightData.prototype.setTotalTaxAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional PaxFare total_pax_fare_adt = 7;
 * @return {?proto.airplane.web.PaxFare}
 */
proto.airplane.web.FlightClass.FlightData.prototype.getTotalPaxFareAdt = function() {
  return /** @type{?proto.airplane.web.PaxFare} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web.PaxFare, 7));
};


/**
 * @param {?proto.airplane.web.PaxFare|undefined} value
 * @return {!proto.airplane.web.FlightClass.FlightData} returns this
*/
proto.airplane.web.FlightClass.FlightData.prototype.setTotalPaxFareAdt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.FlightClass.FlightData} returns this
 */
proto.airplane.web.FlightClass.FlightData.prototype.clearTotalPaxFareAdt = function() {
  return this.setTotalPaxFareAdt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.FlightClass.FlightData.prototype.hasTotalPaxFareAdt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional PaxFare total_pax_fare_chd = 8;
 * @return {?proto.airplane.web.PaxFare}
 */
proto.airplane.web.FlightClass.FlightData.prototype.getTotalPaxFareChd = function() {
  return /** @type{?proto.airplane.web.PaxFare} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web.PaxFare, 8));
};


/**
 * @param {?proto.airplane.web.PaxFare|undefined} value
 * @return {!proto.airplane.web.FlightClass.FlightData} returns this
*/
proto.airplane.web.FlightClass.FlightData.prototype.setTotalPaxFareChd = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.FlightClass.FlightData} returns this
 */
proto.airplane.web.FlightClass.FlightData.prototype.clearTotalPaxFareChd = function() {
  return this.setTotalPaxFareChd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.FlightClass.FlightData.prototype.hasTotalPaxFareChd = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional PaxFare total_pax_fare_inf = 9;
 * @return {?proto.airplane.web.PaxFare}
 */
proto.airplane.web.FlightClass.FlightData.prototype.getTotalPaxFareInf = function() {
  return /** @type{?proto.airplane.web.PaxFare} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web.PaxFare, 9));
};


/**
 * @param {?proto.airplane.web.PaxFare|undefined} value
 * @return {!proto.airplane.web.FlightClass.FlightData} returns this
*/
proto.airplane.web.FlightClass.FlightData.prototype.setTotalPaxFareInf = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.FlightClass.FlightData} returns this
 */
proto.airplane.web.FlightClass.FlightData.prototype.clearTotalPaxFareInf = function() {
  return this.setTotalPaxFareInf(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.FlightClass.FlightData.prototype.hasTotalPaxFareInf = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional double vat = 10;
 * @return {number}
 */
proto.airplane.web.FlightClass.FlightData.prototype.getVat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.FlightClass.FlightData} returns this
 */
proto.airplane.web.FlightClass.FlightData.prototype.setVat = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double display_price = 11;
 * @return {number}
 */
proto.airplane.web.FlightClass.FlightData.prototype.getDisplayPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.FlightClass.FlightData} returns this
 */
proto.airplane.web.FlightClass.FlightData.prototype.setDisplayPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double display_profit = 12;
 * @return {number}
 */
proto.airplane.web.FlightClass.FlightData.prototype.getDisplayProfit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.FlightClass.FlightData} returns this
 */
proto.airplane.web.FlightClass.FlightData.prototype.setDisplayProfit = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional int32 itinerary_index = 13;
 * @return {number}
 */
proto.airplane.web.FlightClass.FlightData.prototype.getItineraryIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.FlightClass.FlightData} returns this
 */
proto.airplane.web.FlightClass.FlightData.prototype.setItineraryIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional string flight_id = 1;
 * @return {string}
 */
proto.airplane.web.FlightClass.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.FlightClass} returns this
 */
proto.airplane.web.FlightClass.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string itinerary_id = 2;
 * @return {string}
 */
proto.airplane.web.FlightClass.prototype.getItineraryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.FlightClass} returns this
 */
proto.airplane.web.FlightClass.prototype.setItineraryId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 availability = 3;
 * @return {number}
 */
proto.airplane.web.FlightClass.prototype.getAvailability = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.FlightClass} returns this
 */
proto.airplane.web.FlightClass.prototype.setAvailability = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string cabin_class = 4;
 * @return {string}
 */
proto.airplane.web.FlightClass.prototype.getCabinClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.FlightClass} returns this
 */
proto.airplane.web.FlightClass.prototype.setCabinClass = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string booking_class = 5;
 * @return {string}
 */
proto.airplane.web.FlightClass.prototype.getBookingClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.FlightClass} returns this
 */
proto.airplane.web.FlightClass.prototype.setBookingClass = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string fare_basis = 6;
 * @return {string}
 */
proto.airplane.web.FlightClass.prototype.getFareBasis = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.FlightClass} returns this
 */
proto.airplane.web.FlightClass.prototype.setFareBasis = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional double total_fare_amount = 7;
 * @return {number}
 */
proto.airplane.web.FlightClass.prototype.getTotalFareAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.FlightClass} returns this
 */
proto.airplane.web.FlightClass.prototype.setTotalFareAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double total_fare_basic = 8;
 * @return {number}
 */
proto.airplane.web.FlightClass.prototype.getTotalFareBasic = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.FlightClass} returns this
 */
proto.airplane.web.FlightClass.prototype.setTotalFareBasic = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double total_tax_amount = 9;
 * @return {number}
 */
proto.airplane.web.FlightClass.prototype.getTotalTaxAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.FlightClass} returns this
 */
proto.airplane.web.FlightClass.prototype.setTotalTaxAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional PaxFare total_pax_fare_adt = 10;
 * @return {?proto.airplane.web.PaxFare}
 */
proto.airplane.web.FlightClass.prototype.getTotalPaxFareAdt = function() {
  return /** @type{?proto.airplane.web.PaxFare} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web.PaxFare, 10));
};


/**
 * @param {?proto.airplane.web.PaxFare|undefined} value
 * @return {!proto.airplane.web.FlightClass} returns this
*/
proto.airplane.web.FlightClass.prototype.setTotalPaxFareAdt = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.FlightClass} returns this
 */
proto.airplane.web.FlightClass.prototype.clearTotalPaxFareAdt = function() {
  return this.setTotalPaxFareAdt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.FlightClass.prototype.hasTotalPaxFareAdt = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional PaxFare total_pax_fare_chd = 11;
 * @return {?proto.airplane.web.PaxFare}
 */
proto.airplane.web.FlightClass.prototype.getTotalPaxFareChd = function() {
  return /** @type{?proto.airplane.web.PaxFare} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web.PaxFare, 11));
};


/**
 * @param {?proto.airplane.web.PaxFare|undefined} value
 * @return {!proto.airplane.web.FlightClass} returns this
*/
proto.airplane.web.FlightClass.prototype.setTotalPaxFareChd = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.FlightClass} returns this
 */
proto.airplane.web.FlightClass.prototype.clearTotalPaxFareChd = function() {
  return this.setTotalPaxFareChd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.FlightClass.prototype.hasTotalPaxFareChd = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional PaxFare total_pax_fare_inf = 12;
 * @return {?proto.airplane.web.PaxFare}
 */
proto.airplane.web.FlightClass.prototype.getTotalPaxFareInf = function() {
  return /** @type{?proto.airplane.web.PaxFare} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web.PaxFare, 12));
};


/**
 * @param {?proto.airplane.web.PaxFare|undefined} value
 * @return {!proto.airplane.web.FlightClass} returns this
*/
proto.airplane.web.FlightClass.prototype.setTotalPaxFareInf = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.FlightClass} returns this
 */
proto.airplane.web.FlightClass.prototype.clearTotalPaxFareInf = function() {
  return this.setTotalPaxFareInf(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.FlightClass.prototype.hasTotalPaxFareInf = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional double vat = 13;
 * @return {number}
 */
proto.airplane.web.FlightClass.prototype.getVat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.FlightClass} returns this
 */
proto.airplane.web.FlightClass.prototype.setVat = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * repeated string next_itinerary_key = 14;
 * @return {!Array<string>}
 */
proto.airplane.web.FlightClass.prototype.getNextItineraryKeyList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 14));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.airplane.web.FlightClass} returns this
 */
proto.airplane.web.FlightClass.prototype.setNextItineraryKeyList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.airplane.web.FlightClass} returns this
 */
proto.airplane.web.FlightClass.prototype.addNextItineraryKey = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.FlightClass} returns this
 */
proto.airplane.web.FlightClass.prototype.clearNextItineraryKeyList = function() {
  return this.setNextItineraryKeyList([]);
};


/**
 * optional double display_price = 15;
 * @return {number}
 */
proto.airplane.web.FlightClass.prototype.getDisplayPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.FlightClass} returns this
 */
proto.airplane.web.FlightClass.prototype.setDisplayPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional double display_profit = 16;
 * @return {number}
 */
proto.airplane.web.FlightClass.prototype.getDisplayProfit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.FlightClass} returns this
 */
proto.airplane.web.FlightClass.prototype.setDisplayProfit = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * repeated FlightData flights = 17;
 * @return {!Array<!proto.airplane.web.FlightClass.FlightData>}
 */
proto.airplane.web.FlightClass.prototype.getFlightsList = function() {
  return /** @type{!Array<!proto.airplane.web.FlightClass.FlightData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web.FlightClass.FlightData, 17));
};


/**
 * @param {!Array<!proto.airplane.web.FlightClass.FlightData>} value
 * @return {!proto.airplane.web.FlightClass} returns this
*/
proto.airplane.web.FlightClass.prototype.setFlightsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.airplane.web.FlightClass.FlightData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web.FlightClass.FlightData}
 */
proto.airplane.web.FlightClass.prototype.addFlights = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.airplane.web.FlightClass.FlightData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.FlightClass} returns this
 */
proto.airplane.web.FlightClass.prototype.clearFlightsList = function() {
  return this.setFlightsList([]);
};


/**
 * repeated airplane.HubBaggage free_baggage = 18;
 * @return {!Array<!proto.airplane.HubBaggage>}
 */
proto.airplane.web.FlightClass.prototype.getFreeBaggageList = function() {
  return /** @type{!Array<!proto.airplane.HubBaggage>} */ (
    jspb.Message.getRepeatedWrapperField(this, airplane_hub_pb.HubBaggage, 18));
};


/**
 * @param {!Array<!proto.airplane.HubBaggage>} value
 * @return {!proto.airplane.web.FlightClass} returns this
*/
proto.airplane.web.FlightClass.prototype.setFreeBaggageList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.airplane.HubBaggage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.HubBaggage}
 */
proto.airplane.web.FlightClass.prototype.addFreeBaggage = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.airplane.HubBaggage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.FlightClass} returns this
 */
proto.airplane.web.FlightClass.prototype.clearFreeBaggageList = function() {
  return this.setFreeBaggageList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.PaxFare.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.PaxFare.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.PaxFare} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.PaxFare.toObject = function(includeInstance, msg) {
  var f, obj = {
    paxType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    fareAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    fareBasic: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    taxAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 5, ""),
    serviceFee: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    displayPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    displayProfit: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.PaxFare}
 */
proto.airplane.web.PaxFare.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.PaxFare;
  return proto.airplane.web.PaxFare.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.PaxFare} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.PaxFare}
 */
proto.airplane.web.PaxFare.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.airplane.PaxType} */ (reader.readEnum());
      msg.setPaxType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFareAmount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFareBasic(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTaxAmount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setServiceFee(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDisplayPrice(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDisplayProfit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.PaxFare.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.PaxFare.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.PaxFare} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.PaxFare.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaxType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getFareAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getFareBasic();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getTaxAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getServiceFee();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getDisplayPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getDisplayProfit();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
};


/**
 * optional airplane.PaxType pax_type = 1;
 * @return {!proto.airplane.PaxType}
 */
proto.airplane.web.PaxFare.prototype.getPaxType = function() {
  return /** @type {!proto.airplane.PaxType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.airplane.PaxType} value
 * @return {!proto.airplane.web.PaxFare} returns this
 */
proto.airplane.web.PaxFare.prototype.setPaxType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional double fare_amount = 2;
 * @return {number}
 */
proto.airplane.web.PaxFare.prototype.getFareAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.PaxFare} returns this
 */
proto.airplane.web.PaxFare.prototype.setFareAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double fare_basic = 3;
 * @return {number}
 */
proto.airplane.web.PaxFare.prototype.getFareBasic = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.PaxFare} returns this
 */
proto.airplane.web.PaxFare.prototype.setFareBasic = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double tax_amount = 4;
 * @return {number}
 */
proto.airplane.web.PaxFare.prototype.getTaxAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.PaxFare} returns this
 */
proto.airplane.web.PaxFare.prototype.setTaxAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string currency = 5;
 * @return {string}
 */
proto.airplane.web.PaxFare.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.PaxFare} returns this
 */
proto.airplane.web.PaxFare.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional double service_fee = 6;
 * @return {number}
 */
proto.airplane.web.PaxFare.prototype.getServiceFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.PaxFare} returns this
 */
proto.airplane.web.PaxFare.prototype.setServiceFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double display_price = 7;
 * @return {number}
 */
proto.airplane.web.PaxFare.prototype.getDisplayPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.PaxFare} returns this
 */
proto.airplane.web.PaxFare.prototype.setDisplayPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double display_profit = 8;
 * @return {number}
 */
proto.airplane.web.PaxFare.prototype.getDisplayProfit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.PaxFare} returns this
 */
proto.airplane.web.PaxFare.prototype.setDisplayProfit = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.AirportInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.AirportInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.AirportInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.AirportInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    country: jspb.Message.getFieldWithDefault(msg, 2, ""),
    countryCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    city: jspb.Message.getFieldWithDefault(msg, 4, ""),
    cityCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    code: jspb.Message.getFieldWithDefault(msg, 6, ""),
    name: jspb.Message.getFieldWithDefault(msg, 7, ""),
    lat: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    lon: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    timezone: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.AirportInfo}
 */
proto.airplane.web.AirportInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.AirportInfo;
  return proto.airplane.web.AirportInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.AirportInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.AirportInfo}
 */
proto.airplane.web.AirportInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCityCode(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLat(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLon(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.AirportInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.AirportInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.AirportInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.AirportInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCountryCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCityCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getLon();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.airplane.web.AirportInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.AirportInfo} returns this
 */
proto.airplane.web.AirportInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string country = 2;
 * @return {string}
 */
proto.airplane.web.AirportInfo.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.AirportInfo} returns this
 */
proto.airplane.web.AirportInfo.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string country_code = 3;
 * @return {string}
 */
proto.airplane.web.AirportInfo.prototype.getCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.AirportInfo} returns this
 */
proto.airplane.web.AirportInfo.prototype.setCountryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string city = 4;
 * @return {string}
 */
proto.airplane.web.AirportInfo.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.AirportInfo} returns this
 */
proto.airplane.web.AirportInfo.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city_code = 5;
 * @return {string}
 */
proto.airplane.web.AirportInfo.prototype.getCityCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.AirportInfo} returns this
 */
proto.airplane.web.AirportInfo.prototype.setCityCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string code = 6;
 * @return {string}
 */
proto.airplane.web.AirportInfo.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.AirportInfo} returns this
 */
proto.airplane.web.AirportInfo.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string name = 7;
 * @return {string}
 */
proto.airplane.web.AirportInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.AirportInfo} returns this
 */
proto.airplane.web.AirportInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional float lat = 8;
 * @return {number}
 */
proto.airplane.web.AirportInfo.prototype.getLat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.AirportInfo} returns this
 */
proto.airplane.web.AirportInfo.prototype.setLat = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float lon = 9;
 * @return {number}
 */
proto.airplane.web.AirportInfo.prototype.getLon = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.AirportInfo} returns this
 */
proto.airplane.web.AirportInfo.prototype.setLon = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional string timezone = 10;
 * @return {string}
 */
proto.airplane.web.AirportInfo.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.AirportInfo} returns this
 */
proto.airplane.web.AirportInfo.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.SearchAirportsFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.SearchAirportsFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.SearchAirportsFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.SearchAirportsFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchText: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.SearchAirportsFilter}
 */
proto.airplane.web.SearchAirportsFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.SearchAirportsFilter;
  return proto.airplane.web.SearchAirportsFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.SearchAirportsFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.SearchAirportsFilter}
 */
proto.airplane.web.SearchAirportsFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.SearchAirportsFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.SearchAirportsFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.SearchAirportsFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.SearchAirportsFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string search_text = 1;
 * @return {string}
 */
proto.airplane.web.SearchAirportsFilter.prototype.getSearchText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.SearchAirportsFilter} returns this
 */
proto.airplane.web.SearchAirportsFilter.prototype.setSearchText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web.SearchAirportsReq.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.SearchAirportsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.SearchAirportsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.SearchAirportsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.SearchAirportsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.airplane.web.SearchAirportsFilter.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f),
    orderByList: jspb.Message.toObjectList(msg.getOrderByList(),
    base_base_pb.OrderByItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.SearchAirportsReq}
 */
proto.airplane.web.SearchAirportsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.SearchAirportsReq;
  return proto.airplane.web.SearchAirportsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.SearchAirportsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.SearchAirportsReq}
 */
proto.airplane.web.SearchAirportsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web.SearchAirportsFilter;
      reader.readMessage(value,proto.airplane.web.SearchAirportsFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var value = new base_base_pb.OrderByItem;
      reader.readMessage(value,base_base_pb.OrderByItem.deserializeBinaryFromReader);
      msg.addOrderBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.SearchAirportsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.SearchAirportsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.SearchAirportsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.SearchAirportsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.airplane.web.SearchAirportsFilter.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
  f = message.getOrderByList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      base_base_pb.OrderByItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional SearchAirportsFilter filter = 1;
 * @return {?proto.airplane.web.SearchAirportsFilter}
 */
proto.airplane.web.SearchAirportsReq.prototype.getFilter = function() {
  return /** @type{?proto.airplane.web.SearchAirportsFilter} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web.SearchAirportsFilter, 1));
};


/**
 * @param {?proto.airplane.web.SearchAirportsFilter|undefined} value
 * @return {!proto.airplane.web.SearchAirportsReq} returns this
*/
proto.airplane.web.SearchAirportsReq.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.SearchAirportsReq} returns this
 */
proto.airplane.web.SearchAirportsReq.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.SearchAirportsReq.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional base.PaginationReq pagination = 2;
 * @return {?proto.base.PaginationReq}
 */
proto.airplane.web.SearchAirportsReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 2));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.airplane.web.SearchAirportsReq} returns this
*/
proto.airplane.web.SearchAirportsReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.SearchAirportsReq} returns this
 */
proto.airplane.web.SearchAirportsReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.SearchAirportsReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated base.OrderByItem order_by = 3;
 * @return {!Array<!proto.base.OrderByItem>}
 */
proto.airplane.web.SearchAirportsReq.prototype.getOrderByList = function() {
  return /** @type{!Array<!proto.base.OrderByItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, base_base_pb.OrderByItem, 3));
};


/**
 * @param {!Array<!proto.base.OrderByItem>} value
 * @return {!proto.airplane.web.SearchAirportsReq} returns this
*/
proto.airplane.web.SearchAirportsReq.prototype.setOrderByList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.base.OrderByItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.base.OrderByItem}
 */
proto.airplane.web.SearchAirportsReq.prototype.addOrderBy = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.base.OrderByItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.SearchAirportsReq} returns this
 */
proto.airplane.web.SearchAirportsReq.prototype.clearOrderByList = function() {
  return this.setOrderByList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web.SearchAirportsRes.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.SearchAirportsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.SearchAirportsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.SearchAirportsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.SearchAirportsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    recentAirportsList: jspb.Message.toObjectList(msg.getRecentAirportsList(),
    proto.airplane.web.AirportInfo.toObject, includeInstance),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.airplane.web.AirportInfo.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.SearchAirportsRes}
 */
proto.airplane.web.SearchAirportsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.SearchAirportsRes;
  return proto.airplane.web.SearchAirportsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.SearchAirportsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.SearchAirportsRes}
 */
proto.airplane.web.SearchAirportsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web.AirportInfo;
      reader.readMessage(value,proto.airplane.web.AirportInfo.deserializeBinaryFromReader);
      msg.addRecentAirports(value);
      break;
    case 2:
      var value = new proto.airplane.web.AirportInfo;
      reader.readMessage(value,proto.airplane.web.AirportInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 3:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.SearchAirportsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.SearchAirportsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.SearchAirportsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.SearchAirportsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecentAirportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.airplane.web.AirportInfo.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.airplane.web.AirportInfo.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AirportInfo recent_airports = 1;
 * @return {!Array<!proto.airplane.web.AirportInfo>}
 */
proto.airplane.web.SearchAirportsRes.prototype.getRecentAirportsList = function() {
  return /** @type{!Array<!proto.airplane.web.AirportInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web.AirportInfo, 1));
};


/**
 * @param {!Array<!proto.airplane.web.AirportInfo>} value
 * @return {!proto.airplane.web.SearchAirportsRes} returns this
*/
proto.airplane.web.SearchAirportsRes.prototype.setRecentAirportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.airplane.web.AirportInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web.AirportInfo}
 */
proto.airplane.web.SearchAirportsRes.prototype.addRecentAirports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.airplane.web.AirportInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.SearchAirportsRes} returns this
 */
proto.airplane.web.SearchAirportsRes.prototype.clearRecentAirportsList = function() {
  return this.setRecentAirportsList([]);
};


/**
 * repeated AirportInfo items = 2;
 * @return {!Array<!proto.airplane.web.AirportInfo>}
 */
proto.airplane.web.SearchAirportsRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.web.AirportInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web.AirportInfo, 2));
};


/**
 * @param {!Array<!proto.airplane.web.AirportInfo>} value
 * @return {!proto.airplane.web.SearchAirportsRes} returns this
*/
proto.airplane.web.SearchAirportsRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.airplane.web.AirportInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web.AirportInfo}
 */
proto.airplane.web.SearchAirportsRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.airplane.web.AirportInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.SearchAirportsRes} returns this
 */
proto.airplane.web.SearchAirportsRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional base.PaginationRes pagination = 3;
 * @return {?proto.base.PaginationRes}
 */
proto.airplane.web.SearchAirportsRes.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 3));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.airplane.web.SearchAirportsRes} returns this
*/
proto.airplane.web.SearchAirportsRes.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.SearchAirportsRes} returns this
 */
proto.airplane.web.SearchAirportsRes.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.SearchAirportsRes.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web.GetAirportsReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.GetAirportsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.GetAirportsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.GetAirportsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.GetAirportsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    codesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.GetAirportsReq}
 */
proto.airplane.web.GetAirportsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.GetAirportsReq;
  return proto.airplane.web.GetAirportsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.GetAirportsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.GetAirportsReq}
 */
proto.airplane.web.GetAirportsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addCodes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.GetAirportsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.GetAirportsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.GetAirportsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.GetAirportsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCodesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string codes = 1;
 * @return {!Array<string>}
 */
proto.airplane.web.GetAirportsReq.prototype.getCodesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.airplane.web.GetAirportsReq} returns this
 */
proto.airplane.web.GetAirportsReq.prototype.setCodesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.airplane.web.GetAirportsReq} returns this
 */
proto.airplane.web.GetAirportsReq.prototype.addCodes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.GetAirportsReq} returns this
 */
proto.airplane.web.GetAirportsReq.prototype.clearCodesList = function() {
  return this.setCodesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web.GetAirportsRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.GetAirportsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.GetAirportsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.GetAirportsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.GetAirportsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.airplane.web.AirportInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.GetAirportsRes}
 */
proto.airplane.web.GetAirportsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.GetAirportsRes;
  return proto.airplane.web.GetAirportsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.GetAirportsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.GetAirportsRes}
 */
proto.airplane.web.GetAirportsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web.AirportInfo;
      reader.readMessage(value,proto.airplane.web.AirportInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.GetAirportsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.GetAirportsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.GetAirportsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.GetAirportsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.airplane.web.AirportInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AirportInfo items = 1;
 * @return {!Array<!proto.airplane.web.AirportInfo>}
 */
proto.airplane.web.GetAirportsRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.web.AirportInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web.AirportInfo, 1));
};


/**
 * @param {!Array<!proto.airplane.web.AirportInfo>} value
 * @return {!proto.airplane.web.GetAirportsRes} returns this
*/
proto.airplane.web.GetAirportsRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.airplane.web.AirportInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web.AirportInfo}
 */
proto.airplane.web.GetAirportsRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.airplane.web.AirportInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.GetAirportsRes} returns this
 */
proto.airplane.web.GetAirportsRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.Routing.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.Routing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.Routing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.Routing.toObject = function(includeInstance, msg) {
  var f, obj = {
    departPlace: jspb.Message.getFieldWithDefault(msg, 1, ""),
    departDate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    arrivalPlace: jspb.Message.getFieldWithDefault(msg, 3, ""),
    arrivalDate: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.Routing}
 */
proto.airplane.web.Routing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.Routing;
  return proto.airplane.web.Routing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.Routing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.Routing}
 */
proto.airplane.web.Routing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartPlace(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDepartDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrivalPlace(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setArrivalDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.Routing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.Routing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.Routing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.Routing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDepartPlace();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDepartDate();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getArrivalPlace();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getArrivalDate();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string depart_place = 1;
 * @return {string}
 */
proto.airplane.web.Routing.prototype.getDepartPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.Routing} returns this
 */
proto.airplane.web.Routing.prototype.setDepartPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 depart_date = 2;
 * @return {number}
 */
proto.airplane.web.Routing.prototype.getDepartDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.Routing} returns this
 */
proto.airplane.web.Routing.prototype.setDepartDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string arrival_place = 3;
 * @return {string}
 */
proto.airplane.web.Routing.prototype.getArrivalPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.Routing} returns this
 */
proto.airplane.web.Routing.prototype.setArrivalPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 arrival_date = 4;
 * @return {number}
 */
proto.airplane.web.Routing.prototype.getArrivalDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.Routing} returns this
 */
proto.airplane.web.Routing.prototype.setArrivalDate = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.FareCheckReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.FareCheckReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.FareCheckReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.FareCheckReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    flightId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.FareCheckReq}
 */
proto.airplane.web.FareCheckReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.FareCheckReq;
  return proto.airplane.web.FareCheckReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.FareCheckReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.FareCheckReq}
 */
proto.airplane.web.FareCheckReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.FareCheckReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.FareCheckReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.FareCheckReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.FareCheckReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.airplane.web.FareCheckReq.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.FareCheckReq} returns this
 */
proto.airplane.web.FareCheckReq.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string flight_id = 2;
 * @return {string}
 */
proto.airplane.web.FareCheckReq.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.FareCheckReq} returns this
 */
proto.airplane.web.FareCheckReq.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web.FareCheckV2Req.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.FareCheckV2Req.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.FareCheckV2Req.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.FareCheckV2Req} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.FareCheckV2Req.toObject = function(includeInstance, msg) {
  var f, obj = {
    flightPairsList: jspb.Message.toObjectList(msg.getFlightPairsList(),
    proto.airplane.web.FlightPair.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.FareCheckV2Req}
 */
proto.airplane.web.FareCheckV2Req.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.FareCheckV2Req;
  return proto.airplane.web.FareCheckV2Req.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.FareCheckV2Req} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.FareCheckV2Req}
 */
proto.airplane.web.FareCheckV2Req.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web.FlightPair;
      reader.readMessage(value,proto.airplane.web.FlightPair.deserializeBinaryFromReader);
      msg.addFlightPairs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.FareCheckV2Req.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.FareCheckV2Req.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.FareCheckV2Req} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.FareCheckV2Req.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlightPairsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.airplane.web.FlightPair.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FlightPair flight_pairs = 1;
 * @return {!Array<!proto.airplane.web.FlightPair>}
 */
proto.airplane.web.FareCheckV2Req.prototype.getFlightPairsList = function() {
  return /** @type{!Array<!proto.airplane.web.FlightPair>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web.FlightPair, 1));
};


/**
 * @param {!Array<!proto.airplane.web.FlightPair>} value
 * @return {!proto.airplane.web.FareCheckV2Req} returns this
*/
proto.airplane.web.FareCheckV2Req.prototype.setFlightPairsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.airplane.web.FlightPair=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web.FlightPair}
 */
proto.airplane.web.FareCheckV2Req.prototype.addFlightPairs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.airplane.web.FlightPair, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.FareCheckV2Req} returns this
 */
proto.airplane.web.FareCheckV2Req.prototype.clearFlightPairsList = function() {
  return this.setFlightPairsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web.FareData.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.FareData.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.FareData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.FareData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.FareData.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalPaxFaresList: jspb.Message.toObjectList(msg.getTotalPaxFaresList(),
    proto.airplane.web.PaxFare.toObject, includeInstance),
    totalFareAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalFareBasic: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    totalTaxAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 5, ""),
    displayPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.FareData}
 */
proto.airplane.web.FareData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.FareData;
  return proto.airplane.web.FareData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.FareData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.FareData}
 */
proto.airplane.web.FareData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web.PaxFare;
      reader.readMessage(value,proto.airplane.web.PaxFare.deserializeBinaryFromReader);
      msg.addTotalPaxFares(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalFareAmount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalFareBasic(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalTaxAmount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDisplayPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.FareData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.FareData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.FareData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.FareData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalPaxFaresList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.airplane.web.PaxFare.serializeBinaryToWriter
    );
  }
  f = message.getTotalFareAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalFareBasic();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getTotalTaxAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDisplayPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
};


/**
 * repeated PaxFare total_pax_fares = 1;
 * @return {!Array<!proto.airplane.web.PaxFare>}
 */
proto.airplane.web.FareData.prototype.getTotalPaxFaresList = function() {
  return /** @type{!Array<!proto.airplane.web.PaxFare>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web.PaxFare, 1));
};


/**
 * @param {!Array<!proto.airplane.web.PaxFare>} value
 * @return {!proto.airplane.web.FareData} returns this
*/
proto.airplane.web.FareData.prototype.setTotalPaxFaresList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.airplane.web.PaxFare=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web.PaxFare}
 */
proto.airplane.web.FareData.prototype.addTotalPaxFares = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.airplane.web.PaxFare, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.FareData} returns this
 */
proto.airplane.web.FareData.prototype.clearTotalPaxFaresList = function() {
  return this.setTotalPaxFaresList([]);
};


/**
 * optional double total_fare_amount = 2;
 * @return {number}
 */
proto.airplane.web.FareData.prototype.getTotalFareAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.FareData} returns this
 */
proto.airplane.web.FareData.prototype.setTotalFareAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_fare_basic = 3;
 * @return {number}
 */
proto.airplane.web.FareData.prototype.getTotalFareBasic = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.FareData} returns this
 */
proto.airplane.web.FareData.prototype.setTotalFareBasic = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double total_tax_amount = 4;
 * @return {number}
 */
proto.airplane.web.FareData.prototype.getTotalTaxAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.FareData} returns this
 */
proto.airplane.web.FareData.prototype.setTotalTaxAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string currency = 5;
 * @return {string}
 */
proto.airplane.web.FareData.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.FareData} returns this
 */
proto.airplane.web.FareData.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional double display_price = 6;
 * @return {number}
 */
proto.airplane.web.FareData.prototype.getDisplayPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.FareData} returns this
 */
proto.airplane.web.FareData.prototype.setDisplayPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.FareCheckRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.FareCheckRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.FareCheckRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.FareCheckRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.airplane.web.FareCheckRes.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.FareCheckRes}
 */
proto.airplane.web.FareCheckRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.FareCheckRes;
  return proto.airplane.web.FareCheckRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.FareCheckRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.FareCheckRes}
 */
proto.airplane.web.FareCheckRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web.FareCheckRes.Data;
      reader.readMessage(value,proto.airplane.web.FareCheckRes.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.FareCheckRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.FareCheckRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.FareCheckRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.FareCheckRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.airplane.web.FareCheckRes.Data.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web.FareCheckRes.Data.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.FareCheckRes.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.FareCheckRes.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.FareCheckRes.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.FareCheckRes.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itinerariesList: jspb.Message.toObjectList(msg.getItinerariesList(),
    airplane_hub_pb.HubItinerary.toObject, includeInstance),
    fareData: (f = msg.getFareData()) && proto.airplane.web.FareData.toObject(includeInstance, f),
    vat: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    flightType: jspb.Message.getFieldWithDefault(msg, 5, ""),
    tripType: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.FareCheckRes.Data}
 */
proto.airplane.web.FareCheckRes.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.FareCheckRes.Data;
  return proto.airplane.web.FareCheckRes.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.FareCheckRes.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.FareCheckRes.Data}
 */
proto.airplane.web.FareCheckRes.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 2:
      var value = new airplane_hub_pb.HubItinerary;
      reader.readMessage(value,airplane_hub_pb.HubItinerary.deserializeBinaryFromReader);
      msg.addItineraries(value);
      break;
    case 3:
      var value = new proto.airplane.web.FareData;
      reader.readMessage(value,proto.airplane.web.FareData.deserializeBinaryFromReader);
      msg.setFareData(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVat(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTripType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.FareCheckRes.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.FareCheckRes.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.FareCheckRes.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.FareCheckRes.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItinerariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      airplane_hub_pb.HubItinerary.serializeBinaryToWriter
    );
  }
  f = message.getFareData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.airplane.web.FareData.serializeBinaryToWriter
    );
  }
  f = message.getVat();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getFlightType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTripType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.airplane.web.FareCheckRes.Data.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.FareCheckRes.Data} returns this
 */
proto.airplane.web.FareCheckRes.Data.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated airplane.HubItinerary itineraries = 2;
 * @return {!Array<!proto.airplane.HubItinerary>}
 */
proto.airplane.web.FareCheckRes.Data.prototype.getItinerariesList = function() {
  return /** @type{!Array<!proto.airplane.HubItinerary>} */ (
    jspb.Message.getRepeatedWrapperField(this, airplane_hub_pb.HubItinerary, 2));
};


/**
 * @param {!Array<!proto.airplane.HubItinerary>} value
 * @return {!proto.airplane.web.FareCheckRes.Data} returns this
*/
proto.airplane.web.FareCheckRes.Data.prototype.setItinerariesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.airplane.HubItinerary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.HubItinerary}
 */
proto.airplane.web.FareCheckRes.Data.prototype.addItineraries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.airplane.HubItinerary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.FareCheckRes.Data} returns this
 */
proto.airplane.web.FareCheckRes.Data.prototype.clearItinerariesList = function() {
  return this.setItinerariesList([]);
};


/**
 * optional FareData fare_data = 3;
 * @return {?proto.airplane.web.FareData}
 */
proto.airplane.web.FareCheckRes.Data.prototype.getFareData = function() {
  return /** @type{?proto.airplane.web.FareData} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web.FareData, 3));
};


/**
 * @param {?proto.airplane.web.FareData|undefined} value
 * @return {!proto.airplane.web.FareCheckRes.Data} returns this
*/
proto.airplane.web.FareCheckRes.Data.prototype.setFareData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.FareCheckRes.Data} returns this
 */
proto.airplane.web.FareCheckRes.Data.prototype.clearFareData = function() {
  return this.setFareData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.FareCheckRes.Data.prototype.hasFareData = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool vat = 4;
 * @return {boolean}
 */
proto.airplane.web.FareCheckRes.Data.prototype.getVat = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web.FareCheckRes.Data} returns this
 */
proto.airplane.web.FareCheckRes.Data.prototype.setVat = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string flight_type = 5;
 * @return {string}
 */
proto.airplane.web.FareCheckRes.Data.prototype.getFlightType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.FareCheckRes.Data} returns this
 */
proto.airplane.web.FareCheckRes.Data.prototype.setFlightType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string trip_type = 6;
 * @return {string}
 */
proto.airplane.web.FareCheckRes.Data.prototype.getTripType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.FareCheckRes.Data} returns this
 */
proto.airplane.web.FareCheckRes.Data.prototype.setTripType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web.FareCheckRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web.FareCheckRes} returns this
 */
proto.airplane.web.FareCheckRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web.FareCheckRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.FareCheckRes} returns this
 */
proto.airplane.web.FareCheckRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Data data = 3;
 * @return {?proto.airplane.web.FareCheckRes.Data}
 */
proto.airplane.web.FareCheckRes.prototype.getData = function() {
  return /** @type{?proto.airplane.web.FareCheckRes.Data} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web.FareCheckRes.Data, 3));
};


/**
 * @param {?proto.airplane.web.FareCheckRes.Data|undefined} value
 * @return {!proto.airplane.web.FareCheckRes} returns this
*/
proto.airplane.web.FareCheckRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.FareCheckRes} returns this
 */
proto.airplane.web.FareCheckRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.FareCheckRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.SearchMinFareReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.SearchMinFareReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.SearchMinFareReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.SearchMinFareReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    routing: (f = msg.getRouting()) && proto.airplane.web.Routing.toObject(includeInstance, f),
    currency: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.SearchMinFareReq}
 */
proto.airplane.web.SearchMinFareReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.SearchMinFareReq;
  return proto.airplane.web.SearchMinFareReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.SearchMinFareReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.SearchMinFareReq}
 */
proto.airplane.web.SearchMinFareReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web.Routing;
      reader.readMessage(value,proto.airplane.web.Routing.deserializeBinaryFromReader);
      msg.setRouting(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.SearchMinFareReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.SearchMinFareReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.SearchMinFareReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.SearchMinFareReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRouting();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.airplane.web.Routing.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Routing routing = 1;
 * @return {?proto.airplane.web.Routing}
 */
proto.airplane.web.SearchMinFareReq.prototype.getRouting = function() {
  return /** @type{?proto.airplane.web.Routing} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web.Routing, 1));
};


/**
 * @param {?proto.airplane.web.Routing|undefined} value
 * @return {!proto.airplane.web.SearchMinFareReq} returns this
*/
proto.airplane.web.SearchMinFareReq.prototype.setRouting = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.SearchMinFareReq} returns this
 */
proto.airplane.web.SearchMinFareReq.prototype.clearRouting = function() {
  return this.setRouting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.SearchMinFareReq.prototype.hasRouting = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string currency = 2;
 * @return {string}
 */
proto.airplane.web.SearchMinFareReq.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.SearchMinFareReq} returns this
 */
proto.airplane.web.SearchMinFareReq.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web.SearchMinFareRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.SearchMinFareRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.SearchMinFareRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.SearchMinFareRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.SearchMinFareRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.airplane.web.SearchMinFareRes.MinFare.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.SearchMinFareRes}
 */
proto.airplane.web.SearchMinFareRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.SearchMinFareRes;
  return proto.airplane.web.SearchMinFareRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.SearchMinFareRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.SearchMinFareRes}
 */
proto.airplane.web.SearchMinFareRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web.SearchMinFareRes.MinFare;
      reader.readMessage(value,proto.airplane.web.SearchMinFareRes.MinFare.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.SearchMinFareRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.SearchMinFareRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.SearchMinFareRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.SearchMinFareRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.airplane.web.SearchMinFareRes.MinFare.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.SearchMinFareRes.MinFare.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.SearchMinFareRes.MinFare.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.SearchMinFareRes.MinFare} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.SearchMinFareRes.MinFare.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, 0),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    airline: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.SearchMinFareRes.MinFare}
 */
proto.airplane.web.SearchMinFareRes.MinFare.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.SearchMinFareRes.MinFare;
  return proto.airplane.web.SearchMinFareRes.MinFare.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.SearchMinFareRes.MinFare} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.SearchMinFareRes.MinFare}
 */
proto.airplane.web.SearchMinFareRes.MinFare.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAirline(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.SearchMinFareRes.MinFare.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.SearchMinFareRes.MinFare.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.SearchMinFareRes.MinFare} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.SearchMinFareRes.MinFare.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getAirline();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 date = 1;
 * @return {number}
 */
proto.airplane.web.SearchMinFareRes.MinFare.prototype.getDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.SearchMinFareRes.MinFare} returns this
 */
proto.airplane.web.SearchMinFareRes.MinFare.prototype.setDate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double amount = 2;
 * @return {number}
 */
proto.airplane.web.SearchMinFareRes.MinFare.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.SearchMinFareRes.MinFare} returns this
 */
proto.airplane.web.SearchMinFareRes.MinFare.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string airline = 3;
 * @return {string}
 */
proto.airplane.web.SearchMinFareRes.MinFare.prototype.getAirline = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.SearchMinFareRes.MinFare} returns this
 */
proto.airplane.web.SearchMinFareRes.MinFare.prototype.setAirline = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web.SearchMinFareRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web.SearchMinFareRes} returns this
 */
proto.airplane.web.SearchMinFareRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web.SearchMinFareRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.SearchMinFareRes} returns this
 */
proto.airplane.web.SearchMinFareRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated MinFare items = 3;
 * @return {!Array<!proto.airplane.web.SearchMinFareRes.MinFare>}
 */
proto.airplane.web.SearchMinFareRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.web.SearchMinFareRes.MinFare>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web.SearchMinFareRes.MinFare, 3));
};


/**
 * @param {!Array<!proto.airplane.web.SearchMinFareRes.MinFare>} value
 * @return {!proto.airplane.web.SearchMinFareRes} returns this
*/
proto.airplane.web.SearchMinFareRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.web.SearchMinFareRes.MinFare=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web.SearchMinFareRes.MinFare}
 */
proto.airplane.web.SearchMinFareRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.web.SearchMinFareRes.MinFare, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.SearchMinFareRes} returns this
 */
proto.airplane.web.SearchMinFareRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.Passenger.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.Passenger.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.Passenger} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.Passenger.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    gender: jspb.Message.getFieldWithDefault(msg, 4, 0),
    dob: jspb.Message.getFieldWithDefault(msg, 5, 0),
    nationality: jspb.Message.getFieldWithDefault(msg, 6, ""),
    passportNumber: jspb.Message.getFieldWithDefault(msg, 7, ""),
    expirationDate: jspb.Message.getFieldWithDefault(msg, 8, 0),
    idIssueCountry: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.Passenger}
 */
proto.airplane.web.Passenger.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.Passenger;
  return proto.airplane.web.Passenger.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.Passenger} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.Passenger}
 */
proto.airplane.web.Passenger.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 3:
      var value = /** @type {!proto.airplane.PaxType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {!proto.base.GENDER} */ (reader.readEnum());
      msg.setGender(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDob(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNationality(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassportNumber(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpirationDate(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdIssueCountry(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.Passenger.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.Passenger.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.Passenger} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.Passenger.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getGender();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string first_name = 1;
 * @return {string}
 */
proto.airplane.web.Passenger.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.Passenger} returns this
 */
proto.airplane.web.Passenger.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string last_name = 2;
 * @return {string}
 */
proto.airplane.web.Passenger.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.Passenger} returns this
 */
proto.airplane.web.Passenger.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional airplane.PaxType type = 3;
 * @return {!proto.airplane.PaxType}
 */
proto.airplane.web.Passenger.prototype.getType = function() {
  return /** @type {!proto.airplane.PaxType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.airplane.PaxType} value
 * @return {!proto.airplane.web.Passenger} returns this
 */
proto.airplane.web.Passenger.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional base.GENDER gender = 4;
 * @return {!proto.base.GENDER}
 */
proto.airplane.web.Passenger.prototype.getGender = function() {
  return /** @type {!proto.base.GENDER} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.base.GENDER} value
 * @return {!proto.airplane.web.Passenger} returns this
 */
proto.airplane.web.Passenger.prototype.setGender = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int64 dob = 5;
 * @return {number}
 */
proto.airplane.web.Passenger.prototype.getDob = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.Passenger} returns this
 */
proto.airplane.web.Passenger.prototype.setDob = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web.Passenger} returns this
 */
proto.airplane.web.Passenger.prototype.clearDob = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.Passenger.prototype.hasDob = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string nationality = 6;
 * @return {string}
 */
proto.airplane.web.Passenger.prototype.getNationality = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.Passenger} returns this
 */
proto.airplane.web.Passenger.prototype.setNationality = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web.Passenger} returns this
 */
proto.airplane.web.Passenger.prototype.clearNationality = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.Passenger.prototype.hasNationality = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string passport_number = 7;
 * @return {string}
 */
proto.airplane.web.Passenger.prototype.getPassportNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.Passenger} returns this
 */
proto.airplane.web.Passenger.prototype.setPassportNumber = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web.Passenger} returns this
 */
proto.airplane.web.Passenger.prototype.clearPassportNumber = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.Passenger.prototype.hasPassportNumber = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 expiration_date = 8;
 * @return {number}
 */
proto.airplane.web.Passenger.prototype.getExpirationDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.Passenger} returns this
 */
proto.airplane.web.Passenger.prototype.setExpirationDate = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web.Passenger} returns this
 */
proto.airplane.web.Passenger.prototype.clearExpirationDate = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.Passenger.prototype.hasExpirationDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string id_issue_country = 9;
 * @return {string}
 */
proto.airplane.web.Passenger.prototype.getIdIssueCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.Passenger} returns this
 */
proto.airplane.web.Passenger.prototype.setIdIssueCountry = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web.Passenger} returns this
 */
proto.airplane.web.Passenger.prototype.clearIdIssueCountry = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.Passenger.prototype.hasIdIssueCountry = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.ContactInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.ContactInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.ContactInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.ContactInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phoneCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    email: jspb.Message.getFieldWithDefault(msg, 6, ""),
    address: jspb.Message.getFieldWithDefault(msg, 7, ""),
    note: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.ContactInfo}
 */
proto.airplane.web.ContactInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.ContactInfo;
  return proto.airplane.web.ContactInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.ContactInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.ContactInfo}
 */
proto.airplane.web.ContactInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.ContactInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.ContactInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.ContactInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.ContactInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhoneCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.airplane.web.ContactInfo.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.ContactInfo} returns this
 */
proto.airplane.web.ContactInfo.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.airplane.web.ContactInfo.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.ContactInfo} returns this
 */
proto.airplane.web.ContactInfo.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.airplane.web.ContactInfo.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.ContactInfo} returns this
 */
proto.airplane.web.ContactInfo.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phone_code = 4;
 * @return {string}
 */
proto.airplane.web.ContactInfo.prototype.getPhoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.ContactInfo} returns this
 */
proto.airplane.web.ContactInfo.prototype.setPhoneCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string phone_number = 5;
 * @return {string}
 */
proto.airplane.web.ContactInfo.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.ContactInfo} returns this
 */
proto.airplane.web.ContactInfo.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string email = 6;
 * @return {string}
 */
proto.airplane.web.ContactInfo.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.ContactInfo} returns this
 */
proto.airplane.web.ContactInfo.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string address = 7;
 * @return {string}
 */
proto.airplane.web.ContactInfo.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.ContactInfo} returns this
 */
proto.airplane.web.ContactInfo.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string note = 8;
 * @return {string}
 */
proto.airplane.web.ContactInfo.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.ContactInfo} returns this
 */
proto.airplane.web.ContactInfo.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web.BookingFlightReq.repeatedFields_ = [5,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.BookingFlightReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.BookingFlightReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.BookingFlightReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.BookingFlightReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    flightId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 3, ""),
    contactInfo: (f = msg.getContactInfo()) && proto.airplane.web.ContactInfo.toObject(includeInstance, f),
    passengersList: jspb.Message.toObjectList(msg.getPassengersList(),
    proto.airplane.web.Passenger.toObject, includeInstance),
    note: jspb.Message.getFieldWithDefault(msg, 6, ""),
    isPublic: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    key: jspb.Message.getFieldWithDefault(msg, 8, ""),
    confirmPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    invoicingInformation: (f = msg.getInvoicingInformation()) && airplane_flight_pb.InvoicingInformation.toObject(includeInstance, f),
    timezone: jspb.Message.getFieldWithDefault(msg, 11, ""),
    affiliateMarketingNetworkInfo: (f = msg.getAffiliateMarketingNetworkInfo()) && airplane_flight_pb.AffiliateMarketingNetworkInfo.toObject(includeInstance, f),
    staticLastTicketDate: jspb.Message.getFieldWithDefault(msg, 13, 0),
    promotionCodesList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.BookingFlightReq}
 */
proto.airplane.web.BookingFlightReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.BookingFlightReq;
  return proto.airplane.web.BookingFlightReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.BookingFlightReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.BookingFlightReq}
 */
proto.airplane.web.BookingFlightReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 4:
      var value = new proto.airplane.web.ContactInfo;
      reader.readMessage(value,proto.airplane.web.ContactInfo.deserializeBinaryFromReader);
      msg.setContactInfo(value);
      break;
    case 5:
      var value = new proto.airplane.web.Passenger;
      reader.readMessage(value,proto.airplane.web.Passenger.deserializeBinaryFromReader);
      msg.addPassengers(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPublic(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setConfirmPrice(value);
      break;
    case 10:
      var value = new airplane_flight_pb.InvoicingInformation;
      reader.readMessage(value,airplane_flight_pb.InvoicingInformation.deserializeBinaryFromReader);
      msg.setInvoicingInformation(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    case 12:
      var value = new airplane_flight_pb.AffiliateMarketingNetworkInfo;
      reader.readMessage(value,airplane_flight_pb.AffiliateMarketingNetworkInfo.deserializeBinaryFromReader);
      msg.setAffiliateMarketingNetworkInfo(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStaticLastTicketDate(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.addPromotionCodes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.BookingFlightReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.BookingFlightReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.BookingFlightReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.BookingFlightReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getContactInfo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.airplane.web.ContactInfo.serializeBinaryToWriter
    );
  }
  f = message.getPassengersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.airplane.web.Passenger.serializeBinaryToWriter
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIsPublic();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getConfirmPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getInvoicingInformation();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      airplane_flight_pb.InvoicingInformation.serializeBinaryToWriter
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAffiliateMarketingNetworkInfo();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      airplane_flight_pb.AffiliateMarketingNetworkInfo.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getPromotionCodesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      14,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.airplane.web.BookingFlightReq.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightReq} returns this
 */
proto.airplane.web.BookingFlightReq.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string flight_id = 2;
 * @return {string}
 */
proto.airplane.web.BookingFlightReq.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightReq} returns this
 */
proto.airplane.web.BookingFlightReq.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string currency = 3;
 * @return {string}
 */
proto.airplane.web.BookingFlightReq.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightReq} returns this
 */
proto.airplane.web.BookingFlightReq.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ContactInfo contact_info = 4;
 * @return {?proto.airplane.web.ContactInfo}
 */
proto.airplane.web.BookingFlightReq.prototype.getContactInfo = function() {
  return /** @type{?proto.airplane.web.ContactInfo} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web.ContactInfo, 4));
};


/**
 * @param {?proto.airplane.web.ContactInfo|undefined} value
 * @return {!proto.airplane.web.BookingFlightReq} returns this
*/
proto.airplane.web.BookingFlightReq.prototype.setContactInfo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.BookingFlightReq} returns this
 */
proto.airplane.web.BookingFlightReq.prototype.clearContactInfo = function() {
  return this.setContactInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.BookingFlightReq.prototype.hasContactInfo = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated Passenger passengers = 5;
 * @return {!Array<!proto.airplane.web.Passenger>}
 */
proto.airplane.web.BookingFlightReq.prototype.getPassengersList = function() {
  return /** @type{!Array<!proto.airplane.web.Passenger>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web.Passenger, 5));
};


/**
 * @param {!Array<!proto.airplane.web.Passenger>} value
 * @return {!proto.airplane.web.BookingFlightReq} returns this
*/
proto.airplane.web.BookingFlightReq.prototype.setPassengersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.airplane.web.Passenger=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web.Passenger}
 */
proto.airplane.web.BookingFlightReq.prototype.addPassengers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.airplane.web.Passenger, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.BookingFlightReq} returns this
 */
proto.airplane.web.BookingFlightReq.prototype.clearPassengersList = function() {
  return this.setPassengersList([]);
};


/**
 * optional string note = 6;
 * @return {string}
 */
proto.airplane.web.BookingFlightReq.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightReq} returns this
 */
proto.airplane.web.BookingFlightReq.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool is_public = 7;
 * @return {boolean}
 */
proto.airplane.web.BookingFlightReq.prototype.getIsPublic = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web.BookingFlightReq} returns this
 */
proto.airplane.web.BookingFlightReq.prototype.setIsPublic = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string key = 8;
 * @return {string}
 */
proto.airplane.web.BookingFlightReq.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightReq} returns this
 */
proto.airplane.web.BookingFlightReq.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional double confirm_price = 9;
 * @return {number}
 */
proto.airplane.web.BookingFlightReq.prototype.getConfirmPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.BookingFlightReq} returns this
 */
proto.airplane.web.BookingFlightReq.prototype.setConfirmPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional airplane.InvoicingInformation invoicing_information = 10;
 * @return {?proto.airplane.InvoicingInformation}
 */
proto.airplane.web.BookingFlightReq.prototype.getInvoicingInformation = function() {
  return /** @type{?proto.airplane.InvoicingInformation} */ (
    jspb.Message.getWrapperField(this, airplane_flight_pb.InvoicingInformation, 10));
};


/**
 * @param {?proto.airplane.InvoicingInformation|undefined} value
 * @return {!proto.airplane.web.BookingFlightReq} returns this
*/
proto.airplane.web.BookingFlightReq.prototype.setInvoicingInformation = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.BookingFlightReq} returns this
 */
proto.airplane.web.BookingFlightReq.prototype.clearInvoicingInformation = function() {
  return this.setInvoicingInformation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.BookingFlightReq.prototype.hasInvoicingInformation = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string timezone = 11;
 * @return {string}
 */
proto.airplane.web.BookingFlightReq.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightReq} returns this
 */
proto.airplane.web.BookingFlightReq.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional airplane.AffiliateMarketingNetworkInfo affiliate_marketing_network_info = 12;
 * @return {?proto.airplane.AffiliateMarketingNetworkInfo}
 */
proto.airplane.web.BookingFlightReq.prototype.getAffiliateMarketingNetworkInfo = function() {
  return /** @type{?proto.airplane.AffiliateMarketingNetworkInfo} */ (
    jspb.Message.getWrapperField(this, airplane_flight_pb.AffiliateMarketingNetworkInfo, 12));
};


/**
 * @param {?proto.airplane.AffiliateMarketingNetworkInfo|undefined} value
 * @return {!proto.airplane.web.BookingFlightReq} returns this
*/
proto.airplane.web.BookingFlightReq.prototype.setAffiliateMarketingNetworkInfo = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.BookingFlightReq} returns this
 */
proto.airplane.web.BookingFlightReq.prototype.clearAffiliateMarketingNetworkInfo = function() {
  return this.setAffiliateMarketingNetworkInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.BookingFlightReq.prototype.hasAffiliateMarketingNetworkInfo = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional int64 static_last_ticket_date = 13;
 * @return {number}
 */
proto.airplane.web.BookingFlightReq.prototype.getStaticLastTicketDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.BookingFlightReq} returns this
 */
proto.airplane.web.BookingFlightReq.prototype.setStaticLastTicketDate = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web.BookingFlightReq} returns this
 */
proto.airplane.web.BookingFlightReq.prototype.clearStaticLastTicketDate = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.BookingFlightReq.prototype.hasStaticLastTicketDate = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * repeated string promotion_codes = 14;
 * @return {!Array<string>}
 */
proto.airplane.web.BookingFlightReq.prototype.getPromotionCodesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 14));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.airplane.web.BookingFlightReq} returns this
 */
proto.airplane.web.BookingFlightReq.prototype.setPromotionCodesList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.airplane.web.BookingFlightReq} returns this
 */
proto.airplane.web.BookingFlightReq.prototype.addPromotionCodes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.BookingFlightReq} returns this
 */
proto.airplane.web.BookingFlightReq.prototype.clearPromotionCodesList = function() {
  return this.setPromotionCodesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web.BookingFlightRes.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.BookingFlightRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.BookingFlightRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.BookingFlightRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.BookingFlightRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.airplane.web.BookingFlightRes.Data.toObject(includeInstance, f),
    promotionErrorsList: jspb.Message.toObjectList(msg.getPromotionErrorsList(),
    proto.airplane.web.PromoErrorRes.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.BookingFlightRes}
 */
proto.airplane.web.BookingFlightRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.BookingFlightRes;
  return proto.airplane.web.BookingFlightRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.BookingFlightRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.BookingFlightRes}
 */
proto.airplane.web.BookingFlightRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web.BookingFlightRes.Data;
      reader.readMessage(value,proto.airplane.web.BookingFlightRes.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 5:
      var value = new proto.airplane.web.PromoErrorRes;
      reader.readMessage(value,proto.airplane.web.PromoErrorRes.deserializeBinaryFromReader);
      msg.addPromotionErrors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.BookingFlightRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.BookingFlightRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.BookingFlightRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.BookingFlightRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.airplane.web.BookingFlightRes.Data.serializeBinaryToWriter
    );
  }
  f = message.getPromotionErrorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.airplane.web.PromoErrorRes.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.BookingFlightRes.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.BookingFlightRes.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.BookingFlightRes.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.BookingFlightRes.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orderId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bookingId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    bookingCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    fareData: (f = msg.getFareData()) && proto.airplane.web.FareData.toObject(includeInstance, f),
    fee: (f = msg.getFee()) && airplane_base_pb.CalculationFlightFeeResData.toObject(includeInstance, f),
    expiredAt: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.BookingFlightRes.Data}
 */
proto.airplane.web.BookingFlightRes.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.BookingFlightRes.Data;
  return proto.airplane.web.BookingFlightRes.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.BookingFlightRes.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.BookingFlightRes.Data}
 */
proto.airplane.web.BookingFlightRes.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    case 5:
      var value = new proto.airplane.web.FareData;
      reader.readMessage(value,proto.airplane.web.FareData.deserializeBinaryFromReader);
      msg.setFareData(value);
      break;
    case 6:
      var value = new airplane_base_pb.CalculationFlightFeeResData;
      reader.readMessage(value,airplane_base_pb.CalculationFlightFeeResData.deserializeBinaryFromReader);
      msg.setFee(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiredAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.BookingFlightRes.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.BookingFlightRes.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.BookingFlightRes.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.BookingFlightRes.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBookingId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFareData();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.airplane.web.FareData.serializeBinaryToWriter
    );
  }
  f = message.getFee();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      airplane_base_pb.CalculationFlightFeeResData.serializeBinaryToWriter
    );
  }
  f = message.getExpiredAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.airplane.web.BookingFlightRes.Data.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightRes.Data} returns this
 */
proto.airplane.web.BookingFlightRes.Data.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string order_id = 2;
 * @return {string}
 */
proto.airplane.web.BookingFlightRes.Data.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightRes.Data} returns this
 */
proto.airplane.web.BookingFlightRes.Data.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string booking_id = 3;
 * @return {string}
 */
proto.airplane.web.BookingFlightRes.Data.prototype.getBookingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightRes.Data} returns this
 */
proto.airplane.web.BookingFlightRes.Data.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string booking_code = 4;
 * @return {string}
 */
proto.airplane.web.BookingFlightRes.Data.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightRes.Data} returns this
 */
proto.airplane.web.BookingFlightRes.Data.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional FareData fare_data = 5;
 * @return {?proto.airplane.web.FareData}
 */
proto.airplane.web.BookingFlightRes.Data.prototype.getFareData = function() {
  return /** @type{?proto.airplane.web.FareData} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web.FareData, 5));
};


/**
 * @param {?proto.airplane.web.FareData|undefined} value
 * @return {!proto.airplane.web.BookingFlightRes.Data} returns this
*/
proto.airplane.web.BookingFlightRes.Data.prototype.setFareData = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.BookingFlightRes.Data} returns this
 */
proto.airplane.web.BookingFlightRes.Data.prototype.clearFareData = function() {
  return this.setFareData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.BookingFlightRes.Data.prototype.hasFareData = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional airplane.CalculationFlightFeeResData fee = 6;
 * @return {?proto.airplane.CalculationFlightFeeResData}
 */
proto.airplane.web.BookingFlightRes.Data.prototype.getFee = function() {
  return /** @type{?proto.airplane.CalculationFlightFeeResData} */ (
    jspb.Message.getWrapperField(this, airplane_base_pb.CalculationFlightFeeResData, 6));
};


/**
 * @param {?proto.airplane.CalculationFlightFeeResData|undefined} value
 * @return {!proto.airplane.web.BookingFlightRes.Data} returns this
*/
proto.airplane.web.BookingFlightRes.Data.prototype.setFee = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.BookingFlightRes.Data} returns this
 */
proto.airplane.web.BookingFlightRes.Data.prototype.clearFee = function() {
  return this.setFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.BookingFlightRes.Data.prototype.hasFee = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 expired_at = 7;
 * @return {number}
 */
proto.airplane.web.BookingFlightRes.Data.prototype.getExpiredAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.BookingFlightRes.Data} returns this
 */
proto.airplane.web.BookingFlightRes.Data.prototype.setExpiredAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web.BookingFlightRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web.BookingFlightRes} returns this
 */
proto.airplane.web.BookingFlightRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web.BookingFlightRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightRes} returns this
 */
proto.airplane.web.BookingFlightRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Data data = 3;
 * @return {?proto.airplane.web.BookingFlightRes.Data}
 */
proto.airplane.web.BookingFlightRes.prototype.getData = function() {
  return /** @type{?proto.airplane.web.BookingFlightRes.Data} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web.BookingFlightRes.Data, 3));
};


/**
 * @param {?proto.airplane.web.BookingFlightRes.Data|undefined} value
 * @return {!proto.airplane.web.BookingFlightRes} returns this
*/
proto.airplane.web.BookingFlightRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.BookingFlightRes} returns this
 */
proto.airplane.web.BookingFlightRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.BookingFlightRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated PromoErrorRes promotion_errors = 5;
 * @return {!Array<!proto.airplane.web.PromoErrorRes>}
 */
proto.airplane.web.BookingFlightRes.prototype.getPromotionErrorsList = function() {
  return /** @type{!Array<!proto.airplane.web.PromoErrorRes>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web.PromoErrorRes, 5));
};


/**
 * @param {!Array<!proto.airplane.web.PromoErrorRes>} value
 * @return {!proto.airplane.web.BookingFlightRes} returns this
*/
proto.airplane.web.BookingFlightRes.prototype.setPromotionErrorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.airplane.web.PromoErrorRes=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web.PromoErrorRes}
 */
proto.airplane.web.BookingFlightRes.prototype.addPromotionErrors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.airplane.web.PromoErrorRes, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.BookingFlightRes} returns this
 */
proto.airplane.web.BookingFlightRes.prototype.clearPromotionErrorsList = function() {
  return this.setPromotionErrorsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.RetrieveBookingFlightReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.RetrieveBookingFlightReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.RetrieveBookingFlightReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.RetrieveBookingFlightReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingCode: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.RetrieveBookingFlightReq}
 */
proto.airplane.web.RetrieveBookingFlightReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.RetrieveBookingFlightReq;
  return proto.airplane.web.RetrieveBookingFlightReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.RetrieveBookingFlightReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.RetrieveBookingFlightReq}
 */
proto.airplane.web.RetrieveBookingFlightReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.RetrieveBookingFlightReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.RetrieveBookingFlightReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.RetrieveBookingFlightReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.RetrieveBookingFlightReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string booking_code = 1;
 * @return {string}
 */
proto.airplane.web.RetrieveBookingFlightReq.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.RetrieveBookingFlightReq} returns this
 */
proto.airplane.web.RetrieveBookingFlightReq.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.PromoLog.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.PromoLog.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.PromoLog} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.PromoLog.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.PromoLog}
 */
proto.airplane.web.PromoLog.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.PromoLog;
  return proto.airplane.web.PromoLog.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.PromoLog} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.PromoLog}
 */
proto.airplane.web.PromoLog.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.PromoLog.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.PromoLog.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.PromoLog} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.PromoLog.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.airplane.web.PromoLog.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.PromoLog} returns this
 */
proto.airplane.web.PromoLog.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double amount = 2;
 * @return {number}
 */
proto.airplane.web.PromoLog.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.PromoLog} returns this
 */
proto.airplane.web.PromoLog.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.RetrieveBookingFlightRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.RetrieveBookingFlightRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.RetrieveBookingFlightRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.RetrieveBookingFlightRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.airplane.web.RetrieveBookingFlightRes.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.RetrieveBookingFlightRes}
 */
proto.airplane.web.RetrieveBookingFlightRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.RetrieveBookingFlightRes;
  return proto.airplane.web.RetrieveBookingFlightRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.RetrieveBookingFlightRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.RetrieveBookingFlightRes}
 */
proto.airplane.web.RetrieveBookingFlightRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web.RetrieveBookingFlightRes.Data;
      reader.readMessage(value,proto.airplane.web.RetrieveBookingFlightRes.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.RetrieveBookingFlightRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.RetrieveBookingFlightRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.RetrieveBookingFlightRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.RetrieveBookingFlightRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.airplane.web.RetrieveBookingFlightRes.Data.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.repeatedFields_ = [4,5,19];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.RetrieveBookingFlightRes.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.RetrieveBookingFlightRes.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reservationCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    passengersList: jspb.Message.toObjectList(msg.getPassengersList(),
    proto.airplane.web.BookingPassenger.toObject, includeInstance),
    itinerariesList: jspb.Message.toObjectList(msg.getItinerariesList(),
    airplane_flight_pb.BookingItinerary.toObject, includeInstance),
    fee: (f = msg.getFee()) && airplane_base_pb.CalculationFlightFeeResData.toObject(includeInstance, f),
    contactInfo: (f = msg.getContactInfo()) && proto.airplane.web.BookingContactInfo.toObject(includeInstance, f),
    createdBy: jspb.Message.getFieldWithDefault(msg, 8, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 9, 0),
    note: jspb.Message.getFieldWithDefault(msg, 10, ""),
    invoicingInformation: (f = msg.getInvoicingInformation()) && airplane_flight_pb.InvoicingInformation.toObject(includeInstance, f),
    currency: jspb.Message.getFieldWithDefault(msg, 12, ""),
    orderId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    expiredAt: jspb.Message.getFieldWithDefault(msg, 14, 0),
    issuedAt: jspb.Message.getFieldWithDefault(msg, 15, 0),
    id: jspb.Message.getFieldWithDefault(msg, 16, ""),
    paymentInfo: (f = msg.getPaymentInfo()) && airplane_hub_pb.PaymentInfo.toObject(includeInstance, f),
    showConfirmTimeChange: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    promoLogsList: jspb.Message.toObjectList(msg.getPromoLogsList(),
    proto.airplane.web.PromoLog.toObject, includeInstance),
    affiliateInfo: (f = msg.getAffiliateInfo()) && airplane_flight_pb.AffiliateMarketingNetworkInfo.toObject(includeInstance, f),
    flightType: jspb.Message.getFieldWithDefault(msg, 21, ""),
    tripType: jspb.Message.getFieldWithDefault(msg, 22, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.RetrieveBookingFlightRes.Data}
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.RetrieveBookingFlightRes.Data;
  return proto.airplane.web.RetrieveBookingFlightRes.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.RetrieveBookingFlightRes.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.RetrieveBookingFlightRes.Data}
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationCode(value);
      break;
    case 3:
      var value = /** @type {!proto.airplane.BookingStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = new proto.airplane.web.BookingPassenger;
      reader.readMessage(value,proto.airplane.web.BookingPassenger.deserializeBinaryFromReader);
      msg.addPassengers(value);
      break;
    case 5:
      var value = new airplane_flight_pb.BookingItinerary;
      reader.readMessage(value,airplane_flight_pb.BookingItinerary.deserializeBinaryFromReader);
      msg.addItineraries(value);
      break;
    case 6:
      var value = new airplane_base_pb.CalculationFlightFeeResData;
      reader.readMessage(value,airplane_base_pb.CalculationFlightFeeResData.deserializeBinaryFromReader);
      msg.setFee(value);
      break;
    case 7:
      var value = new proto.airplane.web.BookingContactInfo;
      reader.readMessage(value,proto.airplane.web.BookingContactInfo.deserializeBinaryFromReader);
      msg.setContactInfo(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 11:
      var value = new airplane_flight_pb.InvoicingInformation;
      reader.readMessage(value,airplane_flight_pb.InvoicingInformation.deserializeBinaryFromReader);
      msg.setInvoicingInformation(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiredAt(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIssuedAt(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 17:
      var value = new airplane_hub_pb.PaymentInfo;
      reader.readMessage(value,airplane_hub_pb.PaymentInfo.deserializeBinaryFromReader);
      msg.setPaymentInfo(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowConfirmTimeChange(value);
      break;
    case 19:
      var value = new proto.airplane.web.PromoLog;
      reader.readMessage(value,proto.airplane.web.PromoLog.deserializeBinaryFromReader);
      msg.addPromoLogs(value);
      break;
    case 20:
      var value = new airplane_flight_pb.AffiliateMarketingNetworkInfo;
      reader.readMessage(value,airplane_flight_pb.AffiliateMarketingNetworkInfo.deserializeBinaryFromReader);
      msg.setAffiliateInfo(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightType(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setTripType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.RetrieveBookingFlightRes.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.RetrieveBookingFlightRes.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReservationCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getPassengersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.airplane.web.BookingPassenger.serializeBinaryToWriter
    );
  }
  f = message.getItinerariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      airplane_flight_pb.BookingItinerary.serializeBinaryToWriter
    );
  }
  f = message.getFee();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      airplane_base_pb.CalculationFlightFeeResData.serializeBinaryToWriter
    );
  }
  f = message.getContactInfo();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.airplane.web.BookingContactInfo.serializeBinaryToWriter
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getInvoicingInformation();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      airplane_flight_pb.InvoicingInformation.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getExpiredAt();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getIssuedAt();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getPaymentInfo();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      airplane_hub_pb.PaymentInfo.serializeBinaryToWriter
    );
  }
  f = message.getShowConfirmTimeChange();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getPromoLogsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      proto.airplane.web.PromoLog.serializeBinaryToWriter
    );
  }
  f = message.getAffiliateInfo();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      airplane_flight_pb.AffiliateMarketingNetworkInfo.serializeBinaryToWriter
    );
  }
  f = message.getFlightType();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getTripType();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
};


/**
 * optional string booking_code = 1;
 * @return {string}
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string reservation_code = 2;
 * @return {string}
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.getReservationCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.setReservationCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional airplane.BookingStatus status = 3;
 * @return {!proto.airplane.BookingStatus}
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.getStatus = function() {
  return /** @type {!proto.airplane.BookingStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.airplane.BookingStatus} value
 * @return {!proto.airplane.web.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated BookingPassenger passengers = 4;
 * @return {!Array<!proto.airplane.web.BookingPassenger>}
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.getPassengersList = function() {
  return /** @type{!Array<!proto.airplane.web.BookingPassenger>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web.BookingPassenger, 4));
};


/**
 * @param {!Array<!proto.airplane.web.BookingPassenger>} value
 * @return {!proto.airplane.web.RetrieveBookingFlightRes.Data} returns this
*/
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.setPassengersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.airplane.web.BookingPassenger=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web.BookingPassenger}
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.addPassengers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.airplane.web.BookingPassenger, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.clearPassengersList = function() {
  return this.setPassengersList([]);
};


/**
 * repeated airplane.BookingItinerary itineraries = 5;
 * @return {!Array<!proto.airplane.BookingItinerary>}
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.getItinerariesList = function() {
  return /** @type{!Array<!proto.airplane.BookingItinerary>} */ (
    jspb.Message.getRepeatedWrapperField(this, airplane_flight_pb.BookingItinerary, 5));
};


/**
 * @param {!Array<!proto.airplane.BookingItinerary>} value
 * @return {!proto.airplane.web.RetrieveBookingFlightRes.Data} returns this
*/
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.setItinerariesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.airplane.BookingItinerary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.BookingItinerary}
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.addItineraries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.airplane.BookingItinerary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.clearItinerariesList = function() {
  return this.setItinerariesList([]);
};


/**
 * optional airplane.CalculationFlightFeeResData fee = 6;
 * @return {?proto.airplane.CalculationFlightFeeResData}
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.getFee = function() {
  return /** @type{?proto.airplane.CalculationFlightFeeResData} */ (
    jspb.Message.getWrapperField(this, airplane_base_pb.CalculationFlightFeeResData, 6));
};


/**
 * @param {?proto.airplane.CalculationFlightFeeResData|undefined} value
 * @return {!proto.airplane.web.RetrieveBookingFlightRes.Data} returns this
*/
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.setFee = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.clearFee = function() {
  return this.setFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.hasFee = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional BookingContactInfo contact_info = 7;
 * @return {?proto.airplane.web.BookingContactInfo}
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.getContactInfo = function() {
  return /** @type{?proto.airplane.web.BookingContactInfo} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web.BookingContactInfo, 7));
};


/**
 * @param {?proto.airplane.web.BookingContactInfo|undefined} value
 * @return {!proto.airplane.web.RetrieveBookingFlightRes.Data} returns this
*/
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.setContactInfo = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.clearContactInfo = function() {
  return this.setContactInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.hasContactInfo = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string created_by = 8;
 * @return {string}
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 created_at = 9;
 * @return {number}
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string note = 10;
 * @return {string}
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional airplane.InvoicingInformation invoicing_information = 11;
 * @return {?proto.airplane.InvoicingInformation}
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.getInvoicingInformation = function() {
  return /** @type{?proto.airplane.InvoicingInformation} */ (
    jspb.Message.getWrapperField(this, airplane_flight_pb.InvoicingInformation, 11));
};


/**
 * @param {?proto.airplane.InvoicingInformation|undefined} value
 * @return {!proto.airplane.web.RetrieveBookingFlightRes.Data} returns this
*/
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.setInvoicingInformation = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.clearInvoicingInformation = function() {
  return this.setInvoicingInformation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.hasInvoicingInformation = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string currency = 12;
 * @return {string}
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string order_id = 13;
 * @return {string}
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int64 expired_at = 14;
 * @return {number}
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.getExpiredAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.setExpiredAt = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 issued_at = 15;
 * @return {number}
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.getIssuedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.setIssuedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string id = 16;
 * @return {string}
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional airplane.PaymentInfo payment_info = 17;
 * @return {?proto.airplane.PaymentInfo}
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.getPaymentInfo = function() {
  return /** @type{?proto.airplane.PaymentInfo} */ (
    jspb.Message.getWrapperField(this, airplane_hub_pb.PaymentInfo, 17));
};


/**
 * @param {?proto.airplane.PaymentInfo|undefined} value
 * @return {!proto.airplane.web.RetrieveBookingFlightRes.Data} returns this
*/
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.setPaymentInfo = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.clearPaymentInfo = function() {
  return this.setPaymentInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.hasPaymentInfo = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional bool show_confirm_time_change = 18;
 * @return {boolean}
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.getShowConfirmTimeChange = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.setShowConfirmTimeChange = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * repeated PromoLog promo_logs = 19;
 * @return {!Array<!proto.airplane.web.PromoLog>}
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.getPromoLogsList = function() {
  return /** @type{!Array<!proto.airplane.web.PromoLog>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web.PromoLog, 19));
};


/**
 * @param {!Array<!proto.airplane.web.PromoLog>} value
 * @return {!proto.airplane.web.RetrieveBookingFlightRes.Data} returns this
*/
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.setPromoLogsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.airplane.web.PromoLog=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web.PromoLog}
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.addPromoLogs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.airplane.web.PromoLog, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.clearPromoLogsList = function() {
  return this.setPromoLogsList([]);
};


/**
 * optional airplane.AffiliateMarketingNetworkInfo affiliate_info = 20;
 * @return {?proto.airplane.AffiliateMarketingNetworkInfo}
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.getAffiliateInfo = function() {
  return /** @type{?proto.airplane.AffiliateMarketingNetworkInfo} */ (
    jspb.Message.getWrapperField(this, airplane_flight_pb.AffiliateMarketingNetworkInfo, 20));
};


/**
 * @param {?proto.airplane.AffiliateMarketingNetworkInfo|undefined} value
 * @return {!proto.airplane.web.RetrieveBookingFlightRes.Data} returns this
*/
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.setAffiliateInfo = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.clearAffiliateInfo = function() {
  return this.setAffiliateInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.hasAffiliateInfo = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional string flight_type = 21;
 * @return {string}
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.getFlightType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.setFlightType = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string trip_type = 22;
 * @return {string}
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.getTripType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web.RetrieveBookingFlightRes.Data.prototype.setTripType = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web.RetrieveBookingFlightRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web.RetrieveBookingFlightRes} returns this
 */
proto.airplane.web.RetrieveBookingFlightRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web.RetrieveBookingFlightRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.RetrieveBookingFlightRes} returns this
 */
proto.airplane.web.RetrieveBookingFlightRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Data data = 3;
 * @return {?proto.airplane.web.RetrieveBookingFlightRes.Data}
 */
proto.airplane.web.RetrieveBookingFlightRes.prototype.getData = function() {
  return /** @type{?proto.airplane.web.RetrieveBookingFlightRes.Data} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web.RetrieveBookingFlightRes.Data, 3));
};


/**
 * @param {?proto.airplane.web.RetrieveBookingFlightRes.Data|undefined} value
 * @return {!proto.airplane.web.RetrieveBookingFlightRes} returns this
*/
proto.airplane.web.RetrieveBookingFlightRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.RetrieveBookingFlightRes} returns this
 */
proto.airplane.web.RetrieveBookingFlightRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.RetrieveBookingFlightRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.ETicket.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.ETicket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.ETicket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.ETicket.toObject = function(includeInstance, msg) {
  var f, obj = {
    paxId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ticketNumber: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.ETicket}
 */
proto.airplane.web.ETicket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.ETicket;
  return proto.airplane.web.ETicket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.ETicket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.ETicket}
 */
proto.airplane.web.ETicket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPaxId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTicketNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.ETicket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.ETicket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.ETicket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.ETicket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaxId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTicketNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 pax_id = 1;
 * @return {number}
 */
proto.airplane.web.ETicket.prototype.getPaxId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.ETicket} returns this
 */
proto.airplane.web.ETicket.prototype.setPaxId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string ticket_number = 2;
 * @return {string}
 */
proto.airplane.web.ETicket.prototype.getTicketNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.ETicket} returns this
 */
proto.airplane.web.ETicket.prototype.setTicketNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.BookingPassenger.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.BookingPassenger.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.BookingPassenger} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.BookingPassenger.toObject = function(includeInstance, msg) {
  var f, obj = {
    paxId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    gender: jspb.Message.getFieldWithDefault(msg, 5, 0),
    dob: jspb.Message.getFieldWithDefault(msg, 6, 0),
    nationality: jspb.Message.getFieldWithDefault(msg, 7, ""),
    passportNumber: jspb.Message.getFieldWithDefault(msg, 8, ""),
    expirationDate: jspb.Message.getFieldWithDefault(msg, 9, 0),
    idIssueCountry: jspb.Message.getFieldWithDefault(msg, 10, ""),
    dobNull: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.BookingPassenger}
 */
proto.airplane.web.BookingPassenger.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.BookingPassenger;
  return proto.airplane.web.BookingPassenger.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.BookingPassenger} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.BookingPassenger}
 */
proto.airplane.web.BookingPassenger.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPaxId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {!proto.airplane.PaxType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {!proto.base.GENDER} */ (reader.readEnum());
      msg.setGender(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDob(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNationality(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassportNumber(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpirationDate(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdIssueCountry(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDobNull(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.BookingPassenger.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.BookingPassenger.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.BookingPassenger} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.BookingPassenger.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaxId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getGender();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDobNull();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional int64 pax_id = 1;
 * @return {number}
 */
proto.airplane.web.BookingPassenger.prototype.getPaxId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.BookingPassenger} returns this
 */
proto.airplane.web.BookingPassenger.prototype.setPaxId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.airplane.web.BookingPassenger.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingPassenger} returns this
 */
proto.airplane.web.BookingPassenger.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.airplane.web.BookingPassenger.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingPassenger} returns this
 */
proto.airplane.web.BookingPassenger.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional airplane.PaxType type = 4;
 * @return {!proto.airplane.PaxType}
 */
proto.airplane.web.BookingPassenger.prototype.getType = function() {
  return /** @type {!proto.airplane.PaxType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.airplane.PaxType} value
 * @return {!proto.airplane.web.BookingPassenger} returns this
 */
proto.airplane.web.BookingPassenger.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional base.GENDER gender = 5;
 * @return {!proto.base.GENDER}
 */
proto.airplane.web.BookingPassenger.prototype.getGender = function() {
  return /** @type {!proto.base.GENDER} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.base.GENDER} value
 * @return {!proto.airplane.web.BookingPassenger} returns this
 */
proto.airplane.web.BookingPassenger.prototype.setGender = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int64 dob = 6;
 * @return {number}
 */
proto.airplane.web.BookingPassenger.prototype.getDob = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.BookingPassenger} returns this
 */
proto.airplane.web.BookingPassenger.prototype.setDob = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web.BookingPassenger} returns this
 */
proto.airplane.web.BookingPassenger.prototype.clearDob = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.BookingPassenger.prototype.hasDob = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string nationality = 7;
 * @return {string}
 */
proto.airplane.web.BookingPassenger.prototype.getNationality = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingPassenger} returns this
 */
proto.airplane.web.BookingPassenger.prototype.setNationality = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web.BookingPassenger} returns this
 */
proto.airplane.web.BookingPassenger.prototype.clearNationality = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.BookingPassenger.prototype.hasNationality = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string passport_number = 8;
 * @return {string}
 */
proto.airplane.web.BookingPassenger.prototype.getPassportNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingPassenger} returns this
 */
proto.airplane.web.BookingPassenger.prototype.setPassportNumber = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web.BookingPassenger} returns this
 */
proto.airplane.web.BookingPassenger.prototype.clearPassportNumber = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.BookingPassenger.prototype.hasPassportNumber = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int64 expiration_date = 9;
 * @return {number}
 */
proto.airplane.web.BookingPassenger.prototype.getExpirationDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.BookingPassenger} returns this
 */
proto.airplane.web.BookingPassenger.prototype.setExpirationDate = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web.BookingPassenger} returns this
 */
proto.airplane.web.BookingPassenger.prototype.clearExpirationDate = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.BookingPassenger.prototype.hasExpirationDate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string id_issue_country = 10;
 * @return {string}
 */
proto.airplane.web.BookingPassenger.prototype.getIdIssueCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingPassenger} returns this
 */
proto.airplane.web.BookingPassenger.prototype.setIdIssueCountry = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web.BookingPassenger} returns this
 */
proto.airplane.web.BookingPassenger.prototype.clearIdIssueCountry = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.BookingPassenger.prototype.hasIdIssueCountry = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool dob_null = 11;
 * @return {boolean}
 */
proto.airplane.web.BookingPassenger.prototype.getDobNull = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web.BookingPassenger} returns this
 */
proto.airplane.web.BookingPassenger.prototype.setDobNull = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.BookingContactInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.BookingContactInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.BookingContactInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.BookingContactInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phoneCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    email: jspb.Message.getFieldWithDefault(msg, 6, ""),
    address: jspb.Message.getFieldWithDefault(msg, 7, ""),
    note: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.BookingContactInfo}
 */
proto.airplane.web.BookingContactInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.BookingContactInfo;
  return proto.airplane.web.BookingContactInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.BookingContactInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.BookingContactInfo}
 */
proto.airplane.web.BookingContactInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.BookingContactInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.BookingContactInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.BookingContactInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.BookingContactInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhoneCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.airplane.web.BookingContactInfo.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingContactInfo} returns this
 */
proto.airplane.web.BookingContactInfo.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.airplane.web.BookingContactInfo.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingContactInfo} returns this
 */
proto.airplane.web.BookingContactInfo.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.airplane.web.BookingContactInfo.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingContactInfo} returns this
 */
proto.airplane.web.BookingContactInfo.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phone_code = 4;
 * @return {string}
 */
proto.airplane.web.BookingContactInfo.prototype.getPhoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingContactInfo} returns this
 */
proto.airplane.web.BookingContactInfo.prototype.setPhoneCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string phone_number = 5;
 * @return {string}
 */
proto.airplane.web.BookingContactInfo.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingContactInfo} returns this
 */
proto.airplane.web.BookingContactInfo.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string email = 6;
 * @return {string}
 */
proto.airplane.web.BookingContactInfo.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingContactInfo} returns this
 */
proto.airplane.web.BookingContactInfo.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string address = 7;
 * @return {string}
 */
proto.airplane.web.BookingContactInfo.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingContactInfo} returns this
 */
proto.airplane.web.BookingContactInfo.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string note = 8;
 * @return {string}
 */
proto.airplane.web.BookingContactInfo.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingContactInfo} returns this
 */
proto.airplane.web.BookingContactInfo.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.CalculationFlightFeeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.CalculationFlightFeeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.CalculationFlightFeeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.CalculationFlightFeeReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    flightId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    passengerQuantity: (f = msg.getPassengerQuantity()) && proto.airplane.web.PassengerQuantity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.CalculationFlightFeeReq}
 */
proto.airplane.web.CalculationFlightFeeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.CalculationFlightFeeReq;
  return proto.airplane.web.CalculationFlightFeeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.CalculationFlightFeeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.CalculationFlightFeeReq}
 */
proto.airplane.web.CalculationFlightFeeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 3:
      var value = new proto.airplane.web.PassengerQuantity;
      reader.readMessage(value,proto.airplane.web.PassengerQuantity.deserializeBinaryFromReader);
      msg.setPassengerQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.CalculationFlightFeeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.CalculationFlightFeeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.CalculationFlightFeeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.CalculationFlightFeeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPassengerQuantity();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.airplane.web.PassengerQuantity.serializeBinaryToWriter
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.airplane.web.CalculationFlightFeeReq.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.CalculationFlightFeeReq} returns this
 */
proto.airplane.web.CalculationFlightFeeReq.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string flight_id = 2;
 * @return {string}
 */
proto.airplane.web.CalculationFlightFeeReq.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.CalculationFlightFeeReq} returns this
 */
proto.airplane.web.CalculationFlightFeeReq.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional PassengerQuantity passenger_quantity = 3;
 * @return {?proto.airplane.web.PassengerQuantity}
 */
proto.airplane.web.CalculationFlightFeeReq.prototype.getPassengerQuantity = function() {
  return /** @type{?proto.airplane.web.PassengerQuantity} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web.PassengerQuantity, 3));
};


/**
 * @param {?proto.airplane.web.PassengerQuantity|undefined} value
 * @return {!proto.airplane.web.CalculationFlightFeeReq} returns this
*/
proto.airplane.web.CalculationFlightFeeReq.prototype.setPassengerQuantity = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.CalculationFlightFeeReq} returns this
 */
proto.airplane.web.CalculationFlightFeeReq.prototype.clearPassengerQuantity = function() {
  return this.setPassengerQuantity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.CalculationFlightFeeReq.prototype.hasPassengerQuantity = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web.CalculationFlightFeeV2Req.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.CalculationFlightFeeV2Req.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.CalculationFlightFeeV2Req.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.CalculationFlightFeeV2Req} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.CalculationFlightFeeV2Req.toObject = function(includeInstance, msg) {
  var f, obj = {
    flightPairsList: jspb.Message.toObjectList(msg.getFlightPairsList(),
    proto.airplane.web.FlightPair.toObject, includeInstance),
    passengerQuantity: (f = msg.getPassengerQuantity()) && proto.airplane.web.PassengerQuantity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.CalculationFlightFeeV2Req}
 */
proto.airplane.web.CalculationFlightFeeV2Req.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.CalculationFlightFeeV2Req;
  return proto.airplane.web.CalculationFlightFeeV2Req.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.CalculationFlightFeeV2Req} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.CalculationFlightFeeV2Req}
 */
proto.airplane.web.CalculationFlightFeeV2Req.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web.FlightPair;
      reader.readMessage(value,proto.airplane.web.FlightPair.deserializeBinaryFromReader);
      msg.addFlightPairs(value);
      break;
    case 2:
      var value = new proto.airplane.web.PassengerQuantity;
      reader.readMessage(value,proto.airplane.web.PassengerQuantity.deserializeBinaryFromReader);
      msg.setPassengerQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.CalculationFlightFeeV2Req.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.CalculationFlightFeeV2Req.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.CalculationFlightFeeV2Req} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.CalculationFlightFeeV2Req.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlightPairsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.airplane.web.FlightPair.serializeBinaryToWriter
    );
  }
  f = message.getPassengerQuantity();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.airplane.web.PassengerQuantity.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FlightPair flight_pairs = 1;
 * @return {!Array<!proto.airplane.web.FlightPair>}
 */
proto.airplane.web.CalculationFlightFeeV2Req.prototype.getFlightPairsList = function() {
  return /** @type{!Array<!proto.airplane.web.FlightPair>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web.FlightPair, 1));
};


/**
 * @param {!Array<!proto.airplane.web.FlightPair>} value
 * @return {!proto.airplane.web.CalculationFlightFeeV2Req} returns this
*/
proto.airplane.web.CalculationFlightFeeV2Req.prototype.setFlightPairsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.airplane.web.FlightPair=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web.FlightPair}
 */
proto.airplane.web.CalculationFlightFeeV2Req.prototype.addFlightPairs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.airplane.web.FlightPair, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.CalculationFlightFeeV2Req} returns this
 */
proto.airplane.web.CalculationFlightFeeV2Req.prototype.clearFlightPairsList = function() {
  return this.setFlightPairsList([]);
};


/**
 * optional PassengerQuantity passenger_quantity = 2;
 * @return {?proto.airplane.web.PassengerQuantity}
 */
proto.airplane.web.CalculationFlightFeeV2Req.prototype.getPassengerQuantity = function() {
  return /** @type{?proto.airplane.web.PassengerQuantity} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web.PassengerQuantity, 2));
};


/**
 * @param {?proto.airplane.web.PassengerQuantity|undefined} value
 * @return {!proto.airplane.web.CalculationFlightFeeV2Req} returns this
*/
proto.airplane.web.CalculationFlightFeeV2Req.prototype.setPassengerQuantity = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.CalculationFlightFeeV2Req} returns this
 */
proto.airplane.web.CalculationFlightFeeV2Req.prototype.clearPassengerQuantity = function() {
  return this.setPassengerQuantity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.CalculationFlightFeeV2Req.prototype.hasPassengerQuantity = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.CalculationFlightFeeRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.CalculationFlightFeeRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.CalculationFlightFeeRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.CalculationFlightFeeRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && airplane_base_pb.CalculationFlightFeeResData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.CalculationFlightFeeRes}
 */
proto.airplane.web.CalculationFlightFeeRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.CalculationFlightFeeRes;
  return proto.airplane.web.CalculationFlightFeeRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.CalculationFlightFeeRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.CalculationFlightFeeRes}
 */
proto.airplane.web.CalculationFlightFeeRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new airplane_base_pb.CalculationFlightFeeResData;
      reader.readMessage(value,airplane_base_pb.CalculationFlightFeeResData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.CalculationFlightFeeRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.CalculationFlightFeeRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.CalculationFlightFeeRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.CalculationFlightFeeRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      airplane_base_pb.CalculationFlightFeeResData.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web.CalculationFlightFeeRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web.CalculationFlightFeeRes} returns this
 */
proto.airplane.web.CalculationFlightFeeRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web.CalculationFlightFeeRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.CalculationFlightFeeRes} returns this
 */
proto.airplane.web.CalculationFlightFeeRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional airplane.CalculationFlightFeeResData data = 3;
 * @return {?proto.airplane.CalculationFlightFeeResData}
 */
proto.airplane.web.CalculationFlightFeeRes.prototype.getData = function() {
  return /** @type{?proto.airplane.CalculationFlightFeeResData} */ (
    jspb.Message.getWrapperField(this, airplane_base_pb.CalculationFlightFeeResData, 3));
};


/**
 * @param {?proto.airplane.CalculationFlightFeeResData|undefined} value
 * @return {!proto.airplane.web.CalculationFlightFeeRes} returns this
*/
proto.airplane.web.CalculationFlightFeeRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.CalculationFlightFeeRes} returns this
 */
proto.airplane.web.CalculationFlightFeeRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.CalculationFlightFeeRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.GetSeatMapReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.GetSeatMapReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.GetSeatMapReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.GetSeatMapReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    isDomestic: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    flightId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    key: jspb.Message.getFieldWithDefault(msg, 3, ""),
    itineraryId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    segmentIndex: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.GetSeatMapReq}
 */
proto.airplane.web.GetSeatMapReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.GetSeatMapReq;
  return proto.airplane.web.GetSeatMapReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.GetSeatMapReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.GetSeatMapReq}
 */
proto.airplane.web.GetSeatMapReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDomestic(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setItineraryId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSegmentIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.GetSeatMapReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.GetSeatMapReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.GetSeatMapReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.GetSeatMapReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsDomestic();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getItineraryId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSegmentIndex();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional bool is_domestic = 1;
 * @return {boolean}
 */
proto.airplane.web.GetSeatMapReq.prototype.getIsDomestic = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web.GetSeatMapReq} returns this
 */
proto.airplane.web.GetSeatMapReq.prototype.setIsDomestic = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string flight_id = 2;
 * @return {string}
 */
proto.airplane.web.GetSeatMapReq.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.GetSeatMapReq} returns this
 */
proto.airplane.web.GetSeatMapReq.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string key = 3;
 * @return {string}
 */
proto.airplane.web.GetSeatMapReq.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.GetSeatMapReq} returns this
 */
proto.airplane.web.GetSeatMapReq.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string itinerary_id = 4;
 * @return {string}
 */
proto.airplane.web.GetSeatMapReq.prototype.getItineraryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.GetSeatMapReq} returns this
 */
proto.airplane.web.GetSeatMapReq.prototype.setItineraryId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 segment_index = 5;
 * @return {number}
 */
proto.airplane.web.GetSeatMapReq.prototype.getSegmentIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.GetSeatMapReq} returns this
 */
proto.airplane.web.GetSeatMapReq.prototype.setSegmentIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web.GetSeatMapRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.GetSeatMapRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.GetSeatMapRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.GetSeatMapRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.GetSeatMapRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    airplane_seat_pb.SeatOption.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.GetSeatMapRes}
 */
proto.airplane.web.GetSeatMapRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.GetSeatMapRes;
  return proto.airplane.web.GetSeatMapRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.GetSeatMapRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.GetSeatMapRes}
 */
proto.airplane.web.GetSeatMapRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new airplane_seat_pb.SeatOption;
      reader.readMessage(value,airplane_seat_pb.SeatOption.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.GetSeatMapRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.GetSeatMapRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.GetSeatMapRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.GetSeatMapRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      airplane_seat_pb.SeatOption.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web.GetSeatMapRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web.GetSeatMapRes} returns this
 */
proto.airplane.web.GetSeatMapRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web.GetSeatMapRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.GetSeatMapRes} returns this
 */
proto.airplane.web.GetSeatMapRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated airplane.SeatOption items = 3;
 * @return {!Array<!proto.airplane.SeatOption>}
 */
proto.airplane.web.GetSeatMapRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.SeatOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, airplane_seat_pb.SeatOption, 3));
};


/**
 * @param {!Array<!proto.airplane.SeatOption>} value
 * @return {!proto.airplane.web.GetSeatMapRes} returns this
*/
proto.airplane.web.GetSeatMapRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.SeatOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.SeatOption}
 */
proto.airplane.web.GetSeatMapRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.SeatOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.GetSeatMapRes} returns this
 */
proto.airplane.web.GetSeatMapRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.GetBaggageOptionsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.GetBaggageOptionsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.GetBaggageOptionsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.GetBaggageOptionsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    flightId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    key: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itineraryId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isDomestic: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.GetBaggageOptionsReq}
 */
proto.airplane.web.GetBaggageOptionsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.GetBaggageOptionsReq;
  return proto.airplane.web.GetBaggageOptionsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.GetBaggageOptionsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.GetBaggageOptionsReq}
 */
proto.airplane.web.GetBaggageOptionsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setItineraryId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDomestic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.GetBaggageOptionsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.GetBaggageOptionsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.GetBaggageOptionsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.GetBaggageOptionsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItineraryId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsDomestic();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string flight_id = 1;
 * @return {string}
 */
proto.airplane.web.GetBaggageOptionsReq.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.GetBaggageOptionsReq} returns this
 */
proto.airplane.web.GetBaggageOptionsReq.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.airplane.web.GetBaggageOptionsReq.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.GetBaggageOptionsReq} returns this
 */
proto.airplane.web.GetBaggageOptionsReq.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string itinerary_id = 3;
 * @return {string}
 */
proto.airplane.web.GetBaggageOptionsReq.prototype.getItineraryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.GetBaggageOptionsReq} returns this
 */
proto.airplane.web.GetBaggageOptionsReq.prototype.setItineraryId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_domestic = 4;
 * @return {boolean}
 */
proto.airplane.web.GetBaggageOptionsReq.prototype.getIsDomestic = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web.GetBaggageOptionsReq} returns this
 */
proto.airplane.web.GetBaggageOptionsReq.prototype.setIsDomestic = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web.GetBaggageOptionsRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.GetBaggageOptionsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.GetBaggageOptionsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.GetBaggageOptionsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.GetBaggageOptionsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    airplane_flight_pb.BaggageOption.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.GetBaggageOptionsRes}
 */
proto.airplane.web.GetBaggageOptionsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.GetBaggageOptionsRes;
  return proto.airplane.web.GetBaggageOptionsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.GetBaggageOptionsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.GetBaggageOptionsRes}
 */
proto.airplane.web.GetBaggageOptionsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new airplane_flight_pb.BaggageOption;
      reader.readMessage(value,airplane_flight_pb.BaggageOption.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.GetBaggageOptionsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.GetBaggageOptionsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.GetBaggageOptionsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.GetBaggageOptionsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      airplane_flight_pb.BaggageOption.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web.GetBaggageOptionsRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web.GetBaggageOptionsRes} returns this
 */
proto.airplane.web.GetBaggageOptionsRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web.GetBaggageOptionsRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.GetBaggageOptionsRes} returns this
 */
proto.airplane.web.GetBaggageOptionsRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated airplane.BaggageOption items = 3;
 * @return {!Array<!proto.airplane.BaggageOption>}
 */
proto.airplane.web.GetBaggageOptionsRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.BaggageOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, airplane_flight_pb.BaggageOption, 3));
};


/**
 * @param {!Array<!proto.airplane.BaggageOption>} value
 * @return {!proto.airplane.web.GetBaggageOptionsRes} returns this
*/
proto.airplane.web.GetBaggageOptionsRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.BaggageOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.BaggageOption}
 */
proto.airplane.web.GetBaggageOptionsRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.BaggageOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.GetBaggageOptionsRes} returns this
 */
proto.airplane.web.GetBaggageOptionsRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.IssueTicketReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.IssueTicketReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.IssueTicketReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.IssueTicketReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isConfirmChangeTime: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.IssueTicketReq}
 */
proto.airplane.web.IssueTicketReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.IssueTicketReq;
  return proto.airplane.web.IssueTicketReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.IssueTicketReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.IssueTicketReq}
 */
proto.airplane.web.IssueTicketReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsConfirmChangeTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.IssueTicketReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.IssueTicketReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.IssueTicketReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.IssueTicketReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsConfirmChangeTime();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string booking_code = 1;
 * @return {string}
 */
proto.airplane.web.IssueTicketReq.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.IssueTicketReq} returns this
 */
proto.airplane.web.IssueTicketReq.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_confirm_change_time = 2;
 * @return {boolean}
 */
proto.airplane.web.IssueTicketReq.prototype.getIsConfirmChangeTime = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web.IssueTicketReq} returns this
 */
proto.airplane.web.IssueTicketReq.prototype.setIsConfirmChangeTime = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.IssueTicketRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.IssueTicketRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.IssueTicketRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.IssueTicketRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.airplane.web.RetrieveBookingFlightRes.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.IssueTicketRes}
 */
proto.airplane.web.IssueTicketRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.IssueTicketRes;
  return proto.airplane.web.IssueTicketRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.IssueTicketRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.IssueTicketRes}
 */
proto.airplane.web.IssueTicketRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web.RetrieveBookingFlightRes.Data;
      reader.readMessage(value,proto.airplane.web.RetrieveBookingFlightRes.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.IssueTicketRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.IssueTicketRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.IssueTicketRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.IssueTicketRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.airplane.web.RetrieveBookingFlightRes.Data.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web.IssueTicketRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web.IssueTicketRes} returns this
 */
proto.airplane.web.IssueTicketRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web.IssueTicketRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.IssueTicketRes} returns this
 */
proto.airplane.web.IssueTicketRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional RetrieveBookingFlightRes.Data data = 3;
 * @return {?proto.airplane.web.RetrieveBookingFlightRes.Data}
 */
proto.airplane.web.IssueTicketRes.prototype.getData = function() {
  return /** @type{?proto.airplane.web.RetrieveBookingFlightRes.Data} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web.RetrieveBookingFlightRes.Data, 3));
};


/**
 * @param {?proto.airplane.web.RetrieveBookingFlightRes.Data|undefined} value
 * @return {!proto.airplane.web.IssueTicketRes} returns this
*/
proto.airplane.web.IssueTicketRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.IssueTicketRes} returns this
 */
proto.airplane.web.IssueTicketRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.IssueTicketRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.MarkBookingAsFailedAndCancelReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.MarkBookingAsFailedAndCancelReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.MarkBookingAsFailedAndCancelReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.MarkBookingAsFailedAndCancelReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingCode: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.MarkBookingAsFailedAndCancelReq}
 */
proto.airplane.web.MarkBookingAsFailedAndCancelReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.MarkBookingAsFailedAndCancelReq;
  return proto.airplane.web.MarkBookingAsFailedAndCancelReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.MarkBookingAsFailedAndCancelReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.MarkBookingAsFailedAndCancelReq}
 */
proto.airplane.web.MarkBookingAsFailedAndCancelReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.MarkBookingAsFailedAndCancelReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.MarkBookingAsFailedAndCancelReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.MarkBookingAsFailedAndCancelReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.MarkBookingAsFailedAndCancelReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string booking_code = 1;
 * @return {string}
 */
proto.airplane.web.MarkBookingAsFailedAndCancelReq.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.MarkBookingAsFailedAndCancelReq} returns this
 */
proto.airplane.web.MarkBookingAsFailedAndCancelReq.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.MarkBookingAsFailedAndCancelRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.MarkBookingAsFailedAndCancelRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.MarkBookingAsFailedAndCancelRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.MarkBookingAsFailedAndCancelRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.MarkBookingAsFailedAndCancelRes}
 */
proto.airplane.web.MarkBookingAsFailedAndCancelRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.MarkBookingAsFailedAndCancelRes;
  return proto.airplane.web.MarkBookingAsFailedAndCancelRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.MarkBookingAsFailedAndCancelRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.MarkBookingAsFailedAndCancelRes}
 */
proto.airplane.web.MarkBookingAsFailedAndCancelRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.MarkBookingAsFailedAndCancelRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.MarkBookingAsFailedAndCancelRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.MarkBookingAsFailedAndCancelRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.MarkBookingAsFailedAndCancelRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web.MarkBookingAsFailedAndCancelRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web.MarkBookingAsFailedAndCancelRes} returns this
 */
proto.airplane.web.MarkBookingAsFailedAndCancelRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web.MarkBookingAsFailedAndCancelRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.MarkBookingAsFailedAndCancelRes} returns this
 */
proto.airplane.web.MarkBookingAsFailedAndCancelRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web.FareCheckDomesticReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.FareCheckDomesticReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.FareCheckDomesticReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.FareCheckDomesticReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.FareCheckDomesticReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    flightsList: jspb.Message.toObjectList(msg.getFlightsList(),
    proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.FareCheckDomesticReq}
 */
proto.airplane.web.FareCheckDomesticReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.FareCheckDomesticReq;
  return proto.airplane.web.FareCheckDomesticReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.FareCheckDomesticReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.FareCheckDomesticReq}
 */
proto.airplane.web.FareCheckDomesticReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo;
      reader.readMessage(value,proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo.deserializeBinaryFromReader);
      msg.addFlights(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.FareCheckDomesticReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.FareCheckDomesticReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.FareCheckDomesticReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.FareCheckDomesticReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlightsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    flightId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo}
 */
proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo;
  return proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo}
 */
proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo} returns this
 */
proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string flight_id = 2;
 * @return {string}
 */
proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo} returns this
 */
proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated CheckFlightInfo flights = 1;
 * @return {!Array<!proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo>}
 */
proto.airplane.web.FareCheckDomesticReq.prototype.getFlightsList = function() {
  return /** @type{!Array<!proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo, 1));
};


/**
 * @param {!Array<!proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo>} value
 * @return {!proto.airplane.web.FareCheckDomesticReq} returns this
*/
proto.airplane.web.FareCheckDomesticReq.prototype.setFlightsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo}
 */
proto.airplane.web.FareCheckDomesticReq.prototype.addFlights = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.airplane.web.FareCheckDomesticReq.CheckFlightInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.FareCheckDomesticReq} returns this
 */
proto.airplane.web.FareCheckDomesticReq.prototype.clearFlightsList = function() {
  return this.setFlightsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.FareCheckDomesticRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.FareCheckDomesticRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.FareCheckDomesticRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.FareCheckDomesticRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.airplane.web.FareCheckDomesticRes.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.FareCheckDomesticRes}
 */
proto.airplane.web.FareCheckDomesticRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.FareCheckDomesticRes;
  return proto.airplane.web.FareCheckDomesticRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.FareCheckDomesticRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.FareCheckDomesticRes}
 */
proto.airplane.web.FareCheckDomesticRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web.FareCheckDomesticRes.Data;
      reader.readMessage(value,proto.airplane.web.FareCheckDomesticRes.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.FareCheckDomesticRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.FareCheckDomesticRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.FareCheckDomesticRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.FareCheckDomesticRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.airplane.web.FareCheckDomesticRes.Data.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web.FareCheckDomesticRes.Data.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.FareCheckDomesticRes.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.FareCheckDomesticRes.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.FareCheckDomesticRes.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.FareCheckDomesticRes.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itinerariesList: jspb.Message.toObjectList(msg.getItinerariesList(),
    airplane_hub_pb.HubItinerary.toObject, includeInstance),
    vat: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    fareData: (f = msg.getFareData()) && proto.airplane.web.FareData.toObject(includeInstance, f),
    flightType: jspb.Message.getFieldWithDefault(msg, 5, ""),
    tripType: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.FareCheckDomesticRes.Data}
 */
proto.airplane.web.FareCheckDomesticRes.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.FareCheckDomesticRes.Data;
  return proto.airplane.web.FareCheckDomesticRes.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.FareCheckDomesticRes.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.FareCheckDomesticRes.Data}
 */
proto.airplane.web.FareCheckDomesticRes.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 2:
      var value = new airplane_hub_pb.HubItinerary;
      reader.readMessage(value,airplane_hub_pb.HubItinerary.deserializeBinaryFromReader);
      msg.addItineraries(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVat(value);
      break;
    case 4:
      var value = new proto.airplane.web.FareData;
      reader.readMessage(value,proto.airplane.web.FareData.deserializeBinaryFromReader);
      msg.setFareData(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTripType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.FareCheckDomesticRes.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.FareCheckDomesticRes.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.FareCheckDomesticRes.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.FareCheckDomesticRes.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItinerariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      airplane_hub_pb.HubItinerary.serializeBinaryToWriter
    );
  }
  f = message.getVat();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getFareData();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.airplane.web.FareData.serializeBinaryToWriter
    );
  }
  f = message.getFlightType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTripType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.airplane.web.FareCheckDomesticRes.Data.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.FareCheckDomesticRes.Data} returns this
 */
proto.airplane.web.FareCheckDomesticRes.Data.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated airplane.HubItinerary itineraries = 2;
 * @return {!Array<!proto.airplane.HubItinerary>}
 */
proto.airplane.web.FareCheckDomesticRes.Data.prototype.getItinerariesList = function() {
  return /** @type{!Array<!proto.airplane.HubItinerary>} */ (
    jspb.Message.getRepeatedWrapperField(this, airplane_hub_pb.HubItinerary, 2));
};


/**
 * @param {!Array<!proto.airplane.HubItinerary>} value
 * @return {!proto.airplane.web.FareCheckDomesticRes.Data} returns this
*/
proto.airplane.web.FareCheckDomesticRes.Data.prototype.setItinerariesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.airplane.HubItinerary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.HubItinerary}
 */
proto.airplane.web.FareCheckDomesticRes.Data.prototype.addItineraries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.airplane.HubItinerary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.FareCheckDomesticRes.Data} returns this
 */
proto.airplane.web.FareCheckDomesticRes.Data.prototype.clearItinerariesList = function() {
  return this.setItinerariesList([]);
};


/**
 * optional bool vat = 3;
 * @return {boolean}
 */
proto.airplane.web.FareCheckDomesticRes.Data.prototype.getVat = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web.FareCheckDomesticRes.Data} returns this
 */
proto.airplane.web.FareCheckDomesticRes.Data.prototype.setVat = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional FareData fare_data = 4;
 * @return {?proto.airplane.web.FareData}
 */
proto.airplane.web.FareCheckDomesticRes.Data.prototype.getFareData = function() {
  return /** @type{?proto.airplane.web.FareData} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web.FareData, 4));
};


/**
 * @param {?proto.airplane.web.FareData|undefined} value
 * @return {!proto.airplane.web.FareCheckDomesticRes.Data} returns this
*/
proto.airplane.web.FareCheckDomesticRes.Data.prototype.setFareData = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.FareCheckDomesticRes.Data} returns this
 */
proto.airplane.web.FareCheckDomesticRes.Data.prototype.clearFareData = function() {
  return this.setFareData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.FareCheckDomesticRes.Data.prototype.hasFareData = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string flight_type = 5;
 * @return {string}
 */
proto.airplane.web.FareCheckDomesticRes.Data.prototype.getFlightType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.FareCheckDomesticRes.Data} returns this
 */
proto.airplane.web.FareCheckDomesticRes.Data.prototype.setFlightType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string trip_type = 6;
 * @return {string}
 */
proto.airplane.web.FareCheckDomesticRes.Data.prototype.getTripType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.FareCheckDomesticRes.Data} returns this
 */
proto.airplane.web.FareCheckDomesticRes.Data.prototype.setTripType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web.FareCheckDomesticRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web.FareCheckDomesticRes} returns this
 */
proto.airplane.web.FareCheckDomesticRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web.FareCheckDomesticRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.FareCheckDomesticRes} returns this
 */
proto.airplane.web.FareCheckDomesticRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Data data = 3;
 * @return {?proto.airplane.web.FareCheckDomesticRes.Data}
 */
proto.airplane.web.FareCheckDomesticRes.prototype.getData = function() {
  return /** @type{?proto.airplane.web.FareCheckDomesticRes.Data} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web.FareCheckDomesticRes.Data, 3));
};


/**
 * @param {?proto.airplane.web.FareCheckDomesticRes.Data|undefined} value
 * @return {!proto.airplane.web.FareCheckDomesticRes} returns this
*/
proto.airplane.web.FareCheckDomesticRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.FareCheckDomesticRes} returns this
 */
proto.airplane.web.FareCheckDomesticRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.FareCheckDomesticRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web.CalculationFlightFeeDomesticReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.CalculationFlightFeeDomesticReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.CalculationFlightFeeDomesticReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.CalculationFlightFeeDomesticReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.CalculationFlightFeeDomesticReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    flightsList: jspb.Message.toObjectList(msg.getFlightsList(),
    proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.CalculationFlightFeeDomesticReq}
 */
proto.airplane.web.CalculationFlightFeeDomesticReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.CalculationFlightFeeDomesticReq;
  return proto.airplane.web.CalculationFlightFeeDomesticReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.CalculationFlightFeeDomesticReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.CalculationFlightFeeDomesticReq}
 */
proto.airplane.web.CalculationFlightFeeDomesticReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair;
      reader.readMessage(value,proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair.deserializeBinaryFromReader);
      msg.addFlights(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.CalculationFlightFeeDomesticReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.CalculationFlightFeeDomesticReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.CalculationFlightFeeDomesticReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.CalculationFlightFeeDomesticReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlightsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    flightId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair}
 */
proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair;
  return proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair}
 */
proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair} returns this
 */
proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string flight_id = 2;
 * @return {string}
 */
proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair} returns this
 */
proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated FlightPair flights = 1;
 * @return {!Array<!proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair>}
 */
proto.airplane.web.CalculationFlightFeeDomesticReq.prototype.getFlightsList = function() {
  return /** @type{!Array<!proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair, 1));
};


/**
 * @param {!Array<!proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair>} value
 * @return {!proto.airplane.web.CalculationFlightFeeDomesticReq} returns this
*/
proto.airplane.web.CalculationFlightFeeDomesticReq.prototype.setFlightsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair}
 */
proto.airplane.web.CalculationFlightFeeDomesticReq.prototype.addFlights = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.airplane.web.CalculationFlightFeeDomesticReq.FlightPair, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.CalculationFlightFeeDomesticReq} returns this
 */
proto.airplane.web.CalculationFlightFeeDomesticReq.prototype.clearFlightsList = function() {
  return this.setFlightsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web.CalculationFlightFeeDomesticRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.CalculationFlightFeeDomesticRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.CalculationFlightFeeDomesticRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.CalculationFlightFeeDomesticRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.CalculationFlightFeeDomesticRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    airplane_base_pb.CalculationFlightFeeResData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.CalculationFlightFeeDomesticRes}
 */
proto.airplane.web.CalculationFlightFeeDomesticRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.CalculationFlightFeeDomesticRes;
  return proto.airplane.web.CalculationFlightFeeDomesticRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.CalculationFlightFeeDomesticRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.CalculationFlightFeeDomesticRes}
 */
proto.airplane.web.CalculationFlightFeeDomesticRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new airplane_base_pb.CalculationFlightFeeResData;
      reader.readMessage(value,airplane_base_pb.CalculationFlightFeeResData.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.CalculationFlightFeeDomesticRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.CalculationFlightFeeDomesticRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.CalculationFlightFeeDomesticRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.CalculationFlightFeeDomesticRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      airplane_base_pb.CalculationFlightFeeResData.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web.CalculationFlightFeeDomesticRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web.CalculationFlightFeeDomesticRes} returns this
 */
proto.airplane.web.CalculationFlightFeeDomesticRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web.CalculationFlightFeeDomesticRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.CalculationFlightFeeDomesticRes} returns this
 */
proto.airplane.web.CalculationFlightFeeDomesticRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated airplane.CalculationFlightFeeResData items = 3;
 * @return {!Array<!proto.airplane.CalculationFlightFeeResData>}
 */
proto.airplane.web.CalculationFlightFeeDomesticRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.CalculationFlightFeeResData>} */ (
    jspb.Message.getRepeatedWrapperField(this, airplane_base_pb.CalculationFlightFeeResData, 3));
};


/**
 * @param {!Array<!proto.airplane.CalculationFlightFeeResData>} value
 * @return {!proto.airplane.web.CalculationFlightFeeDomesticRes} returns this
*/
proto.airplane.web.CalculationFlightFeeDomesticRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.CalculationFlightFeeResData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.CalculationFlightFeeResData}
 */
proto.airplane.web.CalculationFlightFeeDomesticRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.CalculationFlightFeeResData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.CalculationFlightFeeDomesticRes} returns this
 */
proto.airplane.web.CalculationFlightFeeDomesticRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web.BookingFlightDomesticRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.BookingFlightDomesticRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.BookingFlightDomesticRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.BookingFlightDomesticRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.BookingFlightDomesticRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bookingInfoList: jspb.Message.toObjectList(msg.getBookingInfoList(),
    proto.airplane.web.FlightBookingInfo.toObject, includeInstance),
    orderId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    bookingCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    fee: (f = msg.getFee()) && airplane_base_pb.CalculationFlightFeeResData.toObject(includeInstance, f),
    expiredAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
    sessionId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    bookingId: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.BookingFlightDomesticRes}
 */
proto.airplane.web.BookingFlightDomesticRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.BookingFlightDomesticRes;
  return proto.airplane.web.BookingFlightDomesticRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.BookingFlightDomesticRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.BookingFlightDomesticRes}
 */
proto.airplane.web.BookingFlightDomesticRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web.FlightBookingInfo;
      reader.readMessage(value,proto.airplane.web.FlightBookingInfo.deserializeBinaryFromReader);
      msg.addBookingInfo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    case 6:
      var value = new airplane_base_pb.CalculationFlightFeeResData;
      reader.readMessage(value,airplane_base_pb.CalculationFlightFeeResData.deserializeBinaryFromReader);
      msg.setFee(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiredAt(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.BookingFlightDomesticRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.BookingFlightDomesticRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.BookingFlightDomesticRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.BookingFlightDomesticRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBookingInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.airplane.web.FlightBookingInfo.serializeBinaryToWriter
    );
  }
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFee();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      airplane_base_pb.CalculationFlightFeeResData.serializeBinaryToWriter
    );
  }
  f = message.getExpiredAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getBookingId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web.BookingFlightDomesticRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web.BookingFlightDomesticRes} returns this
 */
proto.airplane.web.BookingFlightDomesticRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web.BookingFlightDomesticRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightDomesticRes} returns this
 */
proto.airplane.web.BookingFlightDomesticRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated FlightBookingInfo booking_info = 3;
 * @return {!Array<!proto.airplane.web.FlightBookingInfo>}
 */
proto.airplane.web.BookingFlightDomesticRes.prototype.getBookingInfoList = function() {
  return /** @type{!Array<!proto.airplane.web.FlightBookingInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web.FlightBookingInfo, 3));
};


/**
 * @param {!Array<!proto.airplane.web.FlightBookingInfo>} value
 * @return {!proto.airplane.web.BookingFlightDomesticRes} returns this
*/
proto.airplane.web.BookingFlightDomesticRes.prototype.setBookingInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.web.FlightBookingInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web.FlightBookingInfo}
 */
proto.airplane.web.BookingFlightDomesticRes.prototype.addBookingInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.web.FlightBookingInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.BookingFlightDomesticRes} returns this
 */
proto.airplane.web.BookingFlightDomesticRes.prototype.clearBookingInfoList = function() {
  return this.setBookingInfoList([]);
};


/**
 * optional string order_id = 4;
 * @return {string}
 */
proto.airplane.web.BookingFlightDomesticRes.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightDomesticRes} returns this
 */
proto.airplane.web.BookingFlightDomesticRes.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string booking_code = 5;
 * @return {string}
 */
proto.airplane.web.BookingFlightDomesticRes.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightDomesticRes} returns this
 */
proto.airplane.web.BookingFlightDomesticRes.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional airplane.CalculationFlightFeeResData fee = 6;
 * @return {?proto.airplane.CalculationFlightFeeResData}
 */
proto.airplane.web.BookingFlightDomesticRes.prototype.getFee = function() {
  return /** @type{?proto.airplane.CalculationFlightFeeResData} */ (
    jspb.Message.getWrapperField(this, airplane_base_pb.CalculationFlightFeeResData, 6));
};


/**
 * @param {?proto.airplane.CalculationFlightFeeResData|undefined} value
 * @return {!proto.airplane.web.BookingFlightDomesticRes} returns this
*/
proto.airplane.web.BookingFlightDomesticRes.prototype.setFee = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.BookingFlightDomesticRes} returns this
 */
proto.airplane.web.BookingFlightDomesticRes.prototype.clearFee = function() {
  return this.setFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.BookingFlightDomesticRes.prototype.hasFee = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 expired_at = 7;
 * @return {number}
 */
proto.airplane.web.BookingFlightDomesticRes.prototype.getExpiredAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.BookingFlightDomesticRes} returns this
 */
proto.airplane.web.BookingFlightDomesticRes.prototype.setExpiredAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string session_id = 8;
 * @return {string}
 */
proto.airplane.web.BookingFlightDomesticRes.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightDomesticRes} returns this
 */
proto.airplane.web.BookingFlightDomesticRes.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string booking_id = 9;
 * @return {string}
 */
proto.airplane.web.BookingFlightDomesticRes.prototype.getBookingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightDomesticRes} returns this
 */
proto.airplane.web.BookingFlightDomesticRes.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.FlightBookingInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.FlightBookingInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.FlightBookingInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.FlightBookingInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    flightId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    key: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bookingStatus: jspb.Message.getFieldWithDefault(msg, 3, 0),
    itineraryIndex: jspb.Message.getFieldWithDefault(msg, 4, 0),
    itineraryId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.FlightBookingInfo}
 */
proto.airplane.web.FlightBookingInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.FlightBookingInfo;
  return proto.airplane.web.FlightBookingInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.FlightBookingInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.FlightBookingInfo}
 */
proto.airplane.web.FlightBookingInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 3:
      var value = /** @type {!proto.airplane.BookingStatus} */ (reader.readEnum());
      msg.setBookingStatus(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setItineraryIndex(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setItineraryId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.FlightBookingInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.FlightBookingInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.FlightBookingInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.FlightBookingInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBookingStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getItineraryIndex();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getItineraryId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string flight_id = 1;
 * @return {string}
 */
proto.airplane.web.FlightBookingInfo.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.FlightBookingInfo} returns this
 */
proto.airplane.web.FlightBookingInfo.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.airplane.web.FlightBookingInfo.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.FlightBookingInfo} returns this
 */
proto.airplane.web.FlightBookingInfo.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional airplane.BookingStatus booking_status = 3;
 * @return {!proto.airplane.BookingStatus}
 */
proto.airplane.web.FlightBookingInfo.prototype.getBookingStatus = function() {
  return /** @type {!proto.airplane.BookingStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.airplane.BookingStatus} value
 * @return {!proto.airplane.web.FlightBookingInfo} returns this
 */
proto.airplane.web.FlightBookingInfo.prototype.setBookingStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int32 itinerary_index = 4;
 * @return {number}
 */
proto.airplane.web.FlightBookingInfo.prototype.getItineraryIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.FlightBookingInfo} returns this
 */
proto.airplane.web.FlightBookingInfo.prototype.setItineraryIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string itinerary_id = 5;
 * @return {string}
 */
proto.airplane.web.FlightBookingInfo.prototype.getItineraryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.FlightBookingInfo} returns this
 */
proto.airplane.web.FlightBookingInfo.prototype.setItineraryId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web.BookingFlightDomesticReq.repeatedFields_ = [5,13,17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.BookingFlightDomesticReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.BookingFlightDomesticReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.BookingFlightDomesticReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    flightId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 3, ""),
    contactInfo: (f = msg.getContactInfo()) && proto.airplane.web.BookingFlightDomesticReq.ContactInfo.toObject(includeInstance, f),
    passengersList: jspb.Message.toObjectList(msg.getPassengersList(),
    proto.airplane.web.BookingFlightDomesticReq.Passenger.toObject, includeInstance),
    note: jspb.Message.getFieldWithDefault(msg, 6, ""),
    isPublic: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    key: jspb.Message.getFieldWithDefault(msg, 8, ""),
    confirmPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    invoicingInformation: (f = msg.getInvoicingInformation()) && airplane_flight_pb.InvoicingInformation.toObject(includeInstance, f),
    timezone: jspb.Message.getFieldWithDefault(msg, 11, ""),
    makeupPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    domesticFlightPairsList: jspb.Message.toObjectList(msg.getDomesticFlightPairsList(),
    proto.airplane.web.BookingFlightDomesticReq.FlightPair.toObject, includeInstance),
    isDraftForOverride: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    affiliateMarketingNetworkInfo: (f = msg.getAffiliateMarketingNetworkInfo()) && airplane_flight_pb.AffiliateMarketingNetworkInfo.toObject(includeInstance, f),
    staticLastTicketDate: jspb.Message.getFieldWithDefault(msg, 16, 0),
    promotionCodesList: (f = jspb.Message.getRepeatedField(msg, 17)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.BookingFlightDomesticReq}
 */
proto.airplane.web.BookingFlightDomesticReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.BookingFlightDomesticReq;
  return proto.airplane.web.BookingFlightDomesticReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.BookingFlightDomesticReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.BookingFlightDomesticReq}
 */
proto.airplane.web.BookingFlightDomesticReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 4:
      var value = new proto.airplane.web.BookingFlightDomesticReq.ContactInfo;
      reader.readMessage(value,proto.airplane.web.BookingFlightDomesticReq.ContactInfo.deserializeBinaryFromReader);
      msg.setContactInfo(value);
      break;
    case 5:
      var value = new proto.airplane.web.BookingFlightDomesticReq.Passenger;
      reader.readMessage(value,proto.airplane.web.BookingFlightDomesticReq.Passenger.deserializeBinaryFromReader);
      msg.addPassengers(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPublic(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setConfirmPrice(value);
      break;
    case 10:
      var value = new airplane_flight_pb.InvoicingInformation;
      reader.readMessage(value,airplane_flight_pb.InvoicingInformation.deserializeBinaryFromReader);
      msg.setInvoicingInformation(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMakeupPrice(value);
      break;
    case 13:
      var value = new proto.airplane.web.BookingFlightDomesticReq.FlightPair;
      reader.readMessage(value,proto.airplane.web.BookingFlightDomesticReq.FlightPair.deserializeBinaryFromReader);
      msg.addDomesticFlightPairs(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDraftForOverride(value);
      break;
    case 15:
      var value = new airplane_flight_pb.AffiliateMarketingNetworkInfo;
      reader.readMessage(value,airplane_flight_pb.AffiliateMarketingNetworkInfo.deserializeBinaryFromReader);
      msg.setAffiliateMarketingNetworkInfo(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStaticLastTicketDate(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.addPromotionCodes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.BookingFlightDomesticReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.BookingFlightDomesticReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.BookingFlightDomesticReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getContactInfo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.airplane.web.BookingFlightDomesticReq.ContactInfo.serializeBinaryToWriter
    );
  }
  f = message.getPassengersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.airplane.web.BookingFlightDomesticReq.Passenger.serializeBinaryToWriter
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIsPublic();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getConfirmPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getInvoicingInformation();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      airplane_flight_pb.InvoicingInformation.serializeBinaryToWriter
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getMakeupPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getDomesticFlightPairsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.airplane.web.BookingFlightDomesticReq.FlightPair.serializeBinaryToWriter
    );
  }
  f = message.getIsDraftForOverride();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getAffiliateMarketingNetworkInfo();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      airplane_flight_pb.AffiliateMarketingNetworkInfo.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getPromotionCodesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      17,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.repeatedFields_ = [10,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.BookingFlightDomesticReq.Passenger.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.BookingFlightDomesticReq.Passenger} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    gender: jspb.Message.getFieldWithDefault(msg, 4, 0),
    dob: jspb.Message.getFieldWithDefault(msg, 5, 0),
    nationality: jspb.Message.getFieldWithDefault(msg, 6, ""),
    passportNumber: jspb.Message.getFieldWithDefault(msg, 7, ""),
    expirationDate: jspb.Message.getFieldWithDefault(msg, 8, 0),
    idIssueCountry: jspb.Message.getFieldWithDefault(msg, 9, ""),
    seatOptionsList: jspb.Message.toObjectList(msg.getSeatOptionsList(),
    proto.airplane.web.SeatSelection.toObject, includeInstance),
    baggageOptionsList: jspb.Message.toObjectList(msg.getBaggageOptionsList(),
    proto.airplane.web.BaggageSelection.toObject, includeInstance),
    expenseRequestId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    phoneCode: jspb.Message.getFieldWithDefault(msg, 13, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.BookingFlightDomesticReq.Passenger}
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.BookingFlightDomesticReq.Passenger;
  return proto.airplane.web.BookingFlightDomesticReq.Passenger.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.BookingFlightDomesticReq.Passenger} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.BookingFlightDomesticReq.Passenger}
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 3:
      var value = /** @type {!proto.airplane.PaxType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {!proto.base.GENDER} */ (reader.readEnum());
      msg.setGender(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDob(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNationality(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassportNumber(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpirationDate(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdIssueCountry(value);
      break;
    case 10:
      var value = new proto.airplane.web.SeatSelection;
      reader.readMessage(value,proto.airplane.web.SeatSelection.deserializeBinaryFromReader);
      msg.addSeatOptions(value);
      break;
    case 11:
      var value = new proto.airplane.web.BaggageSelection;
      reader.readMessage(value,proto.airplane.web.BaggageSelection.deserializeBinaryFromReader);
      msg.addBaggageOptions(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpenseRequestId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneCode(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.BookingFlightDomesticReq.Passenger.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.BookingFlightDomesticReq.Passenger} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getGender();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSeatOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.airplane.web.SeatSelection.serializeBinaryToWriter
    );
  }
  f = message.getBaggageOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.airplane.web.BaggageSelection.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeString(
      12,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeString(
      13,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string first_name = 1;
 * @return {string}
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq.Passenger} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string last_name = 2;
 * @return {string}
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq.Passenger} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional airplane.PaxType type = 3;
 * @return {!proto.airplane.PaxType}
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.getType = function() {
  return /** @type {!proto.airplane.PaxType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.airplane.PaxType} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq.Passenger} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional base.GENDER gender = 4;
 * @return {!proto.base.GENDER}
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.getGender = function() {
  return /** @type {!proto.base.GENDER} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.base.GENDER} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq.Passenger} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.setGender = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int64 dob = 5;
 * @return {number}
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.getDob = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq.Passenger} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.setDob = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web.BookingFlightDomesticReq.Passenger} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.clearDob = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.hasDob = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string nationality = 6;
 * @return {string}
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.getNationality = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq.Passenger} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.setNationality = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web.BookingFlightDomesticReq.Passenger} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.clearNationality = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.hasNationality = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string passport_number = 7;
 * @return {string}
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.getPassportNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq.Passenger} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.setPassportNumber = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web.BookingFlightDomesticReq.Passenger} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.clearPassportNumber = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.hasPassportNumber = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 expiration_date = 8;
 * @return {number}
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.getExpirationDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq.Passenger} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.setExpirationDate = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web.BookingFlightDomesticReq.Passenger} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.clearExpirationDate = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.hasExpirationDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string id_issue_country = 9;
 * @return {string}
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.getIdIssueCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq.Passenger} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.setIdIssueCountry = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web.BookingFlightDomesticReq.Passenger} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.clearIdIssueCountry = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.hasIdIssueCountry = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated SeatSelection seat_options = 10;
 * @return {!Array<!proto.airplane.web.SeatSelection>}
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.getSeatOptionsList = function() {
  return /** @type{!Array<!proto.airplane.web.SeatSelection>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web.SeatSelection, 10));
};


/**
 * @param {!Array<!proto.airplane.web.SeatSelection>} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq.Passenger} returns this
*/
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.setSeatOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.airplane.web.SeatSelection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web.SeatSelection}
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.addSeatOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.airplane.web.SeatSelection, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.BookingFlightDomesticReq.Passenger} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.clearSeatOptionsList = function() {
  return this.setSeatOptionsList([]);
};


/**
 * repeated BaggageSelection baggage_options = 11;
 * @return {!Array<!proto.airplane.web.BaggageSelection>}
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.getBaggageOptionsList = function() {
  return /** @type{!Array<!proto.airplane.web.BaggageSelection>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web.BaggageSelection, 11));
};


/**
 * @param {!Array<!proto.airplane.web.BaggageSelection>} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq.Passenger} returns this
*/
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.setBaggageOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.airplane.web.BaggageSelection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web.BaggageSelection}
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.addBaggageOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.airplane.web.BaggageSelection, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.BookingFlightDomesticReq.Passenger} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.clearBaggageOptionsList = function() {
  return this.setBaggageOptionsList([]);
};


/**
 * optional string expense_request_id = 12;
 * @return {string}
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.getExpenseRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq.Passenger} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.setExpenseRequestId = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web.BookingFlightDomesticReq.Passenger} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.clearExpenseRequestId = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.hasExpenseRequestId = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string phone_code = 13;
 * @return {string}
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.getPhoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq.Passenger} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.setPhoneCode = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web.BookingFlightDomesticReq.Passenger} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.clearPhoneCode = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.hasPhoneCode = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string phone_number = 14;
 * @return {string}
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq.Passenger} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web.BookingFlightDomesticReq.Passenger} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.clearPhoneNumber = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.BookingFlightDomesticReq.Passenger.prototype.hasPhoneNumber = function() {
  return jspb.Message.getField(this, 14) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.BookingFlightDomesticReq.ContactInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.BookingFlightDomesticReq.ContactInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.BookingFlightDomesticReq.ContactInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.BookingFlightDomesticReq.ContactInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phoneCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    email: jspb.Message.getFieldWithDefault(msg, 6, ""),
    address: jspb.Message.getFieldWithDefault(msg, 7, ""),
    note: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.BookingFlightDomesticReq.ContactInfo}
 */
proto.airplane.web.BookingFlightDomesticReq.ContactInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.BookingFlightDomesticReq.ContactInfo;
  return proto.airplane.web.BookingFlightDomesticReq.ContactInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.BookingFlightDomesticReq.ContactInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.BookingFlightDomesticReq.ContactInfo}
 */
proto.airplane.web.BookingFlightDomesticReq.ContactInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.BookingFlightDomesticReq.ContactInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.BookingFlightDomesticReq.ContactInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.BookingFlightDomesticReq.ContactInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.BookingFlightDomesticReq.ContactInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhoneCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.airplane.web.BookingFlightDomesticReq.ContactInfo.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq.ContactInfo} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.ContactInfo.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.airplane.web.BookingFlightDomesticReq.ContactInfo.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq.ContactInfo} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.ContactInfo.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.airplane.web.BookingFlightDomesticReq.ContactInfo.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq.ContactInfo} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.ContactInfo.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phone_code = 4;
 * @return {string}
 */
proto.airplane.web.BookingFlightDomesticReq.ContactInfo.prototype.getPhoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq.ContactInfo} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.ContactInfo.prototype.setPhoneCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string phone_number = 5;
 * @return {string}
 */
proto.airplane.web.BookingFlightDomesticReq.ContactInfo.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq.ContactInfo} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.ContactInfo.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string email = 6;
 * @return {string}
 */
proto.airplane.web.BookingFlightDomesticReq.ContactInfo.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq.ContactInfo} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.ContactInfo.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string address = 7;
 * @return {string}
 */
proto.airplane.web.BookingFlightDomesticReq.ContactInfo.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq.ContactInfo} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.ContactInfo.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string note = 8;
 * @return {string}
 */
proto.airplane.web.BookingFlightDomesticReq.ContactInfo.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq.ContactInfo} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.ContactInfo.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.BookingFlightDomesticReq.FlightPair.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.BookingFlightDomesticReq.FlightPair.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.BookingFlightDomesticReq.FlightPair} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.BookingFlightDomesticReq.FlightPair.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    flightId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.BookingFlightDomesticReq.FlightPair}
 */
proto.airplane.web.BookingFlightDomesticReq.FlightPair.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.BookingFlightDomesticReq.FlightPair;
  return proto.airplane.web.BookingFlightDomesticReq.FlightPair.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.BookingFlightDomesticReq.FlightPair} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.BookingFlightDomesticReq.FlightPair}
 */
proto.airplane.web.BookingFlightDomesticReq.FlightPair.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.BookingFlightDomesticReq.FlightPair.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.BookingFlightDomesticReq.FlightPair.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.BookingFlightDomesticReq.FlightPair} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.BookingFlightDomesticReq.FlightPair.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.airplane.web.BookingFlightDomesticReq.FlightPair.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq.FlightPair} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.FlightPair.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string flight_id = 2;
 * @return {string}
 */
proto.airplane.web.BookingFlightDomesticReq.FlightPair.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq.FlightPair} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.FlightPair.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string flight_id = 2;
 * @return {string}
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string currency = 3;
 * @return {string}
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ContactInfo contact_info = 4;
 * @return {?proto.airplane.web.BookingFlightDomesticReq.ContactInfo}
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.getContactInfo = function() {
  return /** @type{?proto.airplane.web.BookingFlightDomesticReq.ContactInfo} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web.BookingFlightDomesticReq.ContactInfo, 4));
};


/**
 * @param {?proto.airplane.web.BookingFlightDomesticReq.ContactInfo|undefined} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq} returns this
*/
proto.airplane.web.BookingFlightDomesticReq.prototype.setContactInfo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.BookingFlightDomesticReq} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.clearContactInfo = function() {
  return this.setContactInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.hasContactInfo = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated Passenger passengers = 5;
 * @return {!Array<!proto.airplane.web.BookingFlightDomesticReq.Passenger>}
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.getPassengersList = function() {
  return /** @type{!Array<!proto.airplane.web.BookingFlightDomesticReq.Passenger>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web.BookingFlightDomesticReq.Passenger, 5));
};


/**
 * @param {!Array<!proto.airplane.web.BookingFlightDomesticReq.Passenger>} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq} returns this
*/
proto.airplane.web.BookingFlightDomesticReq.prototype.setPassengersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.airplane.web.BookingFlightDomesticReq.Passenger=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web.BookingFlightDomesticReq.Passenger}
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.addPassengers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.airplane.web.BookingFlightDomesticReq.Passenger, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.BookingFlightDomesticReq} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.clearPassengersList = function() {
  return this.setPassengersList([]);
};


/**
 * optional string note = 6;
 * @return {string}
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool is_public = 7;
 * @return {boolean}
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.getIsPublic = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.setIsPublic = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string key = 8;
 * @return {string}
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional double confirm_price = 9;
 * @return {number}
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.getConfirmPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.setConfirmPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional airplane.InvoicingInformation invoicing_information = 10;
 * @return {?proto.airplane.InvoicingInformation}
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.getInvoicingInformation = function() {
  return /** @type{?proto.airplane.InvoicingInformation} */ (
    jspb.Message.getWrapperField(this, airplane_flight_pb.InvoicingInformation, 10));
};


/**
 * @param {?proto.airplane.InvoicingInformation|undefined} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq} returns this
*/
proto.airplane.web.BookingFlightDomesticReq.prototype.setInvoicingInformation = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.BookingFlightDomesticReq} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.clearInvoicingInformation = function() {
  return this.setInvoicingInformation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.hasInvoicingInformation = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string timezone = 11;
 * @return {string}
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional double makeup_price = 12;
 * @return {number}
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.getMakeupPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.setMakeupPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * repeated FlightPair domestic_flight_pairs = 13;
 * @return {!Array<!proto.airplane.web.BookingFlightDomesticReq.FlightPair>}
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.getDomesticFlightPairsList = function() {
  return /** @type{!Array<!proto.airplane.web.BookingFlightDomesticReq.FlightPair>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web.BookingFlightDomesticReq.FlightPair, 13));
};


/**
 * @param {!Array<!proto.airplane.web.BookingFlightDomesticReq.FlightPair>} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq} returns this
*/
proto.airplane.web.BookingFlightDomesticReq.prototype.setDomesticFlightPairsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.airplane.web.BookingFlightDomesticReq.FlightPair=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web.BookingFlightDomesticReq.FlightPair}
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.addDomesticFlightPairs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.airplane.web.BookingFlightDomesticReq.FlightPair, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.BookingFlightDomesticReq} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.clearDomesticFlightPairsList = function() {
  return this.setDomesticFlightPairsList([]);
};


/**
 * optional bool is_draft_for_override = 14;
 * @return {boolean}
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.getIsDraftForOverride = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.setIsDraftForOverride = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional airplane.AffiliateMarketingNetworkInfo affiliate_marketing_network_info = 15;
 * @return {?proto.airplane.AffiliateMarketingNetworkInfo}
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.getAffiliateMarketingNetworkInfo = function() {
  return /** @type{?proto.airplane.AffiliateMarketingNetworkInfo} */ (
    jspb.Message.getWrapperField(this, airplane_flight_pb.AffiliateMarketingNetworkInfo, 15));
};


/**
 * @param {?proto.airplane.AffiliateMarketingNetworkInfo|undefined} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq} returns this
*/
proto.airplane.web.BookingFlightDomesticReq.prototype.setAffiliateMarketingNetworkInfo = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.BookingFlightDomesticReq} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.clearAffiliateMarketingNetworkInfo = function() {
  return this.setAffiliateMarketingNetworkInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.hasAffiliateMarketingNetworkInfo = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional int64 static_last_ticket_date = 16;
 * @return {number}
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.getStaticLastTicketDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.setStaticLastTicketDate = function(value) {
  return jspb.Message.setField(this, 16, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web.BookingFlightDomesticReq} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.clearStaticLastTicketDate = function() {
  return jspb.Message.setField(this, 16, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.hasStaticLastTicketDate = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * repeated string promotion_codes = 17;
 * @return {!Array<string>}
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.getPromotionCodesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 17));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.airplane.web.BookingFlightDomesticReq} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.setPromotionCodesList = function(value) {
  return jspb.Message.setField(this, 17, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.airplane.web.BookingFlightDomesticReq} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.addPromotionCodes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 17, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.BookingFlightDomesticReq} returns this
 */
proto.airplane.web.BookingFlightDomesticReq.prototype.clearPromotionCodesList = function() {
  return this.setPromotionCodesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.SeatSelection.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.SeatSelection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.SeatSelection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.SeatSelection.toObject = function(includeInstance, msg) {
  var f, obj = {
    itineraryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    segmentIndex: jspb.Message.getFieldWithDefault(msg, 2, 0),
    seatCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    rowNumber: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.SeatSelection}
 */
proto.airplane.web.SeatSelection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.SeatSelection;
  return proto.airplane.web.SeatSelection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.SeatSelection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.SeatSelection}
 */
proto.airplane.web.SeatSelection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setItineraryId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSegmentIndex(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeatCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRowNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.SeatSelection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.SeatSelection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.SeatSelection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.SeatSelection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItineraryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSegmentIndex();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSeatCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRowNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string itinerary_id = 1;
 * @return {string}
 */
proto.airplane.web.SeatSelection.prototype.getItineraryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.SeatSelection} returns this
 */
proto.airplane.web.SeatSelection.prototype.setItineraryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 segment_index = 2;
 * @return {number}
 */
proto.airplane.web.SeatSelection.prototype.getSegmentIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.SeatSelection} returns this
 */
proto.airplane.web.SeatSelection.prototype.setSegmentIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string seat_code = 3;
 * @return {string}
 */
proto.airplane.web.SeatSelection.prototype.getSeatCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.SeatSelection} returns this
 */
proto.airplane.web.SeatSelection.prototype.setSeatCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string row_number = 4;
 * @return {string}
 */
proto.airplane.web.SeatSelection.prototype.getRowNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.SeatSelection} returns this
 */
proto.airplane.web.SeatSelection.prototype.setRowNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.BaggageSelection.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.BaggageSelection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.BaggageSelection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.BaggageSelection.toObject = function(includeInstance, msg) {
  var f, obj = {
    itineraryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    optionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.BaggageSelection}
 */
proto.airplane.web.BaggageSelection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.BaggageSelection;
  return proto.airplane.web.BaggageSelection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.BaggageSelection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.BaggageSelection}
 */
proto.airplane.web.BaggageSelection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setItineraryId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.BaggageSelection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.BaggageSelection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.BaggageSelection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.BaggageSelection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItineraryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string itinerary_id = 1;
 * @return {string}
 */
proto.airplane.web.BaggageSelection.prototype.getItineraryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BaggageSelection} returns this
 */
proto.airplane.web.BaggageSelection.prototype.setItineraryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string option_id = 2;
 * @return {string}
 */
proto.airplane.web.BaggageSelection.prototype.getOptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.BaggageSelection} returns this
 */
proto.airplane.web.BaggageSelection.prototype.setOptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web.SearchFlightDomesticReq.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.SearchFlightDomesticReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.SearchFlightDomesticReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.SearchFlightDomesticReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.SearchFlightDomesticReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.airplane.web.SearchFlightDomesticFilterReq.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f),
    sortsList: jspb.Message.toObjectList(msg.getSortsList(),
    proto.airplane.web.SortItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.SearchFlightDomesticReq}
 */
proto.airplane.web.SearchFlightDomesticReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.SearchFlightDomesticReq;
  return proto.airplane.web.SearchFlightDomesticReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.SearchFlightDomesticReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.SearchFlightDomesticReq}
 */
proto.airplane.web.SearchFlightDomesticReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web.SearchFlightDomesticFilterReq;
      reader.readMessage(value,proto.airplane.web.SearchFlightDomesticFilterReq.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var value = new proto.airplane.web.SortItem;
      reader.readMessage(value,proto.airplane.web.SortItem.deserializeBinaryFromReader);
      msg.addSorts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.SearchFlightDomesticReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.SearchFlightDomesticReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.SearchFlightDomesticReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.SearchFlightDomesticReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.airplane.web.SearchFlightDomesticFilterReq.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
  f = message.getSortsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.airplane.web.SortItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional SearchFlightDomesticFilterReq filter = 1;
 * @return {?proto.airplane.web.SearchFlightDomesticFilterReq}
 */
proto.airplane.web.SearchFlightDomesticReq.prototype.getFilter = function() {
  return /** @type{?proto.airplane.web.SearchFlightDomesticFilterReq} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web.SearchFlightDomesticFilterReq, 1));
};


/**
 * @param {?proto.airplane.web.SearchFlightDomesticFilterReq|undefined} value
 * @return {!proto.airplane.web.SearchFlightDomesticReq} returns this
*/
proto.airplane.web.SearchFlightDomesticReq.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.SearchFlightDomesticReq} returns this
 */
proto.airplane.web.SearchFlightDomesticReq.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.SearchFlightDomesticReq.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional base.PaginationReq pagination = 2;
 * @return {?proto.base.PaginationReq}
 */
proto.airplane.web.SearchFlightDomesticReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 2));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.airplane.web.SearchFlightDomesticReq} returns this
*/
proto.airplane.web.SearchFlightDomesticReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.SearchFlightDomesticReq} returns this
 */
proto.airplane.web.SearchFlightDomesticReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.SearchFlightDomesticReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated SortItem sorts = 3;
 * @return {!Array<!proto.airplane.web.SortItem>}
 */
proto.airplane.web.SearchFlightDomesticReq.prototype.getSortsList = function() {
  return /** @type{!Array<!proto.airplane.web.SortItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web.SortItem, 3));
};


/**
 * @param {!Array<!proto.airplane.web.SortItem>} value
 * @return {!proto.airplane.web.SearchFlightDomesticReq} returns this
*/
proto.airplane.web.SearchFlightDomesticReq.prototype.setSortsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.web.SortItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web.SortItem}
 */
proto.airplane.web.SearchFlightDomesticReq.prototype.addSorts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.web.SortItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.SearchFlightDomesticReq} returns this
 */
proto.airplane.web.SearchFlightDomesticReq.prototype.clearSortsList = function() {
  return this.setSortsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web.SearchFlightDomesticRes.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.SearchFlightDomesticRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.SearchFlightDomesticRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.SearchFlightDomesticRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.SearchFlightDomesticRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    airplane_domestic_flight_pb.DomesticFlightInfo.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f),
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    expiredAt: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.SearchFlightDomesticRes}
 */
proto.airplane.web.SearchFlightDomesticRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.SearchFlightDomesticRes;
  return proto.airplane.web.SearchFlightDomesticRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.SearchFlightDomesticRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.SearchFlightDomesticRes}
 */
proto.airplane.web.SearchFlightDomesticRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = new airplane_domestic_flight_pb.DomesticFlightInfo;
      reader.readMessage(value,airplane_domestic_flight_pb.DomesticFlightInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 3:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiredAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.SearchFlightDomesticRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.SearchFlightDomesticRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.SearchFlightDomesticRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.SearchFlightDomesticRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      airplane_domestic_flight_pb.DomesticFlightInfo.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getExpiredAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.airplane.web.SearchFlightDomesticRes.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.SearchFlightDomesticRes} returns this
 */
proto.airplane.web.SearchFlightDomesticRes.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated airplane.DomesticFlightInfo items = 2;
 * @return {!Array<!proto.airplane.DomesticFlightInfo>}
 */
proto.airplane.web.SearchFlightDomesticRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.DomesticFlightInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, airplane_domestic_flight_pb.DomesticFlightInfo, 2));
};


/**
 * @param {!Array<!proto.airplane.DomesticFlightInfo>} value
 * @return {!proto.airplane.web.SearchFlightDomesticRes} returns this
*/
proto.airplane.web.SearchFlightDomesticRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.airplane.DomesticFlightInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.DomesticFlightInfo}
 */
proto.airplane.web.SearchFlightDomesticRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.airplane.DomesticFlightInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web.SearchFlightDomesticRes} returns this
 */
proto.airplane.web.SearchFlightDomesticRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional base.PaginationRes pagination = 3;
 * @return {?proto.base.PaginationRes}
 */
proto.airplane.web.SearchFlightDomesticRes.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 3));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.airplane.web.SearchFlightDomesticRes} returns this
*/
proto.airplane.web.SearchFlightDomesticRes.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.SearchFlightDomesticRes} returns this
 */
proto.airplane.web.SearchFlightDomesticRes.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.SearchFlightDomesticRes.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool is_success = 4;
 * @return {boolean}
 */
proto.airplane.web.SearchFlightDomesticRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web.SearchFlightDomesticRes} returns this
 */
proto.airplane.web.SearchFlightDomesticRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string error_code = 5;
 * @return {string}
 */
proto.airplane.web.SearchFlightDomesticRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.SearchFlightDomesticRes} returns this
 */
proto.airplane.web.SearchFlightDomesticRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 expired_at = 6;
 * @return {number}
 */
proto.airplane.web.SearchFlightDomesticRes.prototype.getExpiredAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web.SearchFlightDomesticRes} returns this
 */
proto.airplane.web.SearchFlightDomesticRes.prototype.setExpiredAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web.SearchFlightDomesticFilterReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web.SearchFlightDomesticFilterReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web.SearchFlightDomesticFilterReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.SearchFlightDomesticFilterReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    passengerQuantity: (f = msg.getPassengerQuantity()) && proto.airplane.web.PassengerQuantity.toObject(includeInstance, f),
    flight: (f = msg.getFlight()) && proto.airplane.web.FlightReq.toObject(includeInstance, f),
    currency: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web.SearchFlightDomesticFilterReq}
 */
proto.airplane.web.SearchFlightDomesticFilterReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web.SearchFlightDomesticFilterReq;
  return proto.airplane.web.SearchFlightDomesticFilterReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web.SearchFlightDomesticFilterReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web.SearchFlightDomesticFilterReq}
 */
proto.airplane.web.SearchFlightDomesticFilterReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web.PassengerQuantity;
      reader.readMessage(value,proto.airplane.web.PassengerQuantity.deserializeBinaryFromReader);
      msg.setPassengerQuantity(value);
      break;
    case 2:
      var value = new proto.airplane.web.FlightReq;
      reader.readMessage(value,proto.airplane.web.FlightReq.deserializeBinaryFromReader);
      msg.setFlight(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web.SearchFlightDomesticFilterReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web.SearchFlightDomesticFilterReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web.SearchFlightDomesticFilterReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web.SearchFlightDomesticFilterReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPassengerQuantity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.airplane.web.PassengerQuantity.serializeBinaryToWriter
    );
  }
  f = message.getFlight();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.airplane.web.FlightReq.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional PassengerQuantity passenger_quantity = 1;
 * @return {?proto.airplane.web.PassengerQuantity}
 */
proto.airplane.web.SearchFlightDomesticFilterReq.prototype.getPassengerQuantity = function() {
  return /** @type{?proto.airplane.web.PassengerQuantity} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web.PassengerQuantity, 1));
};


/**
 * @param {?proto.airplane.web.PassengerQuantity|undefined} value
 * @return {!proto.airplane.web.SearchFlightDomesticFilterReq} returns this
*/
proto.airplane.web.SearchFlightDomesticFilterReq.prototype.setPassengerQuantity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.SearchFlightDomesticFilterReq} returns this
 */
proto.airplane.web.SearchFlightDomesticFilterReq.prototype.clearPassengerQuantity = function() {
  return this.setPassengerQuantity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.SearchFlightDomesticFilterReq.prototype.hasPassengerQuantity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional FlightReq flight = 2;
 * @return {?proto.airplane.web.FlightReq}
 */
proto.airplane.web.SearchFlightDomesticFilterReq.prototype.getFlight = function() {
  return /** @type{?proto.airplane.web.FlightReq} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web.FlightReq, 2));
};


/**
 * @param {?proto.airplane.web.FlightReq|undefined} value
 * @return {!proto.airplane.web.SearchFlightDomesticFilterReq} returns this
*/
proto.airplane.web.SearchFlightDomesticFilterReq.prototype.setFlight = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web.SearchFlightDomesticFilterReq} returns this
 */
proto.airplane.web.SearchFlightDomesticFilterReq.prototype.clearFlight = function() {
  return this.setFlight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web.SearchFlightDomesticFilterReq.prototype.hasFlight = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string currency = 3;
 * @return {string}
 */
proto.airplane.web.SearchFlightDomesticFilterReq.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web.SearchFlightDomesticFilterReq} returns this
 */
proto.airplane.web.SearchFlightDomesticFilterReq.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.airplane.web.SortItemType = {
  SORT_ITEM_TYPE_NONE: 0,
  SORT_ITEM_TYPE_PRICE: 1,
  SORT_ITEM_TYPE_FASTEST: 2
};

goog.object.extend(exports, proto.airplane.web);
