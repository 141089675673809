import { UserStatus } from '@api/partner/base_pb';
import { getApiErrorMessages } from '@core/utils';
import { useAppMutation, useToast } from '@sky-booking-config/hooks/internals';
import { EUserStatusPartner, IUserInfo } from '@tixlabs/grpc-client';
import { ICreatePartnerUserWithRolesReq } from '@tixlabs/grpc-client/web-partner-admin';
import {
  IUpdatePartnerUserReq,
  partnerService,
  partnerShopUserServiceClient,
} from '@tixlabs/grpc-client/web-partnership';
import { Dispatch, SetStateAction, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

export type TCreatePartnerIssuerUserForm = ICreatePartnerUserWithRolesReq;
export type TUpdateIssuerForm = IUpdatePartnerUserReq & {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
};

export const useCreateAndUpdateIssuer = () => {
  const toast = useToast();
  const navigate = useNavigate();

  // null is for idle, if not found => undefined
  const [issuerInfo, setIssuerInfo] = useState<IUserInfo>();

  const createIssuerMethod = useForm<TCreatePartnerIssuerUserForm>({
    defaultValues: {
      email: '',
      name: '',
      password: '',
      phoneNumber: '',
      phoneCode: '+84',
      status: EUserStatusPartner.ACTIVE,
      userName: '',
      rolesList: [],
    },
    mode: 'onBlur',
  });

  const updateIssuerMethod = useForm<TUpdateIssuerForm>({
    defaultValues: {
      id: '',
      email: '',
      phoneNumber: '',
      phoneCode: '+84',
      name: '',
      status: UserStatus.ACTIVE,
    },
    mode: 'onBlur',
  });

  const {
    mutateAsync: createPartnerUserWithRoles,
    isLoading: isLoadingCreateIssuer,
  } = useAppMutation({
    mutationKey: ['partnerShopUserServiceClient', 'createPartnerUserWithRoles'],
    mutationFn: partnerShopUserServiceClient.createPartnerUserWithRoles,
  });

  const {
    mutateAsync: retrievePartnerUser,
    isLoading: isLoadingGetIssuerDetail,
  } = useAppMutation({
    mutationKey: ['partnerShopUserServiceClient', 'retrievePartnerUser'],
    mutationFn: partnerShopUserServiceClient.retrievePartnerUser,
  });

  const {
    mutateAsync: updateUser,
    isLoading: isLoadingUpdateIssuer,
    isSuccess: isSuccessUpdateIssuer,
  } = useAppMutation({
    mutationKey: ['partnerShopUserServiceClient', 'updateUser'],
    mutationFn: partnerShopUserServiceClient.updateUser,
  });

  const {
    mutateAsync: updateOfficeProviders,
    isLoading: isLoadingUpdateOfficeProviders,
  } = useAppMutation({
    mutationKey: ['partnerService', 'updateOfficeProviders'],
    mutationFn: partnerService.updateOfficeProviders,
  });

  const getPartnerUserInfo = async (id: string) => {
    try {
      const { isSuccess, errorCode, data } = await retrievePartnerUser({ id });
      if (!isSuccess && errorCode) {
      }
      setIssuerInfo(data);
    } catch (error) {
      const msg = getApiErrorMessages(error)[0];
      console.log('Error get retrievePartnerUser info:', msg);
    }
  };

  const handleSubmitCreateIssuer = async (
    formData: TCreatePartnerIssuerUserForm
  ) => {
    try {
      const { errorCode, isSuccess } = await createPartnerUserWithRoles(
        formData
      );
      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }
      toast.success('Tạo người dùng thành công!');
      createIssuerMethod.reset();
      navigate('/dashboard/issuer-management');
    } catch (error) {
      const errCode = getApiErrorMessages(error)[0];
      switch (errCode) {
        case 'PARTNER_USER_NAME_IS_EXISTS':
          createIssuerMethod.setError('userName', {
            message: 'Tên tài khoản đã tồn tại!',
          });
          break;
        case 'INVALID_INPUT':
          toast.error('Thông tin tạo phí không hợp lệ!');
          break;
        case 'PERMISSION_DENIED':
          toast.error('Bạn không được quyền làm điều đó!');
          break;
        default:
          toast.error('Lỗi tạo người dùng!');
          break;
      }
    }
  };

  const handleSubmitUpdateIssuer = async (formData: TUpdateIssuerForm) => {
    try {
      const { isSuccess, errorCode } = await updateUser({ ...formData });
      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }
      toast.success('Cập nhật người dùng thành công!');
      navigate('/dashboard/issuer-management', { replace: true });
      formData.setIsModalOpen(false);
    } catch (error) {
      const msg = getApiErrorMessages(error)[0];
      switch (msg) {
        case 'INVALID_INPUT':
          toast.error('Thông tin tạo phí không hợp lệ!');
          break;
        case 'PERMISSION_DENIED':
          toast.error('Bạn không được quyền làm điều đó!');
          break;
        case 'PARTNER_NEW_PASSWORD_IS_OLD':
          toast.error('Mật khẩu mới phải khác mật khẩu cũ');
          break;
        case 'NOT_FOUND_ERROR':
          toast.error('Không tìm thấy người dùng');
          break;
        default:
          toast.error('Lỗi cập nhật người dùng!');
          break;
      }
    }
  };

  return {
    issuerInfo,
    createIssuerMethod,
    updateIssuerMethod,
    isLoadingGetIssuerDetail,
    isLoadingCreateIssuer,
    isLoadingUpdateIssuer,
    handleSubmitUpdateIssuer,
    handleSubmitCreateIssuer,
    getPartnerUserInfo,
  };
};

export default useCreateAndUpdateIssuer;
