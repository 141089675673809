import {
  ETransactionTypeBase,
  IPaginationRes,
  ISkyHubTransaction,
  ITransaction,
} from '@tixlabs/grpc-client';

import { useCurrency, useTime } from '@payment-portal/hooks/internals';
import {
  paymentMethodLabel,
  transactionTypeBaseLabel,
} from '@payment-portal/utils/constants/wallet';
import { Table } from 'antd';
import { ColumnProps } from 'antd/es/table';

type Props = {
  listTransaction: ISkyHubTransaction[];
  isLoading: boolean;
  pagination: IPaginationRes;
  onPageChange: (page: number) => void;
};

const TitleColumns = ({ title }: { title: string }) => {
  return <span className='text-neutral-7'>{title}</span>;
};
const TableContent = ({
  isLoading,
  listTransaction,
  pagination,
  onPageChange,
}: Props) => {
  const { formatDateTime } = useTime();
  const { formatPrice } = useCurrency();

  const columns: ColumnProps<ISkyHubTransaction>[] = [
    {
      title: () => {
        return <TitleColumns title='Mã đại lý' />;
      },
      dataIndex: 'agentInfo',
      key: 'agentInfoCode',
      render: (_, transaction) => {
        return <span>{transaction.agentInfo?.agentCode}</span>;
      },
    },
    {
      title: () => {
        return <TitleColumns title='Tên đại lý' />;
      },
      dataIndex: 'agentInfo',
      key: 'agentInfoName',
      render: (_, transaction) => {
        return <span>{transaction.agentInfo?.agentName}</span>;
      },
    },
    {
      title: () => {
        return <TitleColumns title='Mã đơn hàng' />;
      },
      dataIndex: 'bookingCode',
      key: 'bookingCode',
      render: (bookingCode) => {
        return <span>{bookingCode}</span>;
      },
    },
    {
      title: () => {
        return <TitleColumns title='Loại giao dịch' />;
      },
      dataIndex: 'type',
      key: 'type',
      render: (type) => {
        return transactionTypeBaseLabel[type];
      },
      className: 'min-w-[150px]',
    },
    {
      title: () => {
        return <TitleColumns title='Thời gian' />;
      },
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => {
        return <span>{formatDateTime(createdAt)}</span>;
      },
    },
    {
      title: () => {
        return <TitleColumns title='Số tiền' />;
      },
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => {
        return <span>{formatPrice(amount)}</span>;
      },
    },
    {
      title: () => {
        return <TitleColumns title='Phương thức thanh toán' />;
      },
      dataIndex: 'method',
      key: 'method',
      render: (method) => {
        return <span>{paymentMethodLabel[method]}</span>;
      },
      className: 'min-w-[150px]',
    },
  ];

  return (
    <Table
      dataSource={listTransaction.map((order, index) => {
        return {
          ...order,
          key: index,
        };
      })}
      columns={columns}
      size='small'
      rowClassName={(record, index) => {
        return 'whitespace-nowrap';
        // return cn('font-semibold whitespace-nowrap', {
        //   'bg-[#F8F8FB]': index % 2 === 0,
        // });
      }}
      // onRow={(record, rowIndex) => {
      //   return {
      //     onClick: () => {
      //       navigate(`./${record.bookingCode}`);
      //     },
      //   };
      // }}
      scroll={{ x: 1000 }}
      pagination={{
        pageSize: pagination.pageLimit,
        current: pagination.pageCurrent,
        total: pagination.totalRecord,
        className: 'space-x-2.5',
        onChange: onPageChange,
      }}
      loading={isLoading}
    />
  );
};

export default TableContent;
