import {
  Button,
  ButtonLoading,
  Form,
  FormGroupRadio,
  FormInput,
  FormInputPassword,
  FormInputUpload,
  FormPhoneCode,
  FormSelect,
  TInputUpload,
} from '@common-ui';
import { useUserStore } from '@sky-booking-config/hooks/stores';
import {
  PartnerUserStatusListCreateOptions,
  ROLE_USER,
  patternValidateEmail,
  patternValidatePhoneNumber,
  patternValidateUsername,
} from '@sky-booking-config/utils';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ToggleProvider from '../ToggleProvider';
import useCreateAndUpdateOfficeId from '../useCreateAndUpdateOfficeId';
import { SelectOptionItem } from '@tixlabs/types';
import toast from 'react-hot-toast';

type Props = {
  //
};

export const CreateOfficeIdCtn = (props: Props) => {
  const navigate = useNavigate();

  const { userData } = useUserStore();
  const [fileName, setFileName] = useState('');

  const {
    createOfficeIdMethod,
    domainListOption,
    allProviders,
    isLoadingCreateOffice,
    handleSubmitCreateOfficeId,
    getDomainListOption,
    getOfficeIdListOption,
    getAllProviderOption,
  } = useCreateAndUpdateOfficeId();
  const providerOptions: SelectOptionItem[] = useMemo(() => {
    return (
      (allProviders.length > 0 &&
        userData &&
        userData.providerIdsList.length > 0 &&
        (userData.providerIdsList.map((providerId) =>
          allProviders.find((provider) => provider.value === providerId)
        ) as SelectOptionItem[])) ||
      []
    );
  }, [userData, allProviders]);
  const logoFile = createOfficeIdMethod.watch('logoFile');
  const imgPreview = document.getElementById('blah') as HTMLImageElement;

  const handleImageChange = async (file: TInputUpload | null) => {
    if (!file) {
      setFileName('');
      createOfficeIdMethod.setValue('logoFile', []);
      return;
    }

    if (file && file.url && file.fileInfo) {
      const validTypes = ['image/png'];
      const maxHeight = 100;

      if (!validTypes.includes(file?.fileInfo?.type)) {
        toast.error('Logo phải là tệp PNG.');
        createOfficeIdMethod.setValue('logoFile', []);
        imgPreview.src = '';
        setFileName('');
        return;
      }

      const img = new Image();
      img.src = file.url;

      img.onload = () => {
        if (img.height > maxHeight) {
          toast.error(`Chiều cao ảnh không được vượt quá ${maxHeight}px.`);
          createOfficeIdMethod.setValue('logoFile', []);
          imgPreview.src = '';
          setFileName('');
          return;
        } else {
          setFileName(file.fileInfo?.name || '');
          if (file?.url) imgPreview.src = file.url;
        }
        return;
      };

      img.onerror = () => {
        toast.error('Không thể đọc file hình ảnh.');
        createOfficeIdMethod.setValue('logoFile', []);
        setFileName('');
        return;
      };
    }
  };

  useEffect(() => {
    if (logoFile && logoFile.length > 0) {
      handleImageChange(logoFile[0]);
    }
  }, [logoFile]);
  useEffect(() => {
    getDomainListOption();
    if (userData?.rolesList[0] === ROLE_USER.SUPER_ADMIN) {
      getOfficeIdListOption();
    }
    getAllProviderOption();
  }, []);

  useEffect(() => {
    const domainId = userData?.domainId;
    if (!domainId) {
      return;
    }
    createOfficeIdMethod.setValue('domain', domainId);
  }, [userData?.domainId]);

  return (
    <Form
      autoComplete='off'
      methods={createOfficeIdMethod}
      onSubmit={handleSubmitCreateOfficeId}
      className='max-w-[1100px] flex flex-col gap-y-5'>
      <div className='flex flex-col gap-y-5 p-4 border rounded'>
        <div className='flex gap-x-5'>
          <div className='w-1/3'>
            <FormInput
              name='userName'
              label='Tên đăng nhập'
              placeholder='Nhập tên đăng nhập'
              rules={{
                required: 'Nhập tên đăng nhập',
                validate: {
                  maxLength: (value: string) =>
                    value.length <= 20 || 'Tối đa 20 kí tự',
                  minLength: (value: string) =>
                    value.length >= 8 || 'Tối thiểu 8 kí tự',
                },
                pattern: {
                  value: patternValidateUsername,
                  message: 'Tên đăng nhập không hợp lệ',
                },
                onChange(event) {
                  createOfficeIdMethod.clearErrors('userName');
                },
              }}
              inputProps={{
                autoComplete: 'new-userName',
              }}
            />
          </div>
          <div className='w-1/3'>
            <FormInputPassword
              name='password'
              label='Mật khẩu'
              placeholder='Nhập mật khẩu'
              rules={{
                required: 'Nhập mật khẩu',
                validate: {
                  minLength: (value: string) =>
                    value.length >= 8 || 'Tối thiểu 8 kí tự',
                },
                onChange(event) {
                  createOfficeIdMethod.clearErrors('password');
                },
              }}
              inputProps={{
                autoComplete: 'new-password',
              }}
            />
          </div>
          {/* <div className='w-1/3'>
            <FormGroupRadio
              name='status'
              label='Trạng thái'
              className='flex'
              radioOptions={PartnerUserStatusListCreateOptions}
            />
          </div> */}
        </div>
        <div className='w-2/3'>
          <FormSelect
            name='domain'
            label='Domain'
            placeholder='Chọn domain'
            selectOptions={domainListOption}
            inputProps={{ isOnlyValue: true, disabled: true }}
          />
        </div>
      </div>

      <div className='flex flex-col gap-y-2 p-4 border rounded'>
        <span className='text-lg font-semibold'>Thông tin liên hệ</span>
        <div className='flex flex-col gap-y-5'>
          <div className='flex gap-x-5'>
            <div className='w-1/3'>
              <FormInput
                name='name'
                label='Họ tên'
                placeholder='Nhập họ tên'
                rules={{
                  required: 'Nhập họ tên',
                  onChange(event) {
                    createOfficeIdMethod.clearErrors('name');
                  },
                }}
              />
            </div>
            <div className='w-1/3'>
              <div className='flex flex-col gap-y-2'>
                <label>Số điện thoại</label>
                <div className='grid grid-cols-6 gap-x-2'>
                  <div className='col-span-2'>
                    <FormPhoneCode
                      displayFlag={false}
                      displayName={false}
                      name='phoneCode'
                      placeholder='Chọn mã vùng'
                      labelProps={{ className: 'hidden' }} // Hide default label
                      rules={{ required: 'Chọn mã vùng' }}
                    />
                  </div>
                  <div className='col-span-4'>
                    <FormInput
                      name='phoneNumber'
                      placeholder='Nhập số điện thoại'
                      labelProps={{ className: 'hidden' }} // Hide default label
                      inputProps={{
                        customFormat(value) {
                          return value.replace(/[^0-9]/g, '');
                        },
                        // minLength: 4,
                        // maxLength: 13,
                      }}
                      // rules={{
                      //   required: 'Nhập số điện thoại',
                      // }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='w-1/3'>
              <FormInput
                name='email'
                label='Email'
                placeholder='Nhập email'
                rules={{
                  // required: 'Nhập email',
                  validate: {
                    emailPattern: (value: string) => {
                      if (value.length > 0) {
                        return (
                          value.match(patternValidateEmail) ||
                          'Email không hợp lệ'
                        );
                      } else {
                        return true;
                      }
                    },
                  },
                  onChange(event) {
                    createOfficeIdMethod.clearErrors('email');
                  },
                }}
              />
            </div>
          </div>
          <div className=''>
            <FormInput
              name='shopName'
              label='Công ty/Đại lý'
              placeholder='Tên công ty/đại lý'
              rules={{
                required: 'Nhập công ty/đại lý',
                onChange(event) {
                  createOfficeIdMethod.clearErrors('shopName');
                },
              }}
            />
          </div>

          <div className='col-span-8'>
            <div className='my-3'>
              <input
                disabled={true}
                type='text'
                value={fileName}
                placeholder='No file chosen'
                className='border border-gray-300 p-2 flex-1 rounded-l-md h-[40px] w-1/2'
                readOnly
              />
              <label
                htmlFor='file-input'
                className='bg-primary text-white px-4 py-3 mb-3 rounded-r-md cursor-pointer hover:bg-blue-700'>
                Chọn file
              </label>

              <FormInputUpload
                inputProps={{ accept: 'image/png' }}
                className='hidden'
                id='file-input'
                name='logoFile'
              />
            </div>

            <div className='my-3'>
              Logo nên ưu tiên kiểu đặt ngang, có chiều cao không quá 100px,
              định dạng PNG
            </div>

            <img
              className={fileName && fileName !== '' ? 'block' : 'hidden'}
              id='blah'
              alt='img-choose'
            />
          </div>
        </div>
      </div>

      <div className='flex flex-col gap-y-2 p-4 border rounded'>
        <span className='text-lg font-semibold'>Chọn provider</span>

        <div className='w-full flex flex-col gap-y-3'>
          {providerOptions && providerOptions.length > 0 ? (
            providerOptions.map((item, index) => {
              return (
                <ToggleProvider
                  key={index}
                  label={item.label}
                  value={item.value}
                  nameField='enabledProvidersList'
                />
              );
            })
          ) : (
            <span>Không có Provider nào khả thi</span>
          )}
        </div>
      </div>

      <div className='flex justify-end gap-x-5'>
        <Button
          variant='outline'
          onClick={() => {
            // Go back router
            navigate(-1);
          }}>
          Quay lại
        </Button>
        <ButtonLoading
          loading={isLoadingCreateOffice}
          isShowChildWhenLoading
          type='submit'>
          Thêm Agent
        </ButtonLoading>
      </div>
    </Form>
  );
};

export default CreateOfficeIdCtn;
